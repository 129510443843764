import { IonBackButton, IonButton, IonButtons, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonModal, IonPage, IonProgressBar, IonRow, IonSelect, IonSelectOption, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { bookmarkOutline, helpCircleOutline, searchOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import JobList from '../components/JobList';
import '../components/JobListing';
import './Tab3search.css';





var jobTitle = "Gardener";
var hours = "Full-time";
var jobLocation = "St. John's Church";
var jobCity = "Huntsville, AL";
var jobPostedDate = "1 day ago";

var jobDescription="Perform the tasks of a gardener.";
var jobContact="johnsmith@church.com";

var jobObj = {
    "id": 3,
    "jobTitle": "Fast Food Worker",
    "employer": "Chicago Chicken and Grill",
    "term": "Full time",
    "jobRequirements": "1 year experience in a restaurant, ability to work night and closing shifts (closing at 2am), well versed in kitchen etiquette, knowledge of how to cook eat desired temps, must have transportation",
    "startDate": null,
    "duration": null,
    "preferredQualifications": null,
    "description": "Chicago chicken and grill is looking to immediately hire EXPERIENCED line cooks & dishwashers. Chicago chicken and grill is a very HIGH VOLUME and HIGH PACE restaurant. Any candidate applying for the position must be able to follow orders and perform in a consistent manner. In order to apply you must meet the following criteria's.",
    "location": "Tallahassee, FL",
    "salary": "8.25",
    "deadline": null,
    "phoneNumber": "Do not call?",
    "email": null,
    "notes": null,
    "website": null,
    "category": "Cook, food service worker, server, dishwasher",
    "requiresDegree": null,
    "requiredCertifications": null,
    "educationLevel": null,
    "requiresDrugScreening": null,
    "physicalRequirements": null,
    "mentalRequirements": "Good attitude and will to learn and be better",
    "requiredQualifications": null,
    "preferredExperience": null,
    "requiredExperience": "Experience in a restaurant for 1 year",
    "workingConditions": "High volume, high pace restaurant",
    "hireFelons": true,
    "schoolRestriction": false,
    "alcoholRestriction": false,
    "worksWithChildren": false,
    "onTheJobTraining": false
};




const Tab3search: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);

    const [distActive, setDistActive] = useState<boolean>(false);
    const [distance, setDistance] = useState<number>(0.0);
    const [distMode, setDistMode] = useState<string>("less");
    const [partTime, setPartTime] = useState<boolean>(true);
    const [fullTime, setFullTime] = useState<boolean>(true);
    const [dayShift, setDayShift] = useState<boolean>(true);
    const [nightShift, setNightShift] = useState<boolean>(true);
    const [wageActive, setWageActive] = useState<boolean>(false);
    const [wage, setWage] = useState<number>(8.00);
    const [wageMode, setWageMode] = useState<string>("greater");

    const jobList = useRef(null);

    useEffect(() => {
        //@ts-ignore
        jobList.current.forceUpdate();
    }, [jobList]);

    const [text4, setText4] = useState<string>();
    const [text5, setText5] = useState<string>();
    const [text6, setText6] = useState<string>();
    const [text7, setText7] = useState<string>();
    const [text8, setText8] = useState<string>();
    const [text9, setText9] = useState<string>();
    const [stateID, setStateID] = useState<string>();
    const [driverLicense, setDriverLicense] = useState<string>();
    const [techNumber, setTechNumber] = useState<string>();
    const [confNumber, setConfNumber] = useState<string>();

    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);

    const slideOpts = {
        initialSlide: 0,
        speed: 400,
        autoHeight:false,
        noSwiping: true,
        width: 300,
        spaceBetween: 200,
        centeredSlides: true,
        centeredSlideBounds:true,
    };
    
    // Takes in a job object and returns true if it should display, or false if not.
    function filterJob(jobObj: object): boolean
    {
        if(distActive)
        {
            var jobDistance = 10; // test value, use geolocation later
            if(distMode === "less" && jobDistance > distance) return false;
            if(distMode === "greater" && jobDistance < distance) return false;
        }

        var jobWage = parseFloat(jobObj['salary']);
        console.log([wageMode, jobWage, wage]);
        if(wageActive && !isNaN(jobWage)) // don't bother trying to filter by wage if the data is missing
        {
            if(wageMode === "less" && jobWage > wage) return false;
            if(wageMode === "greater" && jobWage < wage) return false;
        }

        var jobPart = jobObj['term'].toLowerCase().includes('part');
        var jobFull = jobObj['term'].toLowerCase().includes('full');
        if(!(jobPart && partTime) && !(jobFull && fullTime)) return false;

        // Night and day shift not present in backend

        return true;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons><IonBackButton defaultHref="/" /><IonTitle>Job Listings</IonTitle><IonButton className="headerButton1" fill="clear" color='light' onClick={() => setShowModal2(true)}><IonIcon className="titleIcons" icon={searchOutline} /></IonButton><IonButton className="headerButton2" fill="clear" slot="primary" color='light' onClick={() => setShowModal3(true)}><IonIcon className="titleIcons" icon={bookmarkOutline} /></IonButton><IonButton slot="primary" fill="clear" color='light' onClick={() => setShowModal4(true)}><IonIcon className="titleIcons" icon={helpCircleOutline} /></IonButton></IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="backgroundColor2">
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Job Search</IonTitle>
                    </IonToolbar>
                </IonHeader>


                
                {/* <IonList>
                <IonItem button onClick={() => setShowModal(true)}>
                        <IonLabel>
                            <div className='leftCol'><h1 className='jobTitle'>{jobTitle}</h1></div>
                            <IonButtons><IonButton className="headerButton3" fill="clear" color='light'><IonIcon className="titleIcons" icon={heartOutline} /></IonButton><IonButton className="headerButton1" fill="clear" color='light'><IonIcon className="titleIcons" icon={closeCircleOutline} /></IonButton></IonButtons>
                           
                       
                            
                     
                        <p className="jobLocation">{jobLocation}</p>
                        <p className="jobCity">{jobCity}</p>
                        <p className='jobDate'>{jobPostedDate}</p>
                   


                        </IonLabel>
                    </IonItem>
                    
                    </IonList> */}
                    

                {/* <IonCard button onClick={() => setShowModal(true)}>
                    <IonCardHeader>
                        <IonCardTitle className="jobTitle">
                            {jobTitle}
                        </IonCardTitle>
                        <IonCardSubtitle className="jobSubtitle">
                            {hours}
                        </IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <p className="jobLocation">{jobLocation}</p>
                        <p className="jobCity">{jobCity}</p>
                        <p className="jobPostedDate">{jobPostedDate}</p>
                        </IonCardContent>

                    

                </IonCard>

                <JobListing jobObj={jobObj} /> */}
                {/* <IonList> */}

                <JobList ref={jobList} filter={filterJob}></JobList>
                {/* </IonList> */}
                
                    <IonModal isOpen={showModal} onDidDismiss={() => { setShowModal(false); }} cssClass="jobModal">
                        <IonContent>
                            <IonCard>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol size="12">
                                            <h1 className="missionCategory">{jobObj['jobTitle']}</h1>
                                            <h5 className="missionCategory">{jobObj['employer']}</h5>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow>
                                        <IonItemDivider className="labelText">Description</IonItemDivider>
                                    <IonCol size="12">
                                        <div className="centeredText">
                                            Wage: {jobObj['salary'] ? '$' + jobObj['salary'] + '/hr' : "Unknown"} <br />
                                            {jobObj['description']}
                                        </div>

                                    </IonCol>
                                </IonRow>

                                <IonItemDivider className="labelText">Conditions</IonItemDivider>
                                <IonRow>
                                    <IonCol size="12">
                                        <div className='centeredText'>
                                            {jobObj['workingConditions']}
                                        </div>

                                    </IonCol>

                                        
                                    </IonRow>
                                    <IonRow>
                                        <IonItemDivider className="labelText">Requirements</IonItemDivider>
                                    <IonCol size="12">
                                        <div className="centeredText">
                                            {jobObj['jobRequirements']} <br />
                                            {jobObj['requiredExperience']} <br />
                                            {jobObj['physicalRequirements']} <br />
                                            {jobObj['mentalRequirements']} <br />
                                            {jobObj['requiredQualifications']} <br />
                                        </div>

                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonItemDivider className="labelText">Preferred</IonItemDivider>
                                    <IonCol size="12">
                                        <div className="centeredText">
                                            {jobObj['preferredQualifications']} <br />
                                            {jobObj['preferredExperience']}
                                        </div>

                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonItemDivider className="labelText">Contact</IonItemDivider>
                                    <IonCol size="12">
                                        <div className="centeredText">
                                            {jobObj['phoneNumber']} <br />
                                        </div>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow class="ion-justify-content-center">
                                        <IonItemDivider></IonItemDivider>
                                        <IonCol size="3">
                                            <IonButton color="success">Save</IonButton>
                                        </IonCol>
                                        <IonCol size="3">
                                            <IonButton onClick={() => { setShowModal(false); }} fill="clear">Close</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCard>
                        </IonContent>
                    </IonModal>
                
       
                <IonModal isOpen={showModal2} cssClass='filterModal'>
                    <h1 className="missionCategory">Filter Options</h1>
                    <IonCard>
                    <IonGrid>
                        <IonRow>
                            <IonItemDivider className="labelText">Distance</IonItemDivider>
                            <IonCol size="4">
                                <IonSelect interface="popover" value={distMode} onIonChange={e=>setDistMode(e.detail.value!)}>
                                    <IonSelectOption value="less">Less Than</IonSelectOption>
                                    <IonSelectOption value="greater">Greater Than</IonSelectOption>
                                </IonSelect>
                            </IonCol>
                            <IonCol size="4">
                                <IonInput id="distanceInput" type='number' value={distance} placeholder="Distance (miles)" onIonChange={e => setDistance(parseInt(e.detail.value!))} clearInput></IonInput>
                            </IonCol>
                            <IonCol size="1">
                                <IonCheckbox className="filterCheckbox" checked={distActive} onIonChange={e=>setDistActive(e.detail.checked!)}  />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                                <IonItemDivider className="labelText">Hours</IonItemDivider>
                                <IonCol size="4">
                                    <IonLabel className="checkboxText">Part-time</IonLabel>
                            </IonCol>
                            <IonCol size="1">
                                    <IonCheckbox className="checkboxPosition1" checked={partTime} onIonChange={e=>setPartTime(e.detail.checked!)}  />
                            </IonCol>

                            <IonCol size="4">
                                    <IonLabel className="checkboxText">Full-time</IonLabel>
                            </IonCol>
                            <IonCol size="1">
                                    <IonCheckbox className="checkboxPosition1" checked={fullTime} onIonChange={e=>setFullTime(e.detail.checked!)}  />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                                <IonItemDivider className="labelText">Hourly Wage</IonItemDivider>
                            <IonCol size="5">
                                <IonSelect interface="popover" value={wageMode} onIonChange={e=>setWageMode(e.detail.value!)}>
                                    <IonSelectOption value="less">Less Than</IonSelectOption>
                                    <IonSelectOption value="greater">Greater Than</IonSelectOption>
                                </IonSelect>
                            </IonCol>
                            <IonCol size="4">
                                <IonInput id="wageInput" type='number' value={wage} placeholder="$/hr" onIonChange={e => setWage(parseInt(e.detail.value!))} clearInput></IonInput>
                            </IonCol>
                            <IonCol size="1">
                                    <IonCheckbox checked={wageActive} className="filterCheckbox" onIonChange={e=>setWageActive(e.detail.checked!)}  />
                            </IonCol>
                        </IonRow>
                        <IonRow>
                                <IonItemDivider className="labelText">Shifts</IonItemDivider>
                            <IonCol size="2">
                                Day
                            </IonCol>
                            <IonCol size="1">
                                    <IonCheckbox className="checkboxPosition2" checked={dayShift} onIonChange={e=>setDayShift(e.detail.checked!)}  />
                            </IonCol>

                            <IonCol size="2">
                                Night
                            </IonCol>
                            <IonCol size="1">
                                    <IonCheckbox className="checkboxPosition2" checked={nightShift} onIonChange={e=>setNightShift(e.detail.checked!)}  />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    </IonCard>

                    <IonButton onClick={() => setShowModal2(false)} fill="clear">Close</IonButton>
                </IonModal>
                <IonModal isOpen={showModal3} cssClass='my-custom-class'>
                    <h1 className="missionCategory">Saved Jobs</h1>
                   
                   
                   

                    <IonButton onClick={() => setShowModal3(false)} fill="clear">Close</IonButton>
                </IonModal>
                <IonModal isOpen={showModal4} cssClass='my-custom-class'>
                    <h1 className="missionCategory">Job Questionnaire</h1>

                    <IonSlides options={slideOpts} id="questionSlides" className="centeredContent">
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>11 Questions</h1>
                                <p className="questionText">Welcome to the job board! Before you proceed, we need to ask a few questions about your job history, interests, and goals.</p>
                                <IonItemDivider></IonItemDivider>
                            <IonButton onClick={() => {
                                //@ts-ignore
                                document.getElementById("questionSlides").slideNext();
                            }} expand="block">Start</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 1/11</h1>
                                <IonProgressBar value={1/11}></IonProgressBar>
                                <p className="questionText">First, lets get an idea about your comfort levels with technology. On a scale of 1 to 10, with 10 being the most, how comfortable are you with using technology and the internet to apply for jobs?</p>
                                <IonItemDivider></IonItemDivider>
                                <IonItem>

                                    <IonSelect interface="popover" value={techNumber} placeholder="Select One" onIonChange={e => setTechNumber(e.detail.value)}>
                                        <IonSelectOption value="1">1</IonSelectOption>
                                        <IonSelectOption value="2">2</IonSelectOption>
                                        <IonSelectOption value="3">3</IonSelectOption>
                                        <IonSelectOption value="4">4</IonSelectOption>
                                        <IonSelectOption value="5">5</IonSelectOption>
                                        <IonSelectOption value="6">6</IonSelectOption>
                                        <IonSelectOption value="7">7</IonSelectOption>
                                        <IonSelectOption value="8">8</IonSelectOption>
                                        <IonSelectOption value="9">9</IonSelectOption>
                                        <IonSelectOption value="10">10</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>

                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 2/11</h1>
                                <IonProgressBar value={2 / 11}></IonProgressBar>
                                <p className="questionText">On a scale of 1 to 10, with 10 being the most,  how confident are you in job interviews? This includes being able to talk about your past convictions and gaps in your work history.</p>
                                <IonItemDivider></IonItemDivider>
                                <IonItem>

                                    <IonSelect interface="popover" value={confNumber} placeholder="Select One" onIonChange={e => setConfNumber(e.detail.value)}>
                                        <IonSelectOption value="1">1</IonSelectOption>
                                        <IonSelectOption value="2">2</IonSelectOption>
                                        <IonSelectOption value="3">3</IonSelectOption>
                                        <IonSelectOption value="4">4</IonSelectOption>
                                        <IonSelectOption value="5">5</IonSelectOption>
                                        <IonSelectOption value="6">6</IonSelectOption>
                                        <IonSelectOption value="7">7</IonSelectOption>
                                        <IonSelectOption value="8">8</IonSelectOption>
                                        <IonSelectOption value="9">9</IonSelectOption>
                                        <IonSelectOption value="10">10</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>

                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 3/11</h1>
                                <IonProgressBar value={3 / 11}></IonProgressBar>
                                <p className="questionText">Let's talk about your skills. Do you have any certifications? This could include things like HVAC certifications, welding certifications, cosmetology licenses, serv-safe certifications, etc. If you don't have any, just type 'none'</p>
                                <IonItemDivider></IonItemDivider>
                                <IonInput value={text4} placeholder="Enter text:" onIonChange={e => setText4(e.detail.value!)} clearInput></IonInput>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>

                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 4/11</h1>
                                <IonProgressBar value={4/11}></IonProgressBar>
                                <p className="questionText">Do you have any other specialities, degrees, or skills? This can include things like classes taken, participation in educational programs, or hobbies.  If you don't have any, just type 'none'</p>
                                <IonItemDivider></IonItemDivider>
                                <IonInput value={text5} placeholder="Enter text:" onIonChange={e => setText5(e.detail.value!)} clearInput></IonInput>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>
                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 5/11</h1>
                                <IonProgressBar value={5/11}></IonProgressBar>
                                <p className="questionText">Let's talk about your job history. List some previous job titles you've had.  If you don't have any, just type 'none'</p>
                                <IonItemDivider></IonItemDivider>
                                <IonInput value={text6} placeholder="Enter text:" onIonChange={e => setText6(e.detail.value!)} clearInput></IonInput>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 6/11</h1>
                                <IonProgressBar value={6/11}></IonProgressBar>
                                <p className="questionText">What kind of jobs have you had in the past? List some categories, such as cashier, food service, mechanic, sales associate, etc.</p>
                                <IonItemDivider></IonItemDivider>
                                <IonInput value={text7} placeholder="Enter text:" onIonChange={e => setText7(e.detail.value!)} clearInput></IonInput>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 7/11</h1>
                                <IonProgressBar value={7/11}></IonProgressBar>
                                <p className="questionText">Are you interested in full or part time work?</p>
                                <IonItemDivider></IonItemDivider>
                                <IonItem>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size='5'>
                                                <IonLabel className="checkboxText2">Part-time</IonLabel>
                                            </IonCol>
                                            <IonCol size='1'>
                                                <IonCheckbox className="checkboxPosition3" checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
                                            </IonCol>
                                            <IonCol size='5'>
                                                <IonLabel className="checkboxText2">Full-time</IonLabel>
                                            </IonCol>
                                            <IonCol size='1'>
                                                <IonCheckbox className="checkboxPosition3" checked={checked2} onIonChange={e => setChecked2(e.detail.checked)} />
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>


                                </IonItem>

                            
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 8/11</h1>
                                <IonProgressBar value={8/11}></IonProgressBar>
                                <p className="questionText">How do you travel usually, or what would be your most reliable form of transportation? Options include own car, friend/family car, bus, walking, bike, etc.</p>
                                <IonItemDivider></IonItemDivider>
                                <IonInput value={text8} placeholder="Enter text:" onIonChange={e => setText8(e.detail.value!)} clearInput></IonInput>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 9/11</h1>
                                <IonProgressBar value={9/11}></IonProgressBar>
                                <p className="questionText">Do you own a state issued ID?</p>
                                <IonItemDivider></IonItemDivider>
                                <IonItem>
                                    
                                    <IonSelect interface="popover" value={stateID} placeholder="Select One" onIonChange={e => setStateID(e.detail.value)}>
                                        <IonSelectOption value="yes">Yes</IonSelectOption>
                                        <IonSelectOption value="no">No</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 10/11</h1>
                                <IonProgressBar value={10/11}></IonProgressBar>
                                <p className="questionText">Do you have a valid driver's license?</p>
                                <IonItemDivider></IonItemDivider>
                                <IonItem>

                                    <IonSelect interface="popover" value={driverLicense} placeholder="Select One" onIonChange={e => setDriverLicense(e.detail.value)}>
                                        <IonSelectOption value="yes">Yes</IonSelectOption>
                                        <IonSelectOption value="no">No</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <h1>Question 11/11</h1>
                                <IonProgressBar value={11/11}></IonProgressBar>
                                <p className="questionText">Finally, let's talk about your interests and goals. What kinds of jobs categories are you interested in applying to? This could include things like servers, food service, truck driver, etc. If you don't know, type 'none'.</p>
                                <IonItemDivider></IonItemDivider>
                                <IonInput value={text9} placeholder="Enter text:" onIonChange={e => setText9(e.detail.value!)} clearInput></IonInput>
                                <IonButton onClick={() => {
                                    //@ts-ignore
                                    document.getElementById("questionSlides").slideNext();
                                }} expand="block">Next</IonButton>
                            </IonCard>


                        </IonSlide>
                        <IonSlide>
                            <IonCard className="questionCard">
                                <p className="questionText">All done! We are finding some personalized job listings you might be interested in. If you want to change your answers, tap the 'question mark' button on the job board to edit your information.</p>
                                <IonItemDivider></IonItemDivider>
                            <IonButton onClick={() => setShowModal4(false)} expand="block">Finish</IonButton>
                            </IonCard>

                        </IonSlide>
                    </IonSlides>




                    <IonButton onClick={() => setShowModal4(false)} fill="clear">Close</IonButton>
                </IonModal>

    
              


          
            </IonContent>
        </IonPage>
    );
};

export default Tab3search;