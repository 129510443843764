import { Point, Polygon } from "geojson"

function statusColor(status:string):string{
    switch (status) {
      case "Not Submitted":
        return "secondary"
      case "Approved":
        return "success"
      case "Rejected":
        return "danger"
      default:
        return "primary"
    }
  }


function getWeeday(date:string):string {
    
    let dateObj:Date = new Date(date)
    dateObj.setDate(dateObj.getDay())
    let day:number = dateObj.getDay()

    switch (day) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday"
      default:
        return "NA";
    }
  }

function getMonthName(date:string):string {
  let dateObj:Date = new Date(date)

  let month:number = dateObj.getMonth()

  switch(month){
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March"
    case 3:
      return "April"
    case 4:
      return "May"
    case 5:
      return "June"
    case 6:
      return "July"
    case 7:
      return "August"
    case 8:
      return "September"
    case 9:
      return "October"
    case 10:
      return "November"
    case 11:
      return "December"
    default:
      return "NA";
  }
}


function shortenMonth(month:string):string {
  
  switch (month) {
    case "January":
      return "JAN"

    case "February":
      return "FEB"

    case "March":
      return "MAR"
    
    case "April":
      return "APR"

    case "May":
      return "MAY"
    
    case "June":
      return "JUN"
    
    case "July":
      return "JUL"
    
    case "August":
      return "AUG"

    case "September":
      return "SEP"
    
    case "October":
      return "OCT"

    case "November":
      return "NOV"

    case "December":
      return "DEC"
  
    default:
      return "Month"
  }
}

function adjustTime(time:string): string {
  let hour:string
  if(time.split(":")[0].length < 2){
    hour = "0" + time.split(":")[0]
  }

  else{
    hour = time.split(":")[0]
  }


  
  let minute:string = time.split(":")[1]
  let timeOfDay:string = "PM"
  return `${hour}:${minute} ${timeOfDay}`
}

function findObjectCenter(obj: Point | Polygon): [number, number]
{
  if(obj.type === "Point")
    return [obj.coordinates[1], obj.coordinates[0]] as [number, number];

  let avg = [0, 0];
  obj.coordinates[0].forEach(v => {
    avg[1] += v[0];
    avg[0] += v[1];
  });

  avg[0] /= obj.coordinates[0].length;
  avg[1] /= obj.coordinates[0].length;

  return avg as [number, number];
}

export {getWeeday,statusColor,shortenMonth,getMonthName,adjustTime,findObjectCenter}