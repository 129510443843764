import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonItem, IonRow, IonText } from "@ionic/react";
import React, { useState } from "react";
import { shortenMonth, getWeeday, statusColor, adjustTime } from "../../utilities";
import SchedulingLog from "./SchedulingLogInterface";
import './ScheduleLog.css'

const WeeklyScheduleLog: React.FC<{data:SchedulingLog}> = ({data}) =>{

    const [checklistCount,setChecklistCount] = useState<number>(0)
  
    if(getWeeday(data.date) == "Friday"){
        return(
            <div>
            <IonText class="ion-text-center">
                <h1>Week 2</h1>
            </IonText>
          <IonCard routerLink={`/tab3/${data.date}`}>
                <IonCardHeader>
                  <IonCardTitle>
                    <IonGrid class="ion-no-padding">
                        <IonRow>
                          <IonCol  size="8">
                            <IonItem class="ion-no-padding" lines="none">
                              <h1>{data.day} </h1>
                              <h1 className="month-text">{shortenMonth(data.month)}</h1>
                              <p>{data.year},{getWeeday(data.date)}</p>
                            </IonItem>
                          </IonCol>
                          <IonCol size="4">
                            <IonItem className="status-box" class="ion-text-center" color={statusColor(data.status)}>
                              <IonText>
                              {data.status}
                              </IonText>
                            </IonItem>
                            
                          </IonCol>
                        </IonRow>
                    </IonGrid>
                    </IonCardTitle>
                  <IonCardSubtitle>02:55pm</IonCardSubtitle>
      
                </IonCardHeader>
      
                
              </IonCard>
              </div>
        )
    }

    else{
        return(
            <div>
          <IonCard routerLink={`/tab3/${data.date}`}>
                <IonCardHeader>
                  <IonCardTitle>
                    <IonGrid class="ion-no-padding">
                        <IonRow>
                          <IonCol  size="8">
                            <IonItem class="ion-no-padding" lines="none">
                              <h1>{data.day} </h1>
                              <h1 className="month-text">{shortenMonth(data.month)}</h1>
                              <p>{data.year},{getWeeday(data.date)}</p>
                            </IonItem>
                          </IonCol>
                          <IonCol size="4">
                            <IonItem className="status-box" class="ion-text-center" color={statusColor(data.status)}>
                              <IonText>
                              {data.status}
                              </IonText>
                            </IonItem>
                            
                          </IonCol>
                        </IonRow>
                    </IonGrid>
                    </IonCardTitle>
                  <IonCardSubtitle>02:55pm</IonCardSubtitle>
      
                </IonCardHeader>
      
                
              </IonCard>
              </div>
        )
    }
    
    
  }

  export default WeeklyScheduleLog;