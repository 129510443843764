import { registerPlugin } from '@capacitor/core';

export interface NativePlugin {
  startZoom(options:{token:string}):Promise<{}>
  login(options: { email: string, password: string }): Promise<{}>
  startSessionTimeout(): Promise<{}>
  clearSessionTimeout():Promise<{}>

}

const Native = registerPlugin<NativePlugin>('NativePlugin');

 export default Native;