
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { FirebaseConfig } from '../firebase_config';
import useAuth from './useAuth';
// import useAuth from './useAuth';

const useLibraryResource = (resource:string) => {
    const [document, setDocument] = useState<Uint8Array | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    // const [userId, setUseId] = useState<string | undefined>("")
    // const [token,setToken] = useState<string>("")
const [userId, setUserId, loggedIn, user] = useAuth()

    useEffect(() => {
        
        setIsLoading(true)
        // console.log(token)
        if (userId != null) {
            axios.get(`${process.env.REACT_APP_NIJAPI_BASEURL}/library/download/${resource}`, {
                responseType:'blob'}).then((res) => {
            setIsLoading(false)
            setIsError(false)
            setDocument(res.data)
        }).catch((err) => {
            setIsLoading(false)
            setDocument(null)
            setIsError(true)
            console.error(err)
        });
        
        }

        
        return () => {};

    }, [userId]);

    return [document, isLoading, isError] as const
};

export default useLibraryResource;