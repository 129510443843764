import { isPlatform, IonSpinner, IonFooter } from '@ionic/react';
import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar, useIonAlert, useIonToast } from '@ionic/react';
import { Call as CallMaterial, CallMissed, CallReceived } from '@material-ui/icons';
import { callOutline, chatboxOutline, logoSkype, timeOutline, trashOutline, flag, flagOutline, cloudOfflineOutline, videocam } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { CalendarEvent, Call, Meeting } from '../../components/calendar/CalendarTypes';
import { getEventIcon, getStatusIcon, getNIJStatusIcon } from '../../components/calendar/EventItem';
import NIJAPI, { NIJMeeting } from '../../NIJAPI';
import Native from '../../plugins/NativePlugin';
import './EventView.css';

interface EventViewProps extends RouteComponentProps<{
    id: string
}> { }

function canCall(meeting: Meeting) {
    return ['skype', 'zoom', 'call', 'duo'].includes(meeting.type);
}

function getMeetingIcon(meeting: Meeting) {
    switch (meeting.type) {
        case 'skype':
            return <IonIcon icon={logoSkype} style={{ color: '#00aff0' }} />;
        case 'zoom':
            return <IonIcon icon={callOutline} style={{ color: '#2D8CFF' }} />;
        case 'call':
            return <IonIcon icon={callOutline} />;
        default:
            return <IonIcon icon={chatboxOutline} />;
    }
}

function getCallIcon(call: Call) {
    switch (call.callStatus) {
        case 'missed':
            return <CallMissed />;
        case 'answered':
            return <CallReceived />
        default:
            return <CallMaterial />
    }
}

function deleteEvent(event: CalendarEvent) {
    console.log("Deleting event", event);
    try {
        // Delete checklists, meetings, and calls
        event.checklist.forEach(v => {
            NIJAPI.instance.deleteChecklist(v.id);
        });
        event.meetings.forEach(v => {
            NIJAPI.instance.deleteMeeting(v.id);
        });
        event.calls.forEach(v => {
            NIJAPI.instance.deleteCall(v.id);
        });

        // Finally, delete this event.
        NIJAPI.instance.deleteEvent(event.id);

        return true;
    }
    catch (e) {
        console.error(e);
        return false;
    }
}

const EventView: React.FC<EventViewProps> = ({ match }) => {
    const [event, setEvent] = useState<NIJMeeting>();
    const [noEvent, setNoEvent] = useState(false);
    const { id } = match.params;

    const [presentDelete] = useIonAlert();
    const [toast] = useIonToast();

    const history = useHistory();

    useEffect(() => {
        // NIJAPI.instance.getEvents({
        //     id: id
        // }).then((v) => {
        //     setEvent(v[0]);
        // });

        NIJAPI.instance.fetchSchedule()
            .then(meetings => {
                const meeting = meetings.find(v => v.id!.toString() === id);

                if (meeting) {
                    setEvent(meeting);
                    setNoEvent(false); 
                } else {
                    setNoEvent(true);
                }
            })
            .catch(err => {
                console.error(err);
                toast({
                    message: `Error fetching event: "${err}"`,
                    duration: 3000
                });
                setNoEvent(true);
            })
    }, [id, toast]);

    // const handleDelete = useCallback(() => {
    //     presentDelete({
    //         header: 'Delete event?',
    //         message: 'Do you want to delete this event?',
    //         buttons: [
    //             {
    //                 text: 'Delete',
    //                 cssClass: 'alert-delete',
    //                 handler: () => {
    //                     if(event && deleteEvent(event))
    //                     {
    //                         toast('Event deleted', 3000);
    //                         history.goBack();
    //                     }
    //                 }
    //             },
    //             {
    //                 text: 'Cancel',
    //                 role: 'cancel'
    //             }
    //         ]
    //     })
    // }, [event]);

    if (!event || noEvent)
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/calendar" />
                        </IonButtons>

                        <IonTitle>
                            Event
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen className="ion-padding">
                    <div style={{
                        position: 'relative',
                        top: '50%',
                        width: '100%',
                        transform: 'translateY(-50%)',
                        textAlign: 'center',
                        color: 'darkgrey',
                        fontSize: '24px'
                    }}>
                        {noEvent ? "Event not found! Check your network connection." : "Loading event..."}
                        <div style={{ fontSize: '64px', padding: '8px' }}>
                        {
                            noEvent ?
                            <IonIcon icon={cloudOfflineOutline} /> :
                            <IonSpinner style={{ width: '64px', height: '64px' }} color="medium" />
                        }
                        </div>
                        
                    </div>
                </IonContent>
            </IonPage>
        );

    const eventDate = new Date(event.start_time);
    const endDate = new Date(event.end_time);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/calendar" />
                    </IonButtons>

                    <IonTitle>
                        Event
                    </IonTitle>

                    {/* <IonButtons slot="end">
                        <IonButton shape='round' onClick={handleDelete}>
                            <IonIcon icon={trashOutline} slot="icon-only" />
                        </IonButton>
                    </IonButtons> */}
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className={`ion-padding eventView event${event.type}`}>
                <div className="eventHeader">
                    <div className="eventDate">
                        <div className="eventWeekday">
                            {eventDate.toLocaleDateString([], { weekday: 'long' })}
                        </div>
                        <div className="eventMonthDay">
                            {eventDate.toLocaleDateString([], { month: 'long', day: 'numeric' })}
                        </div>
                    </div>

                    <div className="eventBadges">
                        <div className={`eventApproval event${event.approved ? "approved" : "pending"}`}>
                            <span className="eventApprovalStatus">{event.approved ? "Approved" : "Not Approved"}</span>
                            <span className="eventApprovalIcon">
                                {getNIJStatusIcon(event)}
                            </span>
                        </div>
                        { event.required &&
                            <div className="eventApproval eventrequired">
                                <span className="eventApprovalStatus">Points Awarded</span>
                                <span className="eventApprovalIcon">
                                    <IonIcon icon={event.required ? flag : flagOutline} />
                                </span>
                            </div>
                        }    
                    </div>
                    
                </div>

                <div className="eventContent">
                    <div className="eventTitle">
                        <div className="eventName">
                            <IonIcon icon={getEventIcon(event)} />
                            <span>{event.title}</span>
                        </div>
                        <div className="eventTime">
                            <IonIcon icon={timeOutline} />
                            <span>{eventDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}</span>
                        </div>
                    </div>

                    <div className="eventMeta">
                        {endDate.valueOf() > eventDate.valueOf() &&
                            <>
                                Ends {endDate.toLocaleDateString()} @ {endDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}
                                <br />
                            </>
                        }
                        Scheduled by {event.sender} for {event.receiver}
                    </div>

                    <div className="eventDescription">
                        <p>{event.purpose}</p>
                    </div>

                    {/* {event.checklist.length > 0 && <div className="eventChecklist">
                        <IonList>
                            <IonListHeader>
                                Checklist
                            </IonListHeader>
                            { event.checklist.map(v =>
                                <IonItem key={v.id} lines="none">
                                    <IonCheckbox checked={v.completed} slot="start" onIonChange={e => {
                                        NIJAPI.instance.updateChecklist(v.id, e.detail.checked);
                                    }} />
                                    <IonLabel>{v.name}</IonLabel>
                                </IonItem>
                            )}
                        </IonList>
                    </div>}

                    {event.meetings.length > 0 && <div className="eventMeetings">
                        <IonList>
                            <IonListHeader>
                                Meetings
                            </IonListHeader>
                            { event.meetings.map((v, i) =>
                                <IonItem key={i} lines="none">
                                    { getMeetingIcon(v) }
                                    <IonLabel>
                                        {v.name}
                                        <p>at {new Date(event.timestamp.substr(0,11)+v.time).toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}</p>
                                    </IonLabel>
                                    {canCall(v) && <IonButton slot="end" className="eventCallButton">Call</IonButton>}
                                    {!canCall(v) &&
                                    <IonLabel className="eventMeetingLocation" slot="end">
                                        {v.location}
                                    </IonLabel>}
                                </IonItem>
                            )}
                        </IonList>
                    </div>}

                    {event.calls.length > 0 && <div className="eventCalls">
                        <IonList>
                            <IonListHeader>
                                Calls
                            </IonListHeader>
                            { event.calls.map((v, i) =>
                                <IonItem key={i} lines="none">
                                    { getCallIcon(v) }
                                    <IonLabel>
                                        {v.name}
                                        <p>{v.topic}</p>
                                        <p>To: {v.receiver}</p>
                                    </IonLabel>
                                </IonItem>
                            )}
                        </IonList>
                    </div>} */}
                </div>
            </IonContent>
            <IonFooter>
                {event.type === 'zoom' &&
                    <IonButton expand="full" size="large" fill="outline" href='https://fsu.zoom.us/j/2572796041'>
                        <span style={{ flexGrow: 1 }}>Start Video Session</span>
                        <IonIcon slot="end" icon={videocam} />
                    </IonButton>
                }
            </IonFooter>
        </IonPage>
    );
};

export default EventView;