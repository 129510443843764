import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonItemDivider, IonList, IonRow } from '@ionic/react';
import { addCircleSharp, closeSharp } from 'ionicons/icons';
import React from 'react';
import { Rtif } from '../../rtif';

import './SectionTime.css';
import SurveySection, { SurveySectionProps } from './SurveySection';
import RWAT from '../../RWAT';

const idOverrides = [
    'sleeping',
    'school',
    'paid_work',
    'household_chores',
    'community_activities',
    'recreational_activities',
    'commuting',
    'personal_relationships',
    'health_and_self_maintenance',
    'spiritual'
];

// For SectionTime, some special behavior occurs.
// The user may add their own questions through the UI
// Any question with a label is considered to be "protected"
export default class SectionTime extends SurveySection {
    constructor(props: SurveySectionProps)
    {
        super(props);

        this.state = {
            answers: RWAT.getInstance().loadSectionAnswers(props.id) ?? new Array(props.questions.length).fill('').map(() => new Array(2).fill(NaN))
        }
    }

    // Add a new question
    addQuestion()
    {
        this.props.questions.push("");
        let answers = this.state.answers;
        answers.push([NaN,NaN]);
        this.setState({ answers: answers });  
        this.triggerEvents();
    }

    // Delete a question
    deleteQuestion(index: number)
    {
        this.props.questions.splice(index, 1);
        let answers = this.state.answers;
        answers.splice(index, 1);
        this.setState({ answers: answers });
        this.triggerEvents();
    }

    encodeAnswers()
    {
        return this.getQuestionAnswers().map((val, index) => {
            let qencoded = index < idOverrides.length ?
                            idOverrides[index] :
                            this.props.questions[index].toLowerCase().replace(/\W/g, '_');
            if(qencoded.length <= 0) return undefined;

            let v0 = isNaN(val[0]) ? 0 : val[0];
            let v1 = isNaN(val[1]) ? 0 : val[1];

            return `${qencoded}:${Math.floor(v0 * 60)}:${Math.floor(v1 * 60)}`;
        }).filter(e => e !== undefined).join('-');
    }

    addAnswers()
    {
        console.log(this);
        return this.state.answers.reduce((curr, ans) => {
            if(ans === undefined) return curr;
            // console.log(curr, ans);
            return [curr[0] + (isNaN(ans[0]) ? 0 : ans[0]), curr[1] + (isNaN(ans[1]) ? 0 : ans[1])]
        }, [0,0]);
    }

    validate()
    {
        const sum = this.addAnswers();

        // console.debug(sum);
        // console.debug(this.encodeAnswers());

        if(sum[0] !== 24 || sum[1] !== 24)
        {
            this.validated = false;
            return 'Time values must add up to 24 hours.';
        }

        this.validated = true;
        return '';
    }

    render()
    {
        const { answers } = this.state;
        const normalQuestions = this.props.labels.length;
        const sum = this.addAnswers();

        return (
            <div>
                {/* Header */}
                { this.getHeader() }

                <IonGrid>
                    <IonRow>
                        <IonCol size="4"><b>Area of Your Life</b></IonCol>
                        <IonCol size="3">How much time did you <b>actually</b> spend doing the following?</IonCol>
                        <IonCol size="3">How much time would you <b>like to</b> spend doing the following?</IonCol>
                    </IonRow>
                </IonGrid>

                {/* Questions */}
                <IonList>
                    {
                        this.props.questions.map((question, index) => {
                            return (
                                <IonItem key={index} class="time">
                                    <IonGrid>
                                        <IonRow>
                                            {/* Default Questions */}
                                            <Rtif boolean={normalQuestions > index}>
                                                <IonCol size="4">
                                                    <b>{question}</b>
                                                    <br/>
                                                    <i style={{fontWeight: 'lighter'}}>{this.props.labels[index]}</i>
                                                </IonCol>
                                            </Rtif>
                                            {/* Custom Questions */}
                                            <Rtif boolean={normalQuestions <= index}>
                                                <IonCol size="4">
                                                    <IonInput inputmode="text" placeholder="Other..." value={question} onIonChange={v => {this.props.questions[index] = v.detail.value!; this.forceUpdate()}} debounce={250} />
                                                </IonCol>
                                            </Rtif>
                                            {/* Time spent */}
                                            <IonCol size="3">
                                                <IonInput style={{ textAlign: 'right' }} disabled={question?.length <= 0} value={answers[index][0]} onIonChange={v => {
                                                    let val = parseFloat(v.detail.value!);
                                                    if(isNaN(val) && isNaN(answers[index][0])) return; //val = 0;
                                                    if(val === answers[index][0]) return;
                                                    this.setQuestionAnswer(index, [val, answers[index][1]])}}
                                                    placeholder="Actual" type="number" max="24" min="0" debounce={250}></IonInput>
                                            </IonCol>
                                            {/* Time desired */}
                                            <IonCol size="3">
                                                <IonInput style={{ textAlign: 'right' }} disabled={question?.length <= 0} value={answers[index][1]} onIonChange={v => {
                                                    let val = parseFloat(v.detail.value!);
                                                    if(isNaN(val) && isNaN(answers[index][1])) return; //val = 0;
                                                    if(val === answers[index][1]) return;
                                                    this.setQuestionAnswer(index, [answers[index][0], val])}}
                                                    placeholder="Desired" inputMode="numeric" type="number" max="24" min="0" debounce={250}></IonInput>
                                            </IonCol>
                                            <IonCol size="2">
                                                <Rtif boolean={normalQuestions <= index}>
                                                    <IonButton shape="round" fill="clear" onClick={e => {this.deleteQuestion(index)}} style={{ margin: 'auto' }}>
                                                        <IonIcon slot="icon-only" icon={closeSharp} />
                                                    </IonButton>
                                                </Rtif>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            );
                        })
                    }

                    {/* Add New Question */}
                    <IonItem key={-2}>
                        <IonButton shape="round" fill="clear" onClick={e=>{this.addQuestion()}} style={{ margin: 'auto' }}>
                            <IonIcon slot="icon-only" icon={addCircleSharp} />
                        </IonButton>
                    </IonItem>

                    {/* Total */}
                    <IonItemDivider />
                    <IonItem key={-1}>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="4">
                                    <b>Total Hours</b>
                                </IonCol>
                                <IonCol size="3" style={{textAlign: 'right'}}>
                                    <b>{sum[0]} hrs.</b>
                                </IonCol>
                                <IonCol size="3" style={{textAlign: 'right'}}>
                                    <b>{sum[1]} hrs.</b>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonItem>
                </IonList>
            </div>
        );
    }
}