import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import CalendarView from './CalendarView';
import EventScheduler from './EventScheduler';
import EventView from './EventView';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';

const SchedulerRouters: React.FC = () => (
  <>
    <Route path="/tab1" component={Tab1} exact={true} />
    <Route path="/tab2" component={Tab2} exact={true} />
    <Route path="/tab3/:id" component={Tab3} />
    <Route path="/calendar" component={CalendarView} exact={true} />
    <Route path="/calendar/event/:id" component={EventView} />
    <Route path="/calendar/create" component={EventScheduler} />
    <Route path="/" render={() => <Redirect to="/calendar" />} exact={true} />
  </>
);

export default SchedulerRouters;
