import { IonItem, IonList } from '@ionic/react';
import React, { Component } from 'react';
import '../NIJAPI';
import NIJAPI from '../NIJAPI';
import './JobListing';
import JobListing from './JobListing';

interface JobListProps {
    filter?: (jobObj: object) => boolean
}

type JobListState = {
    jobListings: [ object ] | [],
    knownId: number
}

export default class JobList extends Component<JobListProps, JobListState>
{
    async fetchJobListings()
    {
        if(NIJAPI.instance.userId === -1)
        {
            setTimeout(this.fetchJobListings.bind(this), 3000);
            return;
        }

        console.log("Fetching jobs...");
        try
        {
            let resp = await NIJAPI.instance.fetchJobs();

            console.log(resp);
            //@ts-ignore
            this.setState({ jobListings: resp, knownId: NIJAPI.instance.userId });
        }
        catch(err)
        {
            console.error(err);
        }
    }

    //@ts-ignore
    constructor(props)
    {
        super(props);

        this.state = {
            jobListings: [],
            knownId: -1
        }

        this.fetchJobListings();
    }

    render()
    {
        //@ts-ignore
        const { jobListings, knownId } = this.state;

        console.log(jobListings);
        console.log(knownId);
        if(NIJAPI.instance.userId === -1)
            return (
                <IonList>
                    <IonItem>
                        Please log in first.
                    </IonItem>
                </IonList>
            );
        
        // Refresh jobs if user changed
        if(knownId !== -1 && knownId !== NIJAPI.instance.userId)
                this.fetchJobListings();
        
        if(jobListings.length < 1)
        {
            return (
                <IonList>
                    <IonItem>
                        No items
                    </IonItem>
                </IonList>
            );
        }

        return (
            <IonList>
                {
                    //@ts-ignore
                    jobListings.map((job: object) => {
                        //@ts-ignore
                        if(this.props.filter && !this.props.filter(job)) return null;
                        return (
                            <JobListing jobObj={job}></JobListing>
                        )})
                }
            </IonList>
        );
    }
}