import { IonButton, useIonLoading } from "@ionic/react"
import React, { useState } from "react"
import { useEffect } from "react"
import useLibraryResource from "../../hooks/useLibraryResource"

interface DownloadPDFButtonProps {
    resource: string
}

const DownloadPDFButton: React.FC<DownloadPDFButtonProps> = ({ resource }) => {
    const [present, dismiss] = useIonLoading()
    // const [resources, isLoading, isError] = useLibraryResource(resource)
    const [fileUrl, setFileUrl] = useState<string>("")


    function downloadPdf() {
        window.open(resource, '_system');
        present('opening file', 3000)
        // if (isError) {
        //     present("error downloading pdf" + resource, 3000)
        // } else {
        //     if (resources != null) {
        //         var file = new Blob([resources!], { type: 'application/pdf;base64' });
        //         var fileURL = URL.createObjectURL(file);
        //         setFileUrl(URL.createObjectURL(file))
        //         window.open("https://drive.google.com/file/d/1jhc5n16trNlqRfkL8POVh3xFMaY029m3/view?usp=share_link", '_system');
        //         // present(fileURL, 3000)
        //         // var fileopener: FileOpenerOriginal
        //         FileOpener.open(resource, 'application/pdf')
        //         present('opening file', 3000)
        //         // Browser.open({ url: fileURL });
        //     } else {
        //         // alert('unable to download pdf')
        //     }
        // }
    }

    useEffect(() => {

    }, [])

    return (
        <IonButton onClick={downloadPdf}>Download</IonButton>
    )
}

export default DownloadPDFButton;