import { SessionInfo } from "../components/fivekey/SessionInterfaces";
import idMap from './idMap.json';

const idsToPaths = idMap as { [id: string]: string };

export const getSessionInfo: (id: string) => SessionInfo = id =>
{
    if(!(id in idsToPaths))
        throw new Error(`Id ${id} does not refer to any known session`);

    try
    {
        return require("./" + idsToPaths[id]);
    }
    catch(e)
    {
        throw e;
    }
}

export default getSessionInfo;