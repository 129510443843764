import React, { Component } from 'react';
import 'react-leaflet';
import { Circle, CircleMarker, GeoJSON, MapContainer, TileLayer, Tooltip, LayersControl, LayerGroup } from 'react-leaflet';
import { Geolocation, Geoposition, PositionError } from '@awesome-cordova-plugins/geolocation';

import 'leaflet/dist/leaflet.css';
import './GOLMap.css';
import L, { Map } from 'leaflet';
import { findObjectCenter } from '../utilities';
import { sampleZones } from '../sampleObjects/sampleZones';
import Control from "react-leaflet-custom-control";
import { IonButton, IonIcon } from '@ionic/react';
import { layersOutline, layersSharp, locateOutline } from 'ionicons/icons';
import { Subscription, Observable } from 'rxjs';
import NIJAPI from '../NIJAPI';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

interface GOLMapProps {
    showOptions?: boolean,
    initialZoom?: number,
    disableTracking?: boolean,
    center?: [number, number],
    onMapCreated?: (map: Map) => void,
    alwaysCenter?: boolean
}

interface GOLMapState {
    center: [number, number],
    map: Map | null,
    showWarningRadius: boolean,
    showRestrictionRadius: boolean,
    offCenter: boolean
}

function mapTilerProvider(x: any, y: any, z: any) {
    return `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`
}

const outerCircleFill = { fillColor: 'white' };
const innerCircleFill = { fillColor: 'blue' };

export default class GOLMap extends Component<GOLMapProps, GOLMapState>
{
    protected watch: Observable<Geoposition | PositionError> | null = null;
    protected geoSubscription: Subscription | null = null;
    protected updateTimeout?: NodeJS.Timeout = undefined;
    protected updating: boolean = false;

    //@ts-ignore
    constructor(props) {
        super(props);

        this.state = {
            center: this.props.center || [40.455850, -86.876070],
            map: null,
            showWarningRadius: true,
            showRestrictionRadius: true,
            offCenter: false
        }

        if (this.props.disableTracking)
            this.watch = null;
        else {
            this.watch = Geolocation.watchPosition({ enableHighAccuracy: true });

            this.geoSubscription = this.watch.subscribe(data => {
                if ((data as Geoposition).coords)
                    this.geoSuccess(data as Geoposition);
                else
                    this.geoError(data as PositionError)
            })

            // this.updateLocation();
        }
    }

    componentWillUnmount() {
        this.geoSubscription?.unsubscribe();
    }

    geoSuccess(pos: Geoposition) {
        this.updating = true;
        // console.log(pos);
        this.setState({ center: [pos.coords.latitude, pos.coords.longitude] });
        if ((!this.state.offCenter || this.props.alwaysCenter) && this.state.map)
            this.state.map.setView([pos.coords.latitude, pos.coords.longitude]);
        this.updating = false;
    }

    geoError(err: PositionError) {
        console.error(err.message);
    }

    updateLocation() {
        if (this.updateTimeout) clearTimeout(this.updateTimeout);

        // console.log("Updating Location...");
        // navigator.geolocation.getCurrentPosition(this.geoSuccess.bind(this), this.geoError.bind(this), { enableHighAccuracy: true });

        Geolocation.getCurrentPosition()
            .then(this.geoSuccess.bind(this))
            .catch(this.geoError.bind(this))

        Geolocation.watchPosition({ enableHighAccuracy: true })

        this.updateTimeout = setTimeout(this.updateLocation.bind(this), 5000);
    }

    setMap(newMap: Map) {
        setTimeout(() => {
            this.state.map!.invalidateSize();
        }, 500);

        this.setState({
            map: newMap
        });

        newMap.on('movestart', e => { if (!this.updating) this.setState({ offCenter: true }) });

        if (this.props.onMapCreated) this.props.onMapCreated(newMap);
    }

    render() {
        const {
            center,
            showWarningRadius,
            showRestrictionRadius
        } = this.state;

        return (
            // <Map provider={mapTilerProvider} center={center} zoom={15} width={365} height={350}>
            //     <Marker anchor={center}  />
            // </Map>
            <MapContainer
                className="gol-map"
                center={center}
                zoom={this.props.initialZoom || 16}
                style={{
                    height: '100%',
                    width: '100%'
                }}
                whenCreated={this.setMap.bind(this)}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />

                <LayersControl position="topright">
                    <LayersControl.Overlay checked name="Restriction Building (Green Sqaure)">
                        <LayerGroup>
                            {/* Draw known restriction zones */}
                            {
                                NIJAPI.instance.knownZones.map(v => {

                                    return (
                                        v.geom.type === 'Polygon' && <GeoJSON data={v.geom} key={v.locationName + v.id} style={{
                                            color: 'green'
                                        }}>
                                            <Tooltip>
                                                {v.locationName}
                                            </Tooltip>
                                        </GeoJSON>
                                    );
                                })
                            }
                        </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked name="Specific Location Restriction (Blue Pin)">
                        <LayerGroup>
                            {/* Draw known restriction zones */}
                            {
                                NIJAPI.instance.knownZones.map(v => {

                                    return (
                                        v.geom.type === 'Point' && <GeoJSON data={v.geom} key={v.locationName + v.id} style={{
                                            color: 'green'
                                        }}>
                                            <Tooltip>
                                                {v.locationName}
                                            </Tooltip>
                                        </GeoJSON>
                                    );
                                })
                            }
                        </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked name="Restriction Radius (Red)">
                        <LayerGroup>
                            {/* Draw known restriction zones' radii */}
                            {
                                NIJAPI.instance.knownZones.map(v => {
                                    // console.log(v.locationName, NIJAPI.instance.getRadius(v), v.geom);
                                    return (
                                        <Circle
                                            center={findObjectCenter(v.geom)}
                                            pathOptions={{
                                                stroke: true,
                                                weight: 2,
                                                opacity: 0.8,
                                                fill: false,
                                                color: '#f50057'
                                            }}
                                            radius={NIJAPI.instance.getRadius(v)}
                                            key={'c' + v.locationName + v.id}
                                        />
                                    );
                                })
                            }
                        </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked name="Warning Radius">
                        <LayerGroup>
                            {/* Radius for warning radius around participant */}
                            <Circle
                                center={center}
                                pathOptions={{
                                    stroke: true,
                                    weight: 2,
                                    opacity: 0.8,
                                    fill: false,
                                    color: '#f50057'
                                }}
                                radius={100}
                            />
                        </LayerGroup>
                    </LayersControl.Overlay>
                </LayersControl>


                {/* Marker for current position */}
                <CircleMarker
                    radius={8}
                    center={center}
                    pathOptions={{
                        stroke: true,
                        color: 'white',
                        fill: true,
                        fillColor: '#0055ff',
                        fillOpacity: 1,
                    }}
                />

                {/* Control buttons */}
                {/* {
                    this.props.showOptions &&
                    <Control position="topright">
                        <IonButton
                            shape="round"
                            fill={showRestrictionRadius ? "default" : "outline"}
                            onClick={e => {
                                this.setState({
                                    showWarningRadius: !showWarningRadius,
                                    showRestrictionRadius: !showRestrictionRadius
                                });
                            }}
                        >
                            <IonIcon icon={showRestrictionRadius ? layersSharp : layersOutline} slot='icon-only' />
                        </IonButton>
                    </Control>
                } */}
                {
                    !this.props.alwaysCenter && this.state.offCenter &&
                    <Control position="topleft">
                        <IonButton className="recenter-control" onClick={e => { e.preventDefault(); this.setState({ offCenter: false }, this.updateLocation.bind(this)) }}>
                            <IonIcon slot="icon-only" icon={locateOutline} />
                        </IonButton>
                    </Control>
                }
            </MapContainer>
        );
    }
}