import React, { useEffect } from 'react';
import { Radar, Bar, Doughnut } from 'react-chartjs-2'
import "./ProgressChart.css";
import { ChartData, Chart as ChartJS, Legend, ArcElement, LinearScale, CategoryScale, PointElement, LineElement, BarElement } from 'chart.js';
const DoughnutLabel = require("chartjs-plugin-doughnutlabel-rebourne");

ChartJS.register(Legend, ArcElement, LinearScale, CategoryScale, PointElement, LineElement, BarElement, DoughnutLabel);

interface FiveKeyProgress
{
    maxLevel: number,
    fiveKey: number,
    restriction: number,
    health: number,
    points: number
}

interface ProgressChartProps
{
    progress: FiveKeyProgress
}

const ProgressChart: React.FC<ProgressChartProps> = (props) => {
    const { progress } = props;
    const { points } = progress;
    const data: ChartData<"bar"> = {
        labels: ['Five Key', 'Restriction', 'Health'],
        datasets: [
            {
                label: 'Progress Level',
                data: [progress.fiveKey, progress.restriction, progress.health],
                backgroundColor: [
                    '#2C9400',
                    'lightgrey',
                    '#FFC82B',
                    '#E30837',
                ]
            }
        ]
    }
    
    const target = (Math.floor(points / 100) + 1) * 100;

    return (
        <>
        <div className="progressChartContainer" style={{
            position: "relative",
            height: "36vh"
        }}>
            <Doughnut data={{
                labels: [],
                datasets: [{
                    data: [points, target - points],
                    backgroundColor: [
                        '#07F52D',
                        'lavender'
                    ]
                }]
            }} options={{
                cutout: '80%',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    // @ts-ignore
                    doughnutlabel: {
                        labels: [
                            {
                                text: 'To Goal',
                                font: {
                                    size: '16'
                                }
                            },
                            {
                                text: ((points * 100) / target).toFixed(0) + "%",
                                font: {
                                    size: '36'
                                }
                            },
                            {
                                text: `${points} / ${target}`,
                                font: {
                                    size: '16'
                                }
                            }
                        ]
                    }
                }
            }} />

        </div>
        <div className="progressChartContainer" style={{
            position: "relative",
            height: "36vh"
        }}>
            <Bar data={data} options={{
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                scales: {
                    x: {
                        max: progress.maxLevel,
                        beginAtZero: true,
                        ticks: {
                            stepSize: 1
                        },
                        grid: {
                            display: true
                        },
                        title: {
                            display: true,
                            text: "Progress Level"
                        }
                    },
                    y: {
                        grid: {
                            display: false
                        }
                    }
                }
            }}/>

        </div>
        </>
    );
};

export default ProgressChart;