import { NIJMeeting } from '../NIJAPI';

export const BlankMeeting: NIJMeeting = {
    "end_time": "2021-06-23T16:49:02.863Z",
    "participantID": "",
    "purpose": "",
    "receiver": "case worker",
    "required": false,
    "sender": "participant",
    "start_time": "2021-06-23T16:49:02.863Z",
    "title": "",
    "type": "in-person",
    "zoomToken":"",
    "approved": false,
    "location": ""
};