import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
// import '../components/GOLMap';
import GOLMap from '../components/GOLMap';
import './Tab6.css';
import {refreshOutline} from "ionicons/icons";
import NIJAPI from "../NIJAPI";


var exclusionLocation = "Huntsville High School";
var lat0 = 34.730370;
var long0 = -86.586105;
var lat1 = 34.734370;
var long1 = -86.586105;
var lat2 = 34.734870;
var long2 = -86.586105;
var lat3 = 34.735370;
var long3 = -86.586105;
var lat4 = 34.730370;
var long4 = -86.580105;
var lat5 = 34.728370;
var long5 = -86.590105;

var lat = lat0;
var long = long0;

var i = -1;
// setInterval(function () {
//     i++
//     if (i == 2) {
//         lat = lat2;
//         long = long2;
//         i = -1;
//     }
//     else if (i == 1) {

//     }

// }, 5000);

setInterval(() => {

}, 5000);


function mapTilerProvider(x: any, y: any, z: any) {
    return `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`
}


const Tab1: React.FC = () => {
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        updateMap()
    }, [])

    const updateMap = () => {
        NIJAPI.instance.resetKnownZones()
        NIJAPI.instance.getRestrictionProfile()
            .then(_ => NIJAPI.instance.fetchGeneralRestrictionGeo())
            .then(_ => NIJAPI.instance.getSpecificRestrictions())
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Location</IonTitle>

                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="backgroundColor">


                <GOLMap showOptions/>

                {/* <p className="warningText">Warning:</p>
                <p className="warningText">You are within the exclusion zone:</p>
                <p className="warningText">{exclusionLocation}</p> */}


            </IonContent>
        </IonPage>
    );
};

export default Tab1;
