import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonImg, IonPage, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './UpdateModal.css'

import page1 from './shots/page_1_1.png'
import page2 from './shots/page_2_1.png'

const UpdateModal: React.FC<{
    onDismiss: () => void
}> = ({ onDismiss }) => {
    // Optional parameters to pass to the swiper instance.
    // See https://swiperjs.com/swiper-api for valid options.
    const slideOpts = {
        initialSlide: 1,
        speed: 400
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>New Features for {process.env.REACT_APP_GOALS_VERSION}</IonTitle>
                    <IonButtons slot='end'>
                        <IonButton onClick={onDismiss} >Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonSlides pager={true} options={slideOpts}>
                    <IonSlide>
                        {/* <h1>Slide 1</h1> */}
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>End time is now shown on your scheduled events</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonImg src={page1}></IonImg>
                            </IonCardContent>
                        </IonCard>

                    </IonSlide>
                    <IonSlide>
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Download documents from library</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonImg src={page2}></IonImg>
                            </IonCardContent>
                        </IonCard>
                    </IonSlide>
                    <IonSlide>
                        <h1>Slide 3</h1>
                    </IonSlide>
                </IonSlides>
            </IonContent>

        </IonPage>
    )

}

export default UpdateModal;