import { AppLauncher } from "@capacitor/app-launcher";
import { CallNumber } from "@awesome-cordova-plugins/call-number";
import { IonAlert, IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import React, { useState } from "react";
import { Meeting } from "./Meeting";

const MeetingItem: React.FC<{data:Meeting}> = ({data}) => {
  const [check,setCheck] = useState<boolean>(false)

  function makeCall(){
    CallNumber.callNumber("12516440533",true)
    .then( res => console.log('Launched dialer!',res))
    .catch(err => console.error('Error launching dialer',err))
  }

  function makeDuoCall(){
    AppLauncher.canOpenUrl({url:"com.google.android.apps.tachyon"}).then((success) => {
      console.log("Duo App is launchable.")
      if(success){
        AppLauncher.openUrl({url:"com.google.android.apps.tachyon"}).then((success) => {
          console.log("Duo app launched.")
        }).catch((err) => {
          console.log("Error launching Duo App: ", err)
        })
      }
    }).catch((err) => {
      console.log("Duo App not installed: ",err);
    })
  }

  function makeSkypeCall(){
    AppLauncher.canOpenUrl({url:"com.skype.raider"}).then((success) => {
      console.log("Skype App is launchable.")
      if(success){
        AppLauncher.openUrl({url:"com.skype.raider"}).then((success) => {
          console.log("Skype app launched.")
        }).catch((err) => {
          console.log("Error launching Skype App: ", err)
        })
      }
    }).catch((err) => {
      console.log("Skype App not installed: ",err);
    })
  }

  function makeZoomCall(){
    AppLauncher.canOpenUrl({url:"us.zoom.videomeetings"}).then((success) => {
      console.log("Zoom App is launchable.")
      if(success){
        AppLauncher.openUrl({url:"us.zoom.videomeetings"}).then((success) => {
          console.log("Zoom app launched.")
        }).catch((err) => {
          console.log("Error launching Skype App: ", err)
        })
      }
    }).catch((err) => {
      console.log("Zoom App not installed: ",err);
    })
  }

  if (data.type === "meeting"){
      return(
        <IonItem > 
          <IonAlert 
            isOpen={check}
            onDidDismiss={() => setCheck(false)}
            cssClass='my-custom-class'
            header={'Check In'}
            subHeader={'Subtitle'}
            message={'Good Luck on your Job Interview!'}
            buttons={['OK']}
            ></IonAlert>
              <IonLabel>
                {data.name}
                <p>Where: {data.location} </p> 
                <p>Time: {data.time}</p>
              </IonLabel>
              <IonButton onClick={()=> setCheck(true)}>Check In</IonButton>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
      )
  }

  else if(data.type === "call"){
    return(
        <IonItem > 
              <IonLabel>
                {data.name}
                <p>Where: {data.location} </p> 
                <p>Time: {data.time}</p>
              </IonLabel>
              <IonButton onClick={makeCall}>Call</IonButton>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
    )
  }

  else if(data.type === "zoom"){
    return(
        <IonItem > 
              <IonLabel>
                {data.name}
                <p>Where: {data.location} </p> 
                <p>Time: {data.time}</p>
              </IonLabel>
              <IonButton onClick={makeZoomCall}>Call</IonButton>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
    )
  }

  else if(data.type === "duo"){
    return(
        <IonItem > 
              <IonLabel>
                {data.name}
                <p>Where: {data.location} </p> 
                <p>Time: {data.time}</p>
              </IonLabel>
              <IonButton onClick={makeDuoCall}>Call</IonButton>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
    )
  }

  else if(data.type === "skype"){
    return(
        <IonItem > 
              <IonLabel>
                {data.name}
                <p>Where: {data.location} </p> 
                <p>Time: {data.time}</p>
              </IonLabel>
              <IonButton onClick={makeSkypeCall}>Call</IonButton>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
    )
  }

  else{
    return(
    <IonItem > 
              <IonLabel>
                {data.name}
                <p>Where: {data.location} </p> 
                <p>Time: {data.time}</p>
              </IonLabel>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
    )
  }
    
}

export default MeetingItem;