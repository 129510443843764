import React from "react";
import ScheduleLog from "./ScheduleLog";
import SchedulingLog from "./SchedulingLogInterface";

const ScheduleLogList: React.FC<{data:SchedulingLog[]}> = ({data,children}) => {
    // const items:ReactElement = 
    // )
  
    return(
      <div>
        {data.map((log,index) => 
          (<ScheduleLog key={index} data={log}></ScheduleLog>)
        )}
      </div>
    )
  }
  
  export default ScheduleLogList;