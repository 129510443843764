import { Calendar } from '@awesome-cordova-plugins/calendar';
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonPage, IonRow, IonText, IonTextarea, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { callOutline, cameraOutline, createOutline, sendOutline, trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Call } from '../../components/scheduler/Call';
import CallItemList from '../../components/scheduler/CallList';
import Checklist from '../../components/scheduler/ChecklistInterface';
import ChecklistList from '../../components/scheduler/ChecklistList';
import { Meeting } from '../../components/scheduler/Meeting';
import MeetingItemList from '../../components/scheduler/MeetingItemList';
import SchedulingLog from '../../components/scheduler/SchedulingLogInterface';
import NIJAPI from '../../NIJAPI';
import { getMonthName, getWeeday, statusColor } from '../../utilities';
import './Tab3.css';


//TODO: Strike through item without refreshing the page
//TODO: Filter checklist items shown based on the day chosen
//TODO: Determine how to properly generate subtext for calls and meetings
//TODO: Fix spacing in bottom toolbar

interface Tab3Props extends RouteComponentProps<{
  id: string;
}> {}

const Tab3: React.FC<Tab3Props> = ({match}) => {

  const [items,setItems] = useState<Checklist[]>([])
  const [log,setLog] = useState<SchedulingLog>({id:0,day:"NA",caseWorker:"NA",dueTime:"NA",date:"NA",description:"NA",status:"NA",month:"NA",year:0,checklistCount:0})
  const [editMode,setEditMode] = useState<boolean>(false)
  const [isNewNote,setIsNewNote] = useState<boolean>(false)
  const [meetings,setMeetings] = useState<Meeting[]>([])
  const [calls,setCalls] = useState<Call[]>([])


  function fetchScheduleLogs() {
    function pad(number:number):string {
      if (number < 10) {
        return '0' + number.toFixed();
      }
      return number.toFixed();
    }
    let logs:SchedulingLog[] = []
    let date:Date = new Date(match.params.id)
    let queryDate:string = `${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate()+2)}`
    // Axios.get(`http://192.168.0.168:1337/scheduling-logs?date_logged=${queryDate}`).then((response)=>{
    // console.log(queryDate);
    // NIJAPI.instance.fetchLogs(`?date_logged=${match.params.id}`).then(data => {
    //   console.log(data);

    // // let data:[] = response.data;
    
    // for (let index = 0; index < data.length; index++) {
    //   const element:any = data[index];

    //   console.log(element);
    //   let schedulingLog:SchedulingLog = {
    //     id:element.id,
    //     dueTime:element.due_time,
    //     status:element.status,
    //     caseWorker:element.case_worker,
    //     description:element.description,
    //     month:getMonthName(element.date_logged),
    //     day:(date.getDate()+1).toFixed(),
    //     year:date.getFullYear(),
    //     date:element.date_logged,
    //     checklistCount:0
    //   }

    //   console.log(schedulingLog);
    //   setLog(schedulingLog)
      
    // }

      

    // }).catch((err) => {
    //   console.error(err)
    // })
  }

  function fetchChecklists(){
    let items:Checklist[] = []
    
    // Axios.get(`http://192.168.0.168:1337/scheduling-checklists/?date=${match.params.id}`).then((response) =>{
    // NIJAPI.instance.fetchChecklist(`/?date=${match.params.id}`).then(data => {
    //   console.log(data);
      
    //   // let data:[] = response.data
    //   for (let index = 0; index < data.length; index++) {
    //     const element:any = data[index];

    //     let checklist:Checklist = {
    //       id:element.id,
    //       completed:element.completed,
    //       date:element.date,
    //       name:element.name
    //     }

    //     items.push(checklist)
        
    //   }

    //   setItems(items)
    // }).catch((err) => {
    //   console.error(err)
    // })
  }

  function createScheduleLog(){
    
  }

  function updateScheduleLog(){

  }

  function descriptionBoxChanged(value:string){
    if (isNewNote){
      //POST Request
      console.log('post request')
    }

    else{
      //PUSH Request
      console.log('put request')
    }
  }

  function fetchMeetings(){
    let meetings:Meeting[] = []

    // Axios.get("http://192.168.0.168:1337/scheduling-meetings/").then((response)=> {
    // NIJAPI.instance.fetchMeetings().then(data => {
    //   console.log(data);
    //   // let data:any = response.data;
      
    //   for (let index = 0; index < data.length; index++) {
    //     const element = data[index];
        
    //     let meeting:Meeting = {
    //       name:element.name,
    //       type:element.type,
    //       location:element.location,
    //       time:element.time,
    //       date:element.date
    //     }

    //     meetings.push(meeting);
        
    //   }

    //   setMeetings(meetings);
    // })
  }

  function fetchCalls(){
    let calls:Call[] = []

    // Axios.get("http://192.168.0.168:1337/scheduling-phone-calls/").then((response) => {
    // NIJAPI.instance.fetchPhoneCalls().then(data => {
    //   console.log(data);
    //   // let data:any = response.data;

    //   for (let index = 0; index < data.length; index++) {
    //     const element = data[index];

    //     let call:Call = {
    //       callStatus:element.status,
    //       name:element.name,
    //       receiver:element.receiver,
    //       sender:element.sender,
    //       topic:element.topic
    //     }

    //     calls.push(call)
        
    //   }

    //   setCalls(calls)
    // }).catch((err) => {
    //   console.error(err);
    // })
  }

  // function hasReadWritePermission() {
  //   Calendar.hasReadWritePermission(
  //     function(result) {
  //       // if this is 'false' you probably want to call 'requestReadWritePermission' now
  //       alert(result);
  //     }
  //   )
  // }

  function requestReadWritePermission() {
    // no callbacks required as this opens a popup which returns async
    Calendar.requestReadWritePermission();
  }

  function syncCalendarEvents(){

    var cal = Calendar;

    Calendar.createCalendar("Test Calendar 2").then((success) => {
      console.log('Succesfully created calendar. :', success)
    }).catch((err) => {
      console.error('Error creating calendar: ', err);
    })

    Calendar.listCalendars().then((success) => {
      var data = success;
      console.log("Successfully Listed Calendars: ", data.toString())
    }).catch((err) => {
      console.log('Error retrieving list of calendars: ', err)
    })
 
    
    var calOptions = Calendar.getCalendarOptions()
    // calOptions.calendarId = 2
    calOptions.calendarName = "Test Calendar 2"
    console.log('cal options: ', calOptions.calendarName)

    Calendar.hasReadWritePermission().then((res) => {
      console.log('Check calendar permissions')
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })

    Calendar.requestReadWritePermission().then((v) => {
      Calendar.createEventWithOptions("My Event","Downtown","Memo",new Date(2020,9,20,8,0,0,0),new Date(2020,9,20,9,0,0,0),calOptions).then((success) => {
        console.log('Success:', success.toString())
        console.log('Succesfully created calendar event!');
      }).catch((err) => {
        console.error('Error creating calendar event:',err);
      })
    });

    
        // calOptions.id = data.id
        


    
  }

  useIonViewWillEnter(()=> {
    let newNote:boolean = isNewNote;
    if (match.params.id === "0000-00-00"){
      newNote = true;
      setIsNewNote(true)
    }

    if (!newNote){
      fetchScheduleLogs()
      fetchChecklists()
      fetchMeetings()
      fetchCalls()
      syncCalendarEvents()
    }

    else{
      let todaysDate:Date = new Date()
      let scheduleLog:SchedulingLog = {
        id:0,
        checklistCount:0,
        description:"",
        status:"Not Submitted",
        caseWorker:"NA",
        date:todaysDate.toISOString().split("T")[0],
        dueTime:"NA",
        day:todaysDate.getDate().toFixed(),
        month:getMonthName(todaysDate.toISOString()),
        year:todaysDate.getFullYear()
      }

      setLog(scheduleLog)
    }
    
  })
  
  
  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton/>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={createOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={sendOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={trashOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          
        </IonToolbar>
      </IonHeader>
      <IonContent >
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem lines="none">
                <IonText>{log.day} {log.month} {log.year} </IonText>
              </IonItem>
                
            </IonCol>

            <IonCol>
            <IonItem lines="none" color={statusColor(log.status)}>
              <IonText>{log.status}</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonItem lines="none">
                <IonText>{getWeeday(log.date)}</IonText>
              </IonItem>
            </IonCol>
            <IonCol>
            <IonItem lines="none">
                <IonText>2:55pm</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItemDivider class="ion-margin-bottom"></IonItemDivider>

        <IonItem lines="none">
        <IonTextarea class="ion-padding" value={log.description} onIonChange={e => descriptionBoxChanged(e.detail.value!)}>
        </IonTextarea>
        </IonItem>

          <ChecklistList data={items}></ChecklistList>

        <CallItemList data={calls}></CallItemList>

        <MeetingItemList data={meetings}></MeetingItemList>
        
        
      </IonContent>

      <IonFooter>
        <IonToolbar >

          <IonButtons slot="start">
            <IonButton >
              <IonIcon icon={cameraOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start">
            <IonButton >
              <IonIcon icon={sendOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={trashOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={callOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          
        </IonToolbar>
        </IonFooter>
    </IonPage>
  );
};

export default Tab3;
