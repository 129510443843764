import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from "./State";
// import { BackgroundGeolocation, BackgroundGeolocationEvents, BackgroundGeolocationLocationProvider } from '@awesome-cordova-plugins/background-geolocation';
import NIJAPI from './NIJAPI';
// import { isPlatform } from '@ionic/core';
import { LocalNotificationDescriptor, LocalNotifications } from '@capacitor/local-notifications';
import { registerPlugin } from '@capacitor/core';
import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
import Restrictions, { toGeojson } from './data/restrictions';
import package_json from '../package.json';
import { isPlatform } from '@ionic/react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import MyApp from './App';

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");

let notifications: LocalNotificationDescriptor[] = [];
axiosRetry(axios, { retries: 3 });

console.info(`GOALS app version ${package_json.version}`);
if (process.env.REACT_APP_GOALS_DEV === '1')
    console.info('Development build');

ReactDOM.render(<AppContextProvider>
    <MyApp />
</AppContextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// console.log(Restrictions.map(toGeojson));

console.log('outside platform')
if (isPlatform('capacitor')) {
    console.log('inside platform')
    LocalNotifications.createChannel({
        id: 'goals-warning',
        name: 'GOALS Warnings',
        description: 'Warnings from GOALS app, e.g. nearing restriction zones',
        importance: 4,
        visibility: -1,
        sound: 'holdon.mp3',
        vibration: true
    });

    // Set up geolocation features

    BackgroundGeolocation.addWatcher({
        distanceFilter: 50,
        backgroundTitle: 'Checking your area',
        backgroundMessage: 'GOALS is informing you of nearby restrictions',
        requestPermissions: true
    }, function callback(location, error) {
        // Triggered when location change is detected
        console.info(`Location update`, location);

        if (error) {
            // Check if location permission must be granted
            if (error.code === "NOT_AUTHORIZED") {
                if (window.confirm("GOALS requires permission to warn you of nearby restriction zones.\n\nOpen settings now?"))
                    BackgroundGeolocation.openSettings();
            }

            return console.error(error);
        }

        if (!location)
            return;

        // if(NIJAPI.instance.authenticated)
        // NIJAPI.instance.pushLocationData(location.latitude, location.longitude, 100)
        //     .then(async (response) => {
        //         console.log(response);

        //         // Check if any violations have been detected
        //         if (response.gViolationCount > 0 || response.sViolationCount > 0) {
        //             // Create a notification warning of restriction zones
        //             notifications = (await LocalNotifications.schedule({
        //                 notifications: [
        //                     {
        //                         id: 1337,
        //                         channelId: 'goals-warning',
        //                         title: 'Near restricted area!',
        //                         body: 'Tap to see restriction zones'
        //                     }
        //                 ]
        //             })).notifications;
        //         }
        //         else {
        //             // Clear all location warnings if there are no current violations
        //             LocalNotifications.cancel({
        //                 notifications: [{ id: 1337 }]
        //             });
        //         }
        //     });
    }).then(id => {
        // Remove watcher when it is no longer needed
        console.log(id);
        // BackgroundGeolocation.removeWatcher({id: id});
    });
}