import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonLabel, useIonLoading, IonButtons, IonButton, IonIcon, IonItem } from "@ionic/react"
import { syncOutline } from "ionicons/icons"
import React, { useEffect } from "react"
import DownloadPDFButton from "../../components/library/DownloadPDFButton"
import useLibrary from "../../hooks/useLibrary"

interface LibraryResource {
    id: number,
    name: string,
    url: string,
    type: string,
    size: number,
    driveUrl: string
}

const ResourceViewer: React.FC = () => {

    const [resources, isLoading, isError, refresh] = useLibrary<LibraryResource>()
    const [present, dismiss] = useIonLoading();


    useEffect(() => {
        if (isLoading) {
            //present({ message: "Loading library...", })
        }

        if (isError) {
            dismiss()
        }

        if (resources != null) {
            dismiss()
        }

    }, [resources, isLoading, isError, present, dismiss]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Library</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            shape="round"
                            onClick={refresh}
                        >
                            <IonIcon icon={syncOutline} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="backgroundColor">

                {resources?.map((resource) => {
                    return (
                        <IonCard >
                            <IonCardHeader>
                                <IonCardTitle>{resource.name}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                {/* <IonLabel>Guide for building a resume</IonLabel> */}
                            </IonCardContent>
                            <IonItem>
                                <IonButton href={`/resource/${resource.id}`}>View</IonButton>
                                <DownloadPDFButton resource={resource.driveUrl} />
                            </IonItem>
                        </IonCard>
                    )
                })}
            </IonContent>
        </IonPage>
    )
}

export default ResourceViewer;