import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonLabel, IonModal, IonPage, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack, arrowForward, flashOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import TaskItem from '../components/TaskItem';
import { sampleTasks } from '../sampleObjects/sampleTasks';
import { AppContext } from '../State';
import './Tab3health.css';


const slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoHeight: true
};

const Tab3health: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);

    const { state, dispatch } = useContext(AppContext);

    return (
    <IonPage>
      <IonHeader>
                <IonToolbar>
                    <IonButtons><IonButton fill="clear" color='light' routerLink="/taskswork"><IonIcon className="titleIcons" icon={arrowBack} /></IonButton><IonTitle>Health</IonTitle><IonButton fill="clear" color='light' routerLink="/taskswork"><IonIcon className="titleIcons" icon={arrowForward} /></IonButton></IonButtons>
        </IonToolbar>
      </IonHeader>
            <IonContent className="backgroundColor">
       
                <p><br/></p>
                
                <IonImg className="houseImg" src="../assets/lb.png" />
                <div className="scoreAmount">
                    <h1>{state.elec} <IonIcon className="iconPic" icon={flashOutline} /></h1>
                </div>

                

                <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)} value="recent">
                    
                    <IonSegmentButton onClick={() => {
                        //@ts-ignore
                        document.getElementById("tab3healthslides").slideTo(0);
                    }} value="recent">
                        <IonLabel>Recent</IonLabel>
                    </IonSegmentButton>
                   
                    <IonSegmentButton onClick={() => {
                        //@ts-ignore
                        document.getElementById("tab3healthslides").slideTo(1);
                    }} value="featured">
                        <IonLabel>Featured</IonLabel>
                    </IonSegmentButton>
                
                    <IonSegmentButton onClick={() => {
                        //@ts-ignore
                        document.getElementById("tab3healthslides").slideTo(2);
                    }} value="all">
                        <IonLabel>All</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                
                    <IonSlides id="tab3healthslides" options={slideOpts}>
                        

                    <IonSlide>
                        <div className="slide1">
                            {/* <Rtif boolean={state.taskH1}>
                                <IonButton onClick={() => setShowModal(true)} expand='block' color='light'><div className="buttonLeftCol">Meditate for 3 Minutes</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25 <IonIcon className="iconPicHealth" icon={flashOutline} /></IonButton>
                            </Rtif> */}

                            <TaskItem task={ sampleTasks[7] } />
                        </div>

                    </IonSlide>
                    <IonSlide>
                        <div className="slide1">
                            {/* <Rtif boolean={state.taskH2}>
                                <IonButton onClick={() => setShowModal2(true)} expand='block' color='light'><div className="buttonLeftCol">Exercise for 20 Minutes</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;35 <IonIcon className="iconPicHealth" icon={flashOutline} /></IonButton>
                            </Rtif> */}

                            <TaskItem task={ sampleTasks[8] } />
                        </div>
                    </IonSlide>
                    <IonSlide>
                        <div className="slide1">
                            {/* <Rtif boolean={state.taskH1}>
                                <IonButton onClick={() => setShowModal(true)} expand='block' color='light'><div className="buttonLeftCol">Meditate for 3 Minutes</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25 <IonIcon className="iconPicHealth" icon={flashOutline} /></IonButton>
                            </Rtif>
                            <Rtif boolean={state.taskH2}>
                                <IonButton onClick={() => setShowModal2(true)} expand='block' color='light'><div className="buttonLeftCol">Exercise for 20 Minutes</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;35 <IonIcon className="iconPicHealth" icon={flashOutline} /></IonButton>
                            </Rtif> */}

                            <TaskItem task={ sampleTasks[7] } />
                            <TaskItem task={ sampleTasks[8] } />
                        </div>
                    </IonSlide>
                </IonSlides>
                <IonModal isOpen={showModal} cssClass='my-custom-class'>
                    <h1 className="missionCategory">Meditate for 3 minutes</h1>
                    <IonImg className="houseImg" src="../assets/clock.jpg" />
                    <IonButton color="secondary" onClick={() => setShowAlert1(true)}>Start</IonButton>
                    <IonAlert
                        isOpen={showAlert1}
                        onDidDismiss={() => {
                            setShowAlert1(false);
                            dispatch({
                                type: 'setTaskH1',
                                taskH1: false
                            });
                            dispatch({
                                type: 'setElec',
                                elec: state.elec + 25
                            })
                            setShowModal(false);
                        }}
                        cssClass='my-custom-class'
                        header={'Good Job!'}
                        
                        message={'Press "Confirm" to confirm your completion of the mission.'}
                        buttons={['Confirm']}
                    />
                  <IonButton onClick={() => setShowModal(false)} fill="clear">Close</IonButton>
                </IonModal>
                <IonModal isOpen={showModal2} cssClass='my-custom-class'>
                    <h1 className="missionCategory">Exercise for 20 Minutes</h1>
                    <IonImg className="houseImg" src="../assets/clock.jpg" />
                    <IonButton color="secondary" onClick={() => setShowAlert2(true)}>Start</IonButton>
                    <IonAlert
                        isOpen={showAlert2}
                        onDidDismiss={() => {
                            setShowAlert2(false);
                            dispatch({
                                type: 'setTaskH2',
                                taskH2: false
                            });
                            dispatch({
                                type: 'setElec',
                                elec: state.elec + 35
                            })
                            setShowModal2(false);
                        }}
                        cssClass='my-custom-class'
                        header={'Good Job!'}

                        message={'Press "Confirm" to confirm your completion of the mission.'}
                        buttons={['Confirm']}
                    />
                    <IonButton onClick={() => setShowModal2(false)} fill="clear">Close</IonButton>
                </IonModal>


      </IonContent>
    </IonPage>
  );
};

export default Tab3health;
