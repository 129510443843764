import { useEffect, useState } from "react"
import { Drivers, Storage } from '@ionic/storage';
import { useIonAlert, useIonModal } from "@ionic/react";
import UpdateModal from "../components/updates/UpdateModal";

const useUpdateChecker = (version: string) => {
    const storage = new Storage({
  name: 'version_checker',
  driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });
    
    const [updated, setUpdated] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
      const [present, dismiss] = useIonModal(UpdateModal,{onDismiss:handleDismiss});

    function handleDismiss() {
        storage.set(process.env.REACT_APP_GOALS_VERSION!, "true").then(() => {
         dismiss()
        }).catch((err) => {
        console.error(err)
    })

       
    }
   
    useEffect(() => {
 async function checkVersion() {
        try {
            let store: Storage = await storage.create()
            const keys: string[] = await store.keys()
            var versionFound = false;

            keys.forEach(key => {
                if (key === version) {
                    versionFound = true
                }
            });

            if (!versionFound) {
                //present()
            }
            
        } catch (error) {
            setIsError(true)
        }
    }
        checkVersion()
        return () => {}
        
    }, [isError,storage])
    
    return [isError] as const
 }

export default useUpdateChecker;