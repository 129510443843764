export  const FirebaseConfig = {

    apiKey: "AIzaSyCDFh6ef8LkrktUxl4bSm_ViRMg9JVhrzs",

    authDomain: "nij-authentication-dev.firebaseapp.com",

    projectId: "nij-authentication-dev",

    storageBucket: "nij-authentication-dev.appspot.com",

    messagingSenderId: "374498573781",

    appId: "1:374498573781:web:dc514e5d62ec35a997515c",

    measurementId: "G-213K2C29HH"

  };
