import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonPage, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack, arrowForward, constructOutline, searchOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import TaskItem from '../components/TaskItem';
import { sampleTasks } from '../sampleObjects/sampleTasks';
import { AppContext } from '../State';
import './Tab3.css';



const slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoHeight: true
};

const Tab3: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [text, setText] = useState<string>();
    const [text2, setText2] = useState<string>();
    const [text3, setText3] = useState<string>();
    const [text4, setText4] = useState<string>();
    const { state, dispatch } = useContext(AppContext);
   

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons><IonButton fill="clear" color='light' routerLink="/taskshealth"><IonIcon className="titleIcons" icon={arrowBack} /></IonButton><IonTitle>Work</IonTitle><IonButton fill="clear" color='light' routerLink="/jobs"><IonIcon className="titleIcons" icon={searchOutline} /></IonButton><IonButton fill="clear" color='light' routerLink="/taskshealth"><IonIcon className="titleIcons" icon={arrowForward} /></IonButton></IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="backgroundColor">
               
                <p><br /></p>
                
                <IonImg className="houseImg" src="../assets/tree-ii.png" />
                <div className="scoreAmount">
                    <h1>{state.wood} <IonIcon className="iconPic" icon={constructOutline} /></h1>
                </div>

              

                {/*-- Segment with default selection --*/}
                <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)} value="recent">
                    
                    <IonSegmentButton onClick={() => {
                        //@ts-ignore
                        document.getElementById("tab3slides").slideTo(0);
                    }} value="recent">
                        <IonLabel>Recent</IonLabel>
                    </IonSegmentButton>
                    
                    <IonSegmentButton onClick={() => {
                        //@ts-ignore
                        document.getElementById("tab3slides").slideTo(1);
                    }} value="featured">
                        <IonLabel>Featured</IonLabel>
                    </IonSegmentButton>
                    
                    <IonSegmentButton onClick={() => {
                        //@ts-ignore
                        document.getElementById("tab3slides").slideTo(2);
                    }} value="all">
                        <IonLabel>All</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                <IonSlides id="tab3slides" options={slideOpts}>
                    <IonSlide>
                        <div className="slide1">

                            {/* <Rtif boolean={state.taskW1}>
                                <IonButton onClick={() => setShowModal(true)} expand='block' color='light' className="taskButtons"><div className="buttonLeftCol">Complete Job Questionnaire</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25 <IonIcon className="iconPicWork" icon={constructOutline} /></IonButton>
                            </Rtif>
                            <Rtif boolean={state.taskW2}>
                                <IonButton onClick={() => setShowModal2(true)} expand='block' color='light' className="taskButtons"><div className="buttonLeftCol">Visit a Job Site</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15 <IonIcon className="iconPicWork" icon={constructOutline} /></IonButton>
                            </Rtif>
                            <Rtif boolean={state.taskW3}>
                                <IonButton onClick={() => setShowModal3(true)} expand='block' color='light' className="taskButtons"><div className="buttonLeftCol">Review Job Recommendations</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25 <IonIcon className="iconPicWork" icon={constructOutline} /></IonButton>
                            </Rtif> */}

                            <TaskItem task={ sampleTasks[3] } />
                            <TaskItem task={ sampleTasks[4] } />
                            <TaskItem task={ sampleTasks[2] } />
    
                        </div>

                    </IonSlide>
                    <IonSlide>
                        <div className="slide1">
                            {/* <Rtif boolean={state.taskW2}>
                                <IonButton onClick={() => setShowModal2(true)} expand='block' color='light' className="taskButtons"><div className="buttonLeftCol">Visit a Job Site</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15 <IonIcon className="iconPicWork" icon={constructOutline} /></IonButton>
                            </Rtif> */}

                            <TaskItem task={ sampleTasks[4] } />
                        </div>
                    </IonSlide>
                    <IonSlide>
                        <div className="slide1">

                            {/* <Rtif boolean={state.taskW1}>
                                <IonButton onClick={() => setShowModal(true)} expand='block' color='light' className="taskButtons"><div className="buttonLeftCol">Complete Job Questionnaire</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25 <IonIcon className="iconPicWork" icon={constructOutline} /></IonButton>
                            </Rtif>
                            <Rtif boolean={state.taskW2}>
                                <IonButton onClick={() => setShowModal2(true)} expand='block' color='light' className="taskButtons"><div className="buttonLeftCol">Visit a Job Site</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15 <IonIcon className="iconPicWork" icon={constructOutline} /></IonButton>
                            </Rtif>
                            <Rtif boolean={state.taskW3}>
                                <IonButton onClick={() => setShowModal3(true)} expand='block' color='light' className="taskButtons"><div className="buttonLeftCol">Review Job Recommendations</div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25 <IonIcon className="iconPicWork" icon={constructOutline} /></IonButton>
                            </Rtif> */}

                            <TaskItem task={ sampleTasks[3] } />
                            <TaskItem task={ sampleTasks[4] } />
                            <TaskItem task={ sampleTasks[2] } />
                        </div>
                    </IonSlide>
                </IonSlides>


                <IonModal isOpen={showModal} cssClass='my-custom-class'>
                    <h1 className="missionCategory">Complete a Job Questionnaire</h1>
                    <IonList>
                        
                        <IonItemDivider>What are you interested in?</IonItemDivider>
                        <IonItem>
                            <IonInput value={text} placeholder="Enter Input" onIonChange={e => setText(e.detail.value!)} clearInput></IonInput>
                        </IonItem>
                        <IonItemDivider>Would you prefer to work inside or outside?</IonItemDivider>
                        <IonItem>
                            <IonInput value={text2} placeholder="Enter Input" onIonChange={e => setText2(e.detail.value!)} clearInput></IonInput>
                        </IonItem>
                        <IonItemDivider>Are you confident using computers?</IonItemDivider>
                        <IonItem>
                            <IonInput value={text3} placeholder="Enter Input" onIonChange={e => setText3(e.detail.value!)} clearInput></IonInput>
                        </IonItem>
                        <IonItemDivider>Do you enjoy interacting with people?</IonItemDivider>
                        <IonItem>
                            <IonInput value={text4} placeholder="Enter Input" onIonChange={e => setText4(e.detail.value!)} clearInput></IonInput>
                        </IonItem>

                    </IonList>
                    <IonButton color="success" onClick={() => setShowAlert1(true)}>Submit</IonButton>
                    <IonAlert
                        isOpen={showAlert1}
                        onDidDismiss={() => {
                            setShowAlert1(false);
                            dispatch({
                                type: 'setTaskW1',
                                taskW1: false
                            });
                            dispatch({
                                type: 'setTaskW3',
                                taskW3: true
                            });
                            dispatch({
                                type: 'setWood',
                                wood: state.wood + 25
                            })
                            setShowModal(false);
                        }}
                        cssClass='my-custom-class'
                        header={'Good Job!'}

                        message={'Press "Confirm" to confirm your completion of the mission.'}
                        buttons={['Confirm']}
                    />
                    <IonButton onClick={() => setShowModal(false)} fill="clear">Close</IonButton>
                </IonModal>

                <IonModal isOpen={showModal2} cssClass='my-custom-class'>
                    <h1 className="missionCategory">Visit a Job Site</h1>
                    <IonImg className="houseImg" src="../assets/job.png" />
                    <IonButton color="success" onClick={() => setShowAlert2(true)}>Start</IonButton>
                    <IonAlert
                        isOpen={showAlert2}
                        onDidDismiss={() => {
                            setShowAlert2(false);
                            dispatch({
                                type: 'setTaskW2',
                                taskW2: false
                            });
                            dispatch({
                                type: 'setWood',
                                wood: state.wood + 15
                            })
                            setShowModal2(false);
                        }}
                        cssClass='my-custom-class'
                        header={'Good Job!'}

                        message={'Your mission will complete as soon as your case worker approves the results.'}
                        buttons={['Confirm']}
                    />
                    <IonButton onClick={() => setShowModal2(false)} fill="clear">Close</IonButton>
                </IonModal>

                <IonModal isOpen={showModal3} cssClass='my-custom-class'>
                    <h1 className="missionCategory">Review Job Recommendations</h1>
                    <h2>Based on the information we have recieved, we would recommend these career paths for you.</h2>
                    <p>Landscaping</p>
                    <p>Construction</p>
                    <p>European Pop Star</p>
                    <p>Sales</p>
                    <IonButton color="success" onClick={() => setShowAlert3(true)}>Confirm</IonButton>
                    <IonAlert
                        isOpen={showAlert3}
                        onDidDismiss={() => {
                            setShowAlert3(false);
                            dispatch({
                                type: 'setTaskW3',
                                taskW3: false
                            });
                            dispatch({
                                type: 'setWood',
                                wood: state.wood + 25
                            })
                            setShowModal3(false);
                        }}
                        cssClass='my-custom-class'
                        header={'Good Job!'}

                        message={'Press "Confirm" to confirm your completion of the mission. You can review this information at any time in the Library.'}
                        buttons={['Confirm']}
                    />
                    <IonButton onClick={() => setShowModal3(false)} fill="clear">Close</IonButton>
                </IonModal>
    
                <div id="X"></div>
          
            </IonContent>
        </IonPage>
    );
};

export default Tab3;