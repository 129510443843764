export type CalendarEventApproval = 'approved' | 'pending' | 'rejected';
export type CalendarEventType = 'meeting' | 'job' | 'other' | 'zoom' | 'in-person' | 'phone';
export type CallStatus = 'missed' | 'answered' | 'other';
export type MeetingType = 'meeting' | 'call' | 'zoom' | 'duo' | 'skype' | 'other';

export const EventTypeColors: {[type in CalendarEventType]: string} = {
    meeting: '#1976D2',
    phone: '#1976D2',
    "in-person": '#1976D2',
    job: '#7E57C2',
    zoom: '#7E57C2',
    other: 'black'
};

// Checklist items, e.g. "Complete paperwork before meeting"
export interface ChecklistItem
{
    id: string,                         // Checklist ID
    userid?: string,                    // Participant's user ID
    completed: boolean,                 // Is this item completed?
    name: string,                       // Name of this checklist item
    event?: CalendarEvent               // Event which this item belongs to
}

// Phone calls associated with an event
export interface Call
{
    id: string,                         // Call ID
    userid?: string,                    // Participant's user ID
    name: string,                       // Name associated with the call, e.g. the case worker's name
    topic: string,                      // Topic of the call
    callStatus: CallStatus,             // Status of the call: missed, answered, or other
    sender: string,                     // Call sender
    receiver: string,                   // Call receiver
    event?: CalendarEvent               // Event which this call belongs to
}

// Scheduled meetings
export interface Meeting
{
    id: string,                         // Meeting ID
    userid?: string,                    // Participant's user ID
    name: string,                       // Name associated with the meeting
    type: MeetingType,                  // Type of meeting: meeting (in-person), call, zoom, duo, skype, other
    location: string,                   // Location of meeting
    time: string,                       // Time of the meeting
    event?: CalendarEvent               // Event which this meeting belongs to
}

// Scheduled events
export interface CalendarEvent
{
    id: string,                         // Event ID
    userid?: string,                    // Participant's user ID
    name: string,                       // Name of this event
    description: string,                // Description of this event
    timestamp: string,                  // Timestamp of event start as ISO date string
    date?: Date,                         
    approval: CalendarEventApproval,    // Approval status of event: approved, pending, or rejected
    caseWorker?: string,                // Case worker associated with this event
    type: CalendarEventType,            // Type of event: meeting, job, other (more types should be added)
    location: string,                   // Event location
    checklist: ChecklistItem[],         // Checklist items associated with this event
    meetings: Meeting[],                // Meetings associated with this event
    calls: Call[]                       // Calls associated with this event
}

export const BlankEvent: CalendarEvent = {
    id: '',
    userid: '',
    name: '',
    description: '',
    timestamp: '',
    approval: 'pending',
    caseWorker: '',
    type: 'other',
    location: '',
    checklist: [],
    meetings: [],
    calls: []
}