import { IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonProgressBar, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import { Task } from '../NIJAPI';
import RewardIcon from "./RewardIcon";
import './TaskItem.css';


interface TaskItemProps
{
    task: Task,
    onClick?: (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => void
}

export default class TaskItem extends Component<TaskItemProps>
{
    render()
    {
        let task = this.props.task;

        return (
            <IonCard className="task-card" button onClick={this.props.onClick}>
                { task.progress > 0 && 
                    <IonProgressBar value={task.progress / 100} />
                }
                
                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="10">
                                <IonCardTitle>
                                    {task.task.taskName}
                                </IonCardTitle>
                                
                                {task.task.taskDescription}
                            </IonCol>

                            <IonCol size="2">
                                <RewardIcon type={task.task.rewardType} amount={task.task.rewardAmount} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
        );
    }
}