import React, { createContext, useReducer } from "react";



const initialState = {
    count: 0,
    wood: 0,
    elec: 0,
    taskW1: true,
    taskW2: true,
    taskW3: false,
    taskH1: true,
    taskH2: true,
    nameOfUser:"Ethan",

}

type dispatchActionType = Partial<typeof initialState> & { type: string };

//@ts-ignore
let AppContext = createContext<{ state: typeof initialState, dispatch: React.Dispatch<dispatchActionType>}>();
//@ts-ignore
let reducer = (state: typeof initialState, action: dispatchActionType ): typeof initialState => {
    switch (action.type) {
        case "setCount": {
            return { ...state, count: action.count! }
        }
        case "setWood": {
            return { ...state, wood: action.wood! }
        }
        case "setElec": {
            return { ...state, elec: action.elec! }
        }
        case "setTaskW1": {
            return { ...state, taskW1: action.taskW1! }
        }
        case "setTaskW2": {
            return { ...state, taskW2: action.taskW3! }
        }
        case "setTaskW3": {
            return { ...state, taskW3: action.taskW3! }
        }
        case "setTaskH1": {
            return { ...state, taskH1: action.taskH1! }
        }
        case "setTaskH2": {
            return { ...state, taskH2: action.taskH2! }
        }
        case "setNameofUser": {
            return { ...state, nameOfUser: action.nameOfUser! }
        }
    }
    return state;
};
//@ts-ignore
function AppContextProvider(props) {
    const fullInitialState = {
        ...initialState,
    }

    let [state, dispatch] = useReducer(reducer, fullInitialState);
    let value = { state, dispatch };


    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };