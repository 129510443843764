//// AI-SMS.ts
//// Facilitates connection to backend API
//// API Documentation can be found at https://docs.google.com/document/d/1u1rEMH5QpJNIraIYV367FkwDntjcWhYgMrdyp5Gka7c/view

import { File } from '@awesome-cordova-plugins/file';
import { HTTP, HTTPResponse } from '@awesome-cordova-plugins/http';
import bent from "bent";
import { Point, Polygon } from "geojson";
import qs from 'qs';
import './components/calendar/CalendarTypes';
import { CalendarEvent, Call, ChecklistItem, Meeting } from "./components/calendar/CalendarTypes";
import Checklist from './components/scheduler/ChecklistInterface';
import RWAT, { RWATAnswers } from "./RWAT";
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { FirebaseConfig } from './firebase_config';
import { Network, ConnectionStatus } from '@capacitor/network';
import { Storage } from '@capacitor/storage';
import { UserTask, Prize, Reward, UserLevel, UserScores } from './gamification/GOALS';
import axios from 'axios';
import Restrictions, { toViolation, RestrictionViolations } from './data/restrictions';
import Native from './plugins/NativePlugin';

// Define some useful types
type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'options';
type TaskOperation = 'finish' | 'delete';

// Define base URLs for API
const apiLocalRoot: string = 'http://192.168.1.64:8080/api/nij/ai-sms';
const apiProxyRoot: string = 'http://192.168.1.64:8010/proxy'; // To avoid CORS issues, a proxy is used when testing
// const apiRoot: string = 'http://128.186.151.67:8080/api/nij/ai-sms';
const apiBaseUrl: string = process.env.REACT_APP_NIJAPI_BASEURL ?? 'https://nij-aisms.nicholasdwest.com';
const apiRoot: string = apiBaseUrl + '/api/nij/ai-sms';

// API address for temporary calendar API
const calendarApi: string = 'http://192.168.1.64:1337';
const calendarLogin = {
    identifier: "ethan@strapi.io",
    password: "ethan1234"
};

const api = (method: bent.Options) => { return bent(apiProxyRoot, 'json', method); };

const authMap: { [path: string]: 'nij' | 'firebase' | 'none' } = {

};

// Send a request using native HTTP plugin
async function nativeApi(path: string, method: HttpMethod, data?: { [index: string]: any }, headers?: { [index: string]: any }, baseUrl?: string, storeOnFail?: boolean)
{
    function saveReq(err?: HTTPResponse): Promise<HTTPResponse>
    {
        let auth = authMap.hasOwnProperty(path) ? authMap[path] : 'nij';

        // Strip authorization from stored request
        if(headers)
        {
            if(!headers.hasOwnProperty("Authorization")) auth = 'none';
            headers['Authorization'] = undefined;
        }

        const request: PendingRequest = {
            path: (baseUrl ?? apiRoot) + path,
            method: method,
            data: data ?? {},
            headers: headers ?? {},
            auth: auth
        };

        storeRequest(request);

        return Promise.reject(err ?? {
            status: -1,
            error: `{"message":"Network unavailable"}`
        });
    }

    // If the network is not available, then check if we should store the request and reject
    if(storeOnFail && !NIJAPI.instance.networkStatus.connected)
    {
        console.log(`Network unavailable for request to ${(baseUrl ?? apiRoot) + path}`);

        return saveReq();
    }

    try
    {
        // await HTTP.setServerTrustMode("nocheck");
        const r = HTTP.sendRequest((baseUrl ?? apiRoot) + path, {
            method: method,
            headers: headers ?? {},
            data: data,
            serializer: 'json',
            responseType: 'json',
        });
        // await HTTP.setServerTrustMode("default");

        return r;
    }
    catch (err)
    {
        const errResponse = err as HTTPResponse;

        if(errResponse.status <= 0) // Internal error, should save request
            return saveReq(errResponse);

        return Promise.reject(err);
    }
}

async function storeRequest(request: PendingRequest)
{
    const pendingStr = (await Storage.get({ key: "pendingRequests" })).value;
    let pending: PendingRequest[] = [];

    if(pendingStr)
        try { pending = JSON.parse(pendingStr); } catch (e) { console.info(`Invalid pendingRequests ${pendingStr}`); }

    pending.push(request);

    console.log(pending);

    Storage.set({
        key: "pendingRequests",
        value: JSON.stringify(pending)
    });
}



// API paths
const apiPaths = {
    auth: '/authentication',
    register: '/registration',

    userTrait: '/user/trait',
    userStatus: '/user/status',
    rwat: '/reentry/primary-assessment/rwat',

    jobs: '/job/recommended-jobs',

    location: '/location/gps-data',
    restrictionProfile: '/location/georestriction-profile',

    taskDetail: '/task/', // Must be completed with taskId
    activeTask: '/task/active-task',
    taskList: '/task/task-list',

    schedule: {
        auth: '/auth/local',
        checklist: '/scheduling-checklists',
        checklistCount: '/scheduling-checklists/count',
        logs: '/scheduling-logs',
        logsCount: '/scheduling-logs/count',
        meetings: '/scheduling-meetings',
        meetingsCount: '/scheduling-meetings/count',
        phoneCalls: '/scheduling-phone-calls',
        phoneCallsCount: '/scheduling-phone-calls/count',
        tasks: '/scheduling-tasks',
        tasksCount: '/scheduling-tasks/count'
    },

    scheduling: {
        meetings: '/scheduling/meetings',
        requiredMeetings: '/scheduling/meetings/required'
    },

    fiveKey: {
        session: '/reentry/session',
        sessionList: '/reentry/session/list',
        paList: '/reentry/primary-assessment/list'
    },

    calendar: {
        auth: '/auth/local',
        events: '/calendar-events',
        calls: '/calls',
        checklists: '/checklists',
        meetings: '/meetings'
    },

    gamification: {
        userScore: '/gamification/user-score'
    },

    // Functions to conveniently construct API URLs
    constructed: {
        taskFetch: (taskId: number): string =>      { return apiPaths.taskDetail + taskId; },
        taskDelete: (taskId: number, operation: TaskOperation): string  =>    { return apiPaths.activeTask + '/' + taskId + '?operation=' + operation; },
        pushLocation: (lon: number, lat: number, radius?: number): string => { return apiPaths.location + `/?lon=${lon}&lat=${lat}` + (radius ? `&radius=${radius}` : '') + `&datetime=${new Date().toUTCString()}` },

        scheduleChecklists: (id: string): string => { return `${apiPaths.schedule.checklist}/${id}`; },
        scheduleLogs: (id: string): string => { return `${apiPaths.schedule.logs}/${id}`; },
        scheduleMeetings: (id: string): string => { return `${apiPaths.schedule.meetings}/${id}`; },
        schedulePhoneCalls: (id: string): string => { return `${apiPaths.schedule.phoneCalls}/${id}`; },
        scheduleTasks: (id: string): string => { return `${apiPaths.schedule.tasks}/${id}`; },

        currentScores: (id: string): string => {return `${apiPaths.gamification.userScore}?participantId=${id}`; },
    }
};

export interface TaskInfo
{
    taskId: number,
    taskName: string,
    taskDescription: string,
    rewardType: "hammer" | "gear" | "leaf" | "lightbulb" | "COIN",
    rewardAmount: number
}

export interface Task
{
    id: number,
    userId: number,
    task: TaskInfo,
    progress: number,
    startTime: string | null
}

export interface GPSViolation
{
    category: string,
    id: number,
    locationName: string,
    geom: Polygon | Point
}

export interface SRestrictedLocation extends GPSViolation
{
    address: string,
    comments: string,
    triggerDistance: number,
    userId: number
}

export interface LocationPostResponse
{
    message: string,
    gViolationCount: number,
    gWarningCount: number,
    sViolationCount: number,
    sWarningCount: number,
    radius: number,
    uploadedGps: Point
    generalViolations: GPSViolation[],
    generalWarnings: GPSViolation[],
    specificViolations: SRestrictedLocation[],
    specificWarnings: SRestrictedLocation[]
}

// Modified CalendarEvent interface for pushing new events
export interface NewCalendarEvent extends Omit<CalendarEvent, 'checklist' | 'meetings' | 'calls'>
{
    checklist: string[],
    meetings: string[],
    calls: string[]
}

export interface NIJMeeting
{
    id?: number,
    participantID: string,
    purpose: string,
    receiver: string,
    required: boolean,
    sender: string,
    start_time: string,
    end_time: string,
    title: string,
    type: "in-person" | "phone" | "zoom",
    zoomToken: string,
    approved: boolean,
    location?: string | null
}

interface PendingRequest
{
    path: string,
    method: HttpMethod,
    data: { [index: string]: any },
    headers: { [index: string]: any },
    auth: 'nij' | 'firebase' | 'none'
}

export type FiveKeys = "Meaningful Work Trajectories" | "Positive Relationships" | "Effective Coping Strategies" | "Positive Social Engagement" | "Healthy Thinking Patterns";

export interface SessionStatus
{
    sessionId: string,
    sessionLogId: number,
    sessionTitle: string,
    status: "PENDING" | "FINISHED"
}

export interface SessionLog
{
    key: FiveKeys,
    sessionList: SessionStatus[]
}

export type SessionListResponse = {
    message: string | null,
    sessionLogList: SessionLog[]
}

export type KeysSessions = { [key in FiveKeys]: SessionStatus[] };

export type PrimaryAssessment = {
    actualEndTime: number | null,
    assessmentType: string,
    id: number,
    meetingDuration: string,
    meetingId: number,
    meetingStartTime: number,
    meetingURL: string,
    participantId: number,
    status: string
};

export type PrimaryAssessmentListResponse = {
    assessmentCount: number,
    message: string,
    primaryAssessmentLogList: PrimaryAssessment[]
};

export type SessionUpdateQuery = {
    endTime: number,
    participantId: number,
    response: Record<string, string>,
    sessionLogId: string,
    startTime: number,
    status: "PENDING" | "DONE"
};

export interface SessionResponseLog {
    associatedKey: string,
    endTime: number,
    response: Record<string, string>,
    sessionId: number,
    sessionTitle: string,
    startTime: number,
    status: string
}

export type RestrictionProfile = {
    barRestricted: boolean,
    barTriggerDistance: number,
    id: number,
    playgroundRestricted: boolean,
    playgroundTriggerDistance: number,
    schoolRestricted: boolean,
    schoolTriggerDistance: number,
    userId: number
};

interface NIJAPIHttpOptions {
    path: string,
    useNative?: boolean,
    method?: HttpMethod,
    data?: { [index: string]: any },
    headers?: { [index: string]: any },
    baseUrl?: string,
    storeOnFail?: boolean,
    nijAuth?: boolean
}

interface NIJAPIHttpResponse {
    data: any,
    status: number,
    headers: any
}

export interface UserStatus {
    fivekeyPoint: number,
    healthPoint: number,
    isSafNeeded: boolean,
    maxFivekeyPoint: number,
    maxHealthPoint: number,
    maxMeetingPoint: number,
    maxRestrictionPoint: number,
    meetingPoint: number,
    programEndTime: number,
    programStartTime: number,
    restrictionPoint: number,
    userId: number
}

// AISMS is a singleton class with methods which provide an asynchronous interface to the AI-SMS API.
export default class NIJAPI
{
    private static _instance: NIJAPI;


    // Stores userId as returned by API
    userId: number = -1;

    // User email (just used for location API at the moment)
    userEmail: string = "ethan@gmail.com";

    // @ts-ignore : initFirebase called in constructor assigns this
    private _firebaseApp: FirebaseApp;
    private _firebaseJwt: string = "";
    private _firebaseUser: User | null = null;

    // JWT token necessary for authorization
    private jwt: string = "";

    // Temporary, for transitional backend code
    private scheduleJwt: string = "";
    private calendarJwt: string = "";

    private _lastLocationResponse: LocationPostResponse | undefined = undefined;
    private _knownZones: GPSViolation[] = [];
    private _restrictionProfile: RestrictionProfile = {
        id: -1,
        barRestricted: false,
        barTriggerDistance: 0,
        playgroundRestricted: false,
        playgroundTriggerDistance: 0,
        schoolRestricted: false,
        schoolTriggerDistance: 0,
        userId: -1
    };

    private _networkStatus: ConnectionStatus = { connected: false, connectionType: 'none' };

    private static _eventCounter = 0;
    private events: { [event: string]: { [id: number]: ((data: any) => void) } } = {};

    // Return AISMS instance, or create it if it does not exist.
    static get instance(): NIJAPI
    {
        if(!NIJAPI._instance)
            NIJAPI._instance = new NIJAPI();

        return NIJAPI._instance;
    }

    get networkStatus(): ConnectionStatus
    {
        return this._networkStatus;
    }

    get authenticated(): boolean
    {
        return this.jwt !== "";
    }

    get firebaseReady(): boolean
    {
        return this._firebaseApp && this._firebaseJwt !== "";
    }

    get lastLocationResponse(): LocationPostResponse | undefined
    {
        return this._lastLocationResponse;
    }

    get knownZones(): GPSViolation[]
    {
        return this._knownZones;
    }

    resetKnownZones() {
        this._knownZones = []
    }

    get restrictionProfile(): RestrictionProfile
    {
        return this._restrictionProfile;
    }

    private async authHeader(): Promise<{ Authorization?: string }>
    {
        if(!this._firebaseUser) {
            console.trace('NIJAPI.authHeader accessed before intialization!');
            return { };
        }
        this._firebaseJwt = await this._firebaseUser.getIdToken();
        return { 'Authorization': `Bearer ${this._firebaseJwt}` };
    }

    constructor()
    {
        // Begin listening for network changes & get current network status
        Network.addListener('networkStatusChange', status => {
            console.log(`New connection status: ${status.connected ? "connected" : "disconnected"}, ${status.connectionType}`);
            const prevStatus = this._networkStatus;
            this._networkStatus = status;

            // If status changed from disconnected to connected, try sending pending requests
            if(!prevStatus.connected && status.connected)
                this.tryPendingRequests();
        });
        Network.getStatus()
            .then(status => this._networkStatus = status);

        this.initFirebase();
    }

    on(event: string, callback: (data: any) => void)
    {
        if(!this.events[event]) this.events[event] = {};
        const id = NIJAPI._eventCounter++;
        this.events[event][id] = callback;
        return id;
    }

    cancel(id: number)
    {
        for(const e in this.events) {
            const cbs = this.events[e];
            delete cbs[id];
        }
    }

    private dispatch(event: string, data: any)
    {
        if(!this.events[event]) return;
        Object.values(this.events[event]).forEach(v => v(data));
    }

    // Check
    async tryPendingRequests()
    {
        const pendingStr = (await Storage.get({key: "pendingRequests"})).value;
        if(!pendingStr) return;

        const pending: PendingRequest[] = JSON.parse(pendingStr);
        if(!pending) return;

        // Clear pendingRequests; any requests that fail will simply be added back
        await Storage.set({
            key: "pendingRequests",
            value: "[]"
        });

        pending.forEach(v => {
            console.log(`Trying stored request to ${v.path}`);

            let headers = v.headers;

            switch(v.auth)
            {
                case 'nij':
                    // If we aren't signed in, save the request for later
                    if(!this.authenticated)
                    {
                        console.log(`Request cannot proceed without authentication to NIJ API, saving...`);
                        storeRequest(v);
                        return;
                    }
                    headers['Authorization'] = 'Bearer ' + this.jwt;
                    break;
                case 'firebase':
                    // If we aren't signed in, save the request for later
                    if(this._firebaseJwt === "")
                    {
                        console.log(`Request cannot proceed without authentication to Firebase, saving...`);
                        storeRequest(v);
                        return;
                    }
                    headers['Authorization'] = 'Bearer ' + this._firebaseJwt;
                    break;
                default:
                    break;
            }

            console.log(headers);
            nativeApi(v.path, v.method, v.data, headers, '', false)
                .then(resp => console.log(resp))
                .catch(err => console.error(err));
        });
    }

    initFirebase()
    {
        // Initialize Firebase app & set up auth hook
        this._firebaseApp = initializeApp(FirebaseConfig);
        getAuth(this._firebaseApp).onAuthStateChanged(async (user) => {
            console.log(user);
            if(user)
            {
                this._firebaseUser = user;
                this._firebaseJwt = this.jwt = await user.getIdToken();
                this.userId = parseInt(user.uid);

                // Try to send pending requests in case some were blocked on Firebase authentication
                this.tryPendingRequests();

                this.dispatch('auth', { userId: this.userId });

                // Update known restriction zones
                this.getRestrictionProfile()
                    .then(_ => this.fetchGeneralRestrictionGeo())
                    .then(_ => this.getSpecificRestrictions());
                    this.getSpecificRestrictions()
                // console.info(this._firebaseJwt);
            }
        });
    }

    readLocationData()
    {
        File.checkFile(File.dataDirectory, '.access')
            .then(_ => {
                console.log('Found .access');
                File.readAsText(File.dataDirectory, '.access')
                    .then(contents => {
                        console.log(contents);
                    })
                    .catch(console.error);
            })
            .catch(err => {
                console.error(err);
            })
    }

    // Write JWT and email to file so location service can send data to API
    writeAccessInfo()
    {
        return File.writeFile(File.dataDirectory, '.access', `${this.jwt}\r\n${this.userEmail}\r\n${apiLocalRoot}/`, { replace: true })
            .catch(console.error);
    }

    addRestriction(restriction: GPSViolation)
    {
        // swap lat and lon
        if(restriction.geom.type === 'Point') {
            let k = restriction.geom.coordinates[0];
            restriction.geom.coordinates[0] = restriction.geom.coordinates[1];
            restriction.geom.coordinates[1] = k;
        }

        if(!this._knownZones.find(z => z.id === restriction.id && z.locationName === restriction.locationName)) this._knownZones.push(restriction);
    }

    isRestricted(violation: GPSViolation)
    {
        if(this.restrictionProfile.barRestricted && violation.category.toLowerCase() === "bar") return true;
        if(this.restrictionProfile.schoolRestricted && violation.category.toLowerCase() === "school") return true;
        if(this.restrictionProfile.playgroundRestricted && violation.category.toLowerCase() === "playground") return true;
        return false;
    }

    getRadius(violation: GPSViolation)
    {
        if(violation.category.toLowerCase() === "bar") return this.restrictionProfile.barTriggerDistance;
        if(violation.category.toLowerCase() === "school") return this.restrictionProfile.schoolTriggerDistance;
        if(violation.category.toLowerCase() === "playground") return this.restrictionProfile.playgroundTriggerDistance;
        return (violation as SRestrictedLocation).triggerDistance ?? 0;
    }

    async http({
        path,
        useNative = (process.env.REACT_APP_NIJAPI_NATIVE === '1'),
        method = 'get',
        data = {},
        headers = {},
        baseUrl = apiRoot,
        storeOnFail = false,
        nijAuth = true
    }: NIJAPIHttpOptions)
    {
        return new Promise<NIJAPIHttpResponse>(async (res, rej) => {
            // Construct headers object from supplied headers and Firebase Bearer token
            const h = {...headers, ...(nijAuth ? await this.authHeader() : {})};

            // Handle request storage & rejection when network is unavailable
            if(!this.networkStatus.connected)
            {
                console.log(`Network unavailable for request to ${baseUrl + path}`);

                if(storeOnFail) {
                    // Remove Authorization header
                    let auth = authMap[path] ?? (nijAuth ? 'firebase' : 'none');
                    if(headers['Authorization']) delete headers['Authorization'];

                    storeRequest({
                        path: baseUrl + path,
                        method: method,
                        data: data,
                        headers: headers,
                        auth: auth
                    });
                }

                rej("Network unavailable");

                return;
            }

            // Handle native API call
            if(useNative) {
                nativeApi(path, method, data, h, baseUrl, storeOnFail)
                    .then(resp => {
                        res({
                            data: resp.data,
                            headers: resp.headers,
                            status: resp.status
                        });
                    })
                    .catch(rej);
                return;
            }

            // Handle web API call
            axios({
                method: method,
                url: path,
                baseURL: baseUrl,
                data: data,
                headers: {...h, 'Content-Type': 'application/json'},
                responseType: 'json'
            }).then(resp => {
                res({
                    data: resp.data,
                    headers: resp.headers,
                    status: resp.status
                });
            }).catch(rej);
        })
    }

    // Attempt login to calendar system
    async calendarAuth()
    {
        try
        {
            const response = await nativeApi(apiPaths.calendar.auth, 'post', calendarLogin, undefined, calendarApi);

            // console.log(response);
            this.calendarJwt = response.data['jwt'];
            return Promise.resolve("");
        }
        catch (err)
        {
            if(err.status === 400) return Promise.reject("Username or password is incorrect");
            return Promise.reject("Could not log in.");
        }
    }

    // Send sign in request to Firebase
    async firebaseAuth(email: string, password: string): Promise<{ success: boolean, error?: any }>
    {
        // Initialize Firebase if somehow it was not initialized at this point
        if(!this._firebaseApp) this.initFirebase();

        const auth = getAuth(this._firebaseApp);

        return new Promise((resolve, reject) => {
            setPersistence(auth, browserLocalPersistence).then(() => {
                signInWithEmailAndPassword(auth, email, password)
                    .then(async cred => {
                        // If a sign in is successful, the hook created in initFirebase will set up the token.

                        console.log("Signed in @ Firebase");
                        Native.login({email, password}).then(() => {
                            resolve({ success: true });
                        })
                        
                    })
                    .catch(error => {
                        console.error("Firebase login failed:");
                        console.error(error);

                        reject({ success: false, error: error });
                    });
            });
        });
    }

    firebaseSignOut()
    {
        if(!this._firebaseApp) this.initFirebase();

        return getAuth(this._firebaseApp).signOut()
            .then(() => {
                this._firebaseUser = null;
                this._firebaseJwt = this.jwt = "";
                this.userId = -1;
            });
    }

    // Attempt log in, must successfully use this first in order to access most API.
    // If successful, resolves to an empty string.
    // Otherwise, rejects with the returned error message.
    async authenticate(user: string, pass: string): Promise<string>
    {
        // let query = {
        //     username: user,
        //     password: pass
        // };

        try
        {
            await this.firebaseAuth(user, pass);
            this.tryPendingRequests();

            return Promise.resolve("");
        }
        catch (err)
        {
            console.error(err);
            if(err.status === 401) return Promise.reject("Username or password is incorrect");
            return Promise.reject("Could not log in.");
        }
    }

    // Register new account. Takes an object which is directly passed to the API.
    // This object should take the form of:
    // {
    //      username: ...,
    //      password: ...,
    //      fullName: ...,
    //      gender: ...
    // }
    // If successful and autoLogIn is not false, a login will automatically be triggered next. See NIJAPI.authenticate.
    // If successful and autoLogIn is false, resolves to an empty string.
    // If unsuccessful, rejects with the returned error message.
    // TODO: Remove unused method
    async register(form: object, autoLogIn: boolean = true): Promise<string>
    {
        // const request = api('POST');

        try
        {
            // Send registration request
            // await request(apiPaths.register, form);
            await nativeApi(apiPaths.register, 'post', form);

            if(autoLogIn) // Automatically log in
                return this.authenticate(form['username'], form['password']);

            return Promise.resolve('');
        }
        catch (err)
        {
            return Promise.reject((JSON.parse(err.error)).message);
        }
    }

    // Used to fetch user traits. Must log in first via authenticate method.
    // If successful, resolves to user traits object as defined in the API.
    // Otherwise, rejects with the returned error message.
    async fetchTraits(): Promise<string | bent.Json>
    {
        if(this.jwt === "")
            return Promise.reject("You must log in to access this feature.");

        // const request = api('GET');

        try
        {
            // Send trait fetch request
            const response = await this.http({
                path: apiPaths.userTrait
            });

            console.log("User Trait", response);
            // const response = await request(apiPaths.userTrait, undefined, { 'Authorization': "Bearer " + this.jwt });
            // const response = await nativeApi(apiPaths.userTrait, 'get', {}, { 'Authorization': "Bearer " + this.jwt });

            return Promise.resolve(response.data['userTrait']);

        }
        catch (err)
        {
            return Promise.reject((JSON.parse(err.error)).message);
        }
    }

    // TODO: Remove unused method
    async patchTraits(data : object): Promise<string>
    {
        if(this.jwt === "")
            return Promise.reject("You must log in to access this feature.");

        // const request = api('GET');

        try
        {
            // Send trait fetch request
            // const response = await request(apiPaths.userTrait, undefined, { 'Authorization': "Bearer " + this.jwt });
            const response = await nativeApi(apiPaths.userTrait, 'patch', data, { 'Authorization': "Bearer " + this.jwt });

            console.log("PATCH SUCCESS");
            return Promise.resolve(response.data);

        }
        catch (err)
        {
            return Promise.reject((JSON.parse(err.error)).message);
        }
    }

    // Used to fetch user status. Must log in first via authenticate method.
    // If successful, resolves to user status object as defined in the API.
    // Otherwise, rejects with the returned error message.
    async fetchStatus(): Promise<UserStatus>
    {
        if(this.jwt === "")
            return Promise.reject("You must log in to access this feature.");

        // const request = api('GET');

        try
        {
            // Send status fetch request
            const response = (await this.http({
                path: apiPaths.userStatus
            })).data as UserStatus;

            console.log("User Status", response);
            // const response = await nativeApi(apiPaths.userStatus, 'get', {}, { 'Authorization': "Bearer " + this.jwt });

            return Promise.resolve(response);

        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Used to recommended jobs. Must log in first via authenticate method.
    // If successful, resolves to the recommended jobs object as defined in the API
    // Otherwise, rejects with the returned error message.
    // TODO: Remove unused method
    async fetchJobs(): Promise<string | Array<object>>
    {
        if(this.jwt === "")
            return Promise.reject("You must log in to access this feature.");

        // const request = api('GET');

        try
        {
            // Send job fetch request
            // const response = await request(apiPaths.jobs, undefined, { 'Authorization': "Bearer " + this.jwt });
            const response = await nativeApi(apiPaths.jobs, 'get', {}, { 'Authorization': "Bearer " + this.jwt });

            return Promise.resolve(response.data['jobListings']);

        }
        catch (err)
        {
            return Promise.reject((JSON.parse(err.error)).message);
        }
    }

    // Used to fetch user's active tasks. Must log in first via authenticate method.
    // If successful, returns the activeTasks object as defined in the API.
    // Otherwise, rejects with the returned error message.
    async fetchTaskList(): Promise<UserTask[]>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in to access this feature.");

        // const request = api('GET');

        try
        {
            // Send task list fetch request
            // const response = await nativeApi(apiPaths.taskList + `?userId=${this.userId}`, 'get', {}, { 'Authorization': "Bearer " + this.jwt });
            const response = await this.http({
                path: apiPaths.taskList + `?userId=${this.userId}`,
                nijAuth: true,
                useNative: false
            });

            return Promise.resolve(response.data);

        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Used to fetch details about a particular task. Must log in first via authenticate method.
    // If successful, returns the task object as defined in the API.
    // Otherwise, rejects with the returned error message.
    async fetchTask(taskId: number): Promise<string | any>
    {
        if(this.jwt === "")
            return Promise.reject("You must log in to access this feature.");

        // const request = api('GET');

        try
        {
            // Send task fetch request
            const response = await nativeApi(apiPaths.constructed.taskFetch(taskId), 'get', {}, { 'Authorization': "Bearer " + this.jwt });

            return Promise.resolve(response.data);

        }
        catch (err)
        {
            return Promise.reject((JSON.parse(err.error)).message);
        }
    }

    // Used to delete a task. Must log in first via authenticate method.
    // If successful, returns the deleted task object as defined in the API.
    // Otherwise, rejects with the returned error message.
    async deleteTask(taskId: number, operation: TaskOperation): Promise<string | bent.Json>
    {
        if(this.jwt === "")
            return Promise.reject("You must log in to access this feature.");

        // const request = api('DELETE');

        try
        {
            // Send task deletion request
            const response = await nativeApi(apiPaths.constructed.taskDelete(taskId, operation), 'delete', {}, { 'Authorization': "Bearer " + this.jwt }, undefined, true);

            return Promise.resolve(response.data['deletedTask']);

        }
        catch (err)
        {
            return Promise.reject((JSON.parse(err.error)).message);
        }
    }

    // Used to push location data to the API. Must log in first via authenticate method.
    // Returns a response which includes any violations
    async pushLocationData(latitude: number, longitude: number, radius?: number): Promise<LocationPostResponse>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in to access this feature.");

        // const request = api('POST');

        try
        {
            // Send location information
            const response = await this.http({
                path: apiPaths.constructed.pushLocation(longitude, latitude, radius),
                method: 'post',
                storeOnFail: true
            });
            console.log("Location Push response", response);
            // const response = await nativeApi(apiPaths.constructed.pushLocation(longitude, latitude, radius), 'post', {}, { 'Authorization': "Bearer " + this.jwt }, undefined, true);
            const data: LocationPostResponse = response.data;

            // Save information from this response
            this._lastLocationResponse = data;
            data.generalViolations.forEach(v => {
                if(!this._knownZones.find(z => z.id === v.id)) this._knownZones.push(v);
            });

            return Promise.resolve(data);

        }
        catch (err)
        {
            return Promise.reject((JSON.parse(err.error)).message);
        }
    }

    // Used to push RWAT response data. Must log in first via authenticate method.
    // Automatically pulls encoded answers from RWAT object if answers is not provided.
    async pushRwat(answers?: RWATAnswers): Promise<string>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before submitting an RWAT response.")

        if(!answers)
            answers = RWAT.getInstance().encodeAnswers();

        answers.participantId = this.userId;
        console.log('Submitting RWAT...', answers);

        try
        {
            // Send registration request
            // await request(apiPaths.register, form);
            const response = (await this.http({
                path: apiPaths.rwat,
                method: 'post',
                data: answers,
                storeOnFail: true
            })).data;
            // const response = (await nativeApi(apiPaths.rwat, 'post', answers, { 'Authorization': "Bearer " + this.jwt }, undefined, true)).data;

            console.log('RWAT submission', response);

            return Promise.resolve(response.message);
        }
        catch (err)
        {
            console.error(err);
            return Promise.reject(err);
        }
    }

    // Get events from calendar API
    // Optionally accepts an input for qs.stringify
    // TODO: Remove unused method
    async getEvents(params?: any, qsOptions?: qs.IStringifyOptions)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            // Send GET request
            const response = await nativeApi(apiPaths.calendar.events + (params ? "?" + qs.stringify(params, qsOptions) : ""), 'get', {}, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            let events = response.data as CalendarEvent[];
            for (const event of events) {
                event.date = new Date(event.timestamp);
            }

            return Promise.resolve(events);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Generate a request to fetch events in a specific range
    getEventRange(start: Date, end: Date)
    {
        const query = {
            _where: [
                {timestamp_gte: start.toISOString()},
                {timestamp_lte: end.toISOString()}
            ],
            _sort: 'timestamp:ASC'
        };

        return this.getEvents(query);
    }

    // Updates the chosen checklist
    // TODO: Remove unused method
    async updateChecklist(id: string, value: boolean)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(id, value);
            // Send PUT request
            const response = await nativeApi(apiPaths.calendar.checklists + '/' + id, 'put', { completed: value }, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Deletes the chosen checklist
    // TODO: Remove unused method
    async deleteChecklist(id: string)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            // Send DELETE request
            const response = await nativeApi(apiPaths.calendar.checklists + '/' + id, 'delete', {}, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Post a checklist item to the calendar backend
    // TODO: Remove unused method
    async pushChecklist(item: ChecklistItem)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(item);
            // Send POST request
            const response = await nativeApi(apiPaths.calendar.checklists, 'post', item, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Push a list of checklist items
    // TODO: Remove unused method
    async pushChecklists(checklist: ChecklistItem[])
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(checklist);
            // Send all POST requests and wait for responses
            const responses = await Promise.all(checklist.map(async (v) => {
                return nativeApi(apiPaths.calendar.checklists, 'post', v, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);
            }));
            console.log(responses);

            return Promise.resolve(responses);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Post a meeting item to the calendar backend
    // TODO: Remove unused method
    async pushMeeting(item: Meeting)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(item);
            // Send POST request
            const response = await nativeApi(apiPaths.calendar.meetings, 'post', item, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Deletes the chosen meeting
    // TODO: Remove unused method
    async deleteMeeting(id: string)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            // Send DELETE request
            const response = await nativeApi(apiPaths.calendar.meetings + '/' + id, 'delete', {}, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Push a list of meeting items
    // TODO: Remove unused method
    async pushMeetings(meetings: Meeting[])
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(meetings);
            // Send all POST requests and wait for responses
            const responses = await Promise.all(meetings.map(async (v) => {
                return nativeApi(apiPaths.calendar.meetings, 'post', v, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);
            }));
            console.log(responses);

            return Promise.resolve(responses);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Post a meeting item to the calendar backend
    // TODO: Remove unused method
    async pushCall(item: Call)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(item);
            // Send POST request
            const response = await nativeApi(apiPaths.calendar.calls, 'post', item, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Deletes the chosen call
    // TODO: Remove unused method
    async deleteCall(id: string)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            // Send DELETE request
            const response = await nativeApi(apiPaths.calendar.calls + '/' + id, 'delete', {}, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Push a list of checklist items
    // TODO: Remove unused method
    async pushCalls(calls: Call[])
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(calls);
            // Send all POST requests and wait for responses
            const responses = await Promise.all(calls.map(async (v) => {
                return nativeApi(apiPaths.calendar.calls, 'post', v, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);
            }));
            console.log(responses);

            return Promise.resolve(responses);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Post an event to the calendar backend
    // This expects checklist, meetings, and calls to be given as a list of existing IDs
    // TODO: Remove unused method
    async pushEvent(item: NewCalendarEvent)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            console.log(item);
            // Send POST request
            const response = await nativeApi(apiPaths.calendar.events, 'post', item, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Post an event to the calendar backend
    // This will handle uploading checklists, meetings, and calls given as objects to the backend
    // TODO: Remove unused method
    async pushFullEvent(item: CalendarEvent)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            let newItem = { ...item, checklist: [], meetings: [], calls: [] } as NewCalendarEvent;
            console.log(item);

            if(item.checklist.length > 0)
            {
                console.info("Pushing checklists...");
                const realChecklist = await this.pushChecklists(item.checklist);
                newItem.checklist = realChecklist.map(v => {
                    const d = v.data as ChecklistItem;
                    return d.id;
                });
            }
            if(item.meetings.length > 0)
            {
                console.info("Pushing meetings...");
                const realMeetings = await this.pushMeetings(item.meetings);
                newItem.meetings = realMeetings.map(v => {
                    const d = v.data as Meeting;
                    return d.id;
                });
            }
            if(item.calls.length > 0)
            {
                console.info("Pushing calls...");
                const realCalls = await this.pushCalls(item.calls);
                newItem.calls = realCalls.map(v => {
                    const d = v.data as Call;
                    return d.id;
                });
            }

            const response = this.pushEvent(newItem);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    // Deletes the chosen event
    // TODO: Remove unused method
    async deleteEvent(id: string)
    {
        if(this.calendarJwt === "")
            return Promise.reject("You must log in to access this feature.");

        try
        {
            // Send DELETE request
            const response = await nativeApi(apiPaths.calendar.events + '/' + id, 'delete', {}, { 'Authorization': "Bearer " + this.calendarJwt }, calendarApi);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async fetchSchedule(id?: string, required?: boolean, startTime?: string, endTime?: string): Promise<NIJMeeting[]>
    {
        if(!this.firebaseReady)
            return Promise.reject("You must log in to access your schedule.");

        try
        {
            // Build query string
            const urlParams = new URLSearchParams();

            urlParams.set("participantID", id ?? this.userId.toString());
            urlParams.set("starttime", startTime ?? "2000-06-23T16:49:02.863Z");
            urlParams.set("endtime", endTime ?? "3000-06-23T16:49:02.863Z");

            const url = (required ? apiPaths.scheduling.requiredMeetings : apiPaths.scheduling.meetings) + "?" + urlParams.toString();
            console.log(url);

            // Send GET request for schedule
            const response: NIJMeeting[] = (await this.http({
                path: url,
                baseUrl: apiBaseUrl
            })).data;
            // const response = await nativeApi(url, 'get', {}, { 'Authorization': "Bearer " + this._firebaseJwt }, apiBaseUrl);

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async fetchAllMeetings() : Promise<NIJMeeting[]> {
        if(!this.firebaseReady)
            return Promise.reject("You must log in to access your schedule.");

        try 
        {
            const response = await this.http({
                path: apiPaths.scheduling.meetings + `?participantId=${this.userId}`, 
                nijAuth: true,
                useNative: false
            });

            return Promise.resolve(response.data); 
        }
        catch (err) 
        {
            return Promise.reject(err);
        }
    }

    // Convert a meeting as returned by the NIJ API to a CalendarEvent
    // TODO: Make this obselete by using the NIJMeeting schema directly
    static adaptNIJMeeting(v: NIJMeeting): CalendarEvent
    {
        return {
            id: v.id?.toString() ?? "",
            userid: v.participantID,
            name: v.title,
            description: v.purpose,
            timestamp: v.start_time,
            date: new Date(v.start_time),
            approval: "approved",
            caseWorker: v.receiver === "participant" ? v.sender : v.receiver,
            type: v.type,
            location: v.type,
            checklist: [],
            meetings: [],
            calls: []
        };
    }

    async createMeeting(meeting: NIJMeeting)
    {
        if(!this.firebaseReady)
            return Promise.reject("You must log in to access your schedule.");

        try
        {
            const response: NIJMeeting = (await this.http({
                path: apiPaths.scheduling.meetings,
                method: 'post',
                data: meeting,
                baseUrl: apiBaseUrl,
                storeOnFail: true
            })).data;
            // const response = await nativeApi(apiPaths.scheduling.meetings, 'post', meeting, { 'Authorization': "Bearer " + this._firebaseJwt }, apiBaseUrl, true);
            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async getSessionList(): Promise<SessionListResponse>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in to access sessions.");

        try
        {
            const response: SessionListResponse = (await this.http({
                path: apiPaths.fiveKey.sessionList + `?participantId=${this.userId}`
            })).data;
            // const response = (await nativeApi(apiPaths.fiveKey.sessionList + `?participantId=${this.userId}`, 'get', {}, { 'Authorization': "Bearer " + this.jwt })).data;
            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async getPrimaryAssessmentList(): Promise<PrimaryAssessmentListResponse>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in to access primary assessments.");

        try
        {
            const response: PrimaryAssessmentListResponse = (await this.http({
                path: apiPaths.fiveKey.paList + `?participantId=${this.userId}`
            })).data;
            // const response = (await nativeApi(apiPaths.fiveKey.paList + `?participantId=${this.userId}`, 'get', {}, { 'Authorization': `Bearer ${this.jwt}`})).data;
            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async updateSession(update: SessionUpdateQuery): Promise<{ message: string }>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in to submit session responses.");

        try
        {
            const response = (await this.http({
                path: apiPaths.fiveKey.session,
                method: 'put',
                data: update
            })).data;

            // const response = (await nativeApi(apiPaths.fiveKey.session, 'put', update, {'Authorization': `Bearer ${this.jwt}`})).data;
            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async fetchSessionLog(logId: number)
    {
        if(!this.authenticated)
            return Promise.reject("You must log in to fetch session logs.");

        try
        {
            const response: SessionResponseLog = (await this.http({
                path: apiPaths.fiveKey.session + `?sessionLogId=${logId}`,
                method: 'get'
            })).data;

            return Promise.resolve(response);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async fetchPrizes(): Promise<Prize[]>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before accessing rewards.");

        try
        {
            const prizes: Prize[] = (await this.http({
                path: `/rewards?participantId=${this.userId}`,
                baseUrl: apiBaseUrl
            })).data;

            // const prizes: Prize[] = (await nativeApi(`/rewards?participantId=${this.userId}`, 'get', {}, {'Authorization': `Bearer ${this.jwt}`}, apiBaseUrl)).data;
            // console.debug(prizes);
            return Promise.resolve(prizes);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async getRestrictionProfile(buildRestrictions: boolean = false): Promise<RestrictionProfile>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before accessing geolocation.");

        try
        {
            const resp: RestrictionProfile = (await this.http({
                path: apiPaths.restrictionProfile + `?userId=${this.userId}`
            })).data;
            // const resp: RestrictionProfile = (await nativeApi(apiPaths.restrictionProfile + `?userId=${this.userId}`, 'get', {}, {'Authorization': `Bearer ${this.jwt}`})).data;

            console.log('restriction profile', resp);
            if(resp.userId === this.userId)
            {
                this._restrictionProfile = resp;
                if(buildRestrictions)
                {
                    RestrictionViolations
                        .filter(e => this.isRestricted(e))
                        .forEach(e => this.addRestriction(e));
                }
            }

            return Promise.resolve(resp);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async getSpecificRestrictions(): Promise<SRestrictedLocation[]>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before accessing geolocation.");

        try
        {
            const resp: SRestrictedLocation[] = (await this.http({
                path: `/location/specific-restriction?userId=${this.userId}`,
            })).data;
            // const resp: SRestrictedLocation[] = (await nativeApi(`/location/specific-restriction?userId=${this.userId}`, 'get', {}, {'Authorization': `Bearer ${this.jwt}`})).data;

            resp.forEach((e) => {


                this.addRestriction(e);
            });
            console.log(resp, this._knownZones);

            return Promise.resolve(resp);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async claimTask(id: number): Promise<{ message: string }>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before claiming tasks.");

        try
        {
            const resp: { message: string } = (await this.http({
                path: `/task?id=${id}`,
                method: 'patch'
            })).data;

            return Promise.resolve(resp);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async fetchGeneralRestrictionGeo(addRestrictions: boolean = true): Promise<GPSViolation[]>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before fetching restricted areas");

        try
        {
            const resp: GPSViolation[] = (await this.http({
                path: `/location/general-restricted-user?id=${this.userId}`
            })).data;
            // console.log("General Restrictions", resp);

            if(addRestrictions)
            {
                // API should only give us correct locations, no need to implement filtering on our end
                resp.forEach(l => this.addRestriction(l));
            }

            return Promise.resolve(resp);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async fetchRewards(): Promise<Reward[]>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before accessing rewards.");

        try
        {
            const reward: Reward[] = (await this.http({
                path: `/gamification/rewards?participantId=${this.userId}`,
                baseUrl: apiBaseUrl
            })).data;

            return Promise.resolve(reward);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async fetchUserLevel(): Promise<UserLevel>
    {
        if(!this.authenticated)
            return Promise.reject("You must log in before accessing rewards.");

        try
        {
            const userLevel: UserLevel = (await this.http({
                path: `/gamification/user-level?participantId=${this.userId}`,
                baseUrl: apiBaseUrl
            })).data;

            return Promise.resolve(userLevel);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }

    async getUserScores(): Promise<UserScores>
    {

        if(!this.authenticated)
            return Promise.reject("You must log in before accessing rewards.");

        try
        {

            const userScores: UserScores = (await this.http({
                path: apiPaths.constructed.currentScores(String(this.userId)),
                baseUrl: apiBaseUrl
            })).data;

            return Promise.resolve(userScores);
        }
        catch (err)
        {
            return Promise.reject(err);
        }
    }
}
