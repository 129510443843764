import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonItem, IonLabel, IonNote, IonPage, IonProgressBar, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect } from 'react';
import Native from '../../plugins/NativePlugin';

import "./FiveKeysMenu.css";

const FiveKeysMenu: React.FC = () => {


    useEffect(() => {
        Native.clearSessionTimeout()
    })

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        5-Key Model for Reentry
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="five-keys-content">
                <IonCard routerLink="/fivekeys/primary">
                    <IonCardHeader>
                        <IonCardTitle>
                            Primary Assessments
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <div>
                            Primary assessments include RWAT (Reentry Wellbeing Assessment Tool), Biopsychosocial Assessment, and Cultural Ecogram. In order to start working along different keys, please complete the primary assessments.
                        </div>
                        <br />
                        {/*-- Default Progressbar with 50 percent --*/}
                        <IonProgressBar value={0.5}></IonProgressBar>

                    </IonCardContent>
                </IonCard>

                <IonCard routerLink="/fivekeys/case">
                    <IonCardHeader>
                        <IonCardTitle>
                            Case Conceptualization
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <div>Case Conceptualization includes assigned sessions by the program specialist after discussing with you. These are the interventions that help you improve along different keys. If you have already completed primary assessments, you should be able to see the list of assigned interventions along different keys.</div>
                        <IonItem>
                            <IonLabel><b>Session Name</b></IonLabel>
                            <IonNote color='warning' slot='end'>Pending</IonNote>
                        </IonItem>
                        <IonItem>
                            <IonLabel><b>Session Name</b></IonLabel>
                            <IonNote color='warning' slot='end'>Pending</IonNote>
                        </IonItem>
                        <IonItem>
                            <IonLabel><b>Session Name</b></IonLabel>
                            <IonNote color='warning' slot='end'>Pending</IonNote>
                        </IonItem>
                        <IonItem>
                            <IonLabel><b>Session Name</b></IonLabel>
                            <IonNote color='warning' slot='end'>Pending</IonNote>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    )
};

export default FiveKeysMenu;