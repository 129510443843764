import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonItem, IonRow, IonText } from "@ionic/react";
import React, { useState } from "react";
import { shortenMonth, getWeeday, statusColor, adjustTime } from "../../utilities";
import SchedulingLog from "./SchedulingLogInterface";
import './ScheduleLog.css'

const ScheduleLog: React.FC<{data:SchedulingLog}> = ({data}) =>{

    const [checklistCount,setChecklistCount] = useState<number>(0)
  
  
    return(
      <IonCard routerLink={`/tab3/${data.date}`} class="schedule-log">
            <IonCardHeader>
              <IonCardTitle>
                <IonGrid class="ion-no-padding">
                    <IonRow>
                      <IonCol  size="8">
                        <IonItem class="ion-no-padding" lines="none">
                          <h1>{data.day} </h1>
                          <h1 className="month-text">{shortenMonth(data.month)}</h1>
                          <p>{data.year}, {getWeeday(data.date)}</p>
                        </IonItem>
                      </IonCol>
                      <IonCol size="4">
                        <IonItem className="status-box" class="ion-text-center" color={statusColor(data.status)}>
                          <IonText>
                          {data.status}
                          </IonText>
                        </IonItem>
                        
                      </IonCol>
                    </IonRow>
                </IonGrid>
                </IonCardTitle>
              <IonCardSubtitle>02:55pm</IonCardSubtitle>
  
            </IonCardHeader>
  
            <IonCardContent>
              {data.description}
        </IonCardContent>
  
       <IonGrid>
          <IonRow>
            <IonCol size="5">
              <IonItem lines="none">
                  <IonText>0/{data.checklistCount} Completed</IonText>
              </IonItem>
            </IonCol>
            <IonCol size="4">
              <IonItem className="due-time-box" color="primary">
                <IonText>{adjustTime(data.dueTime)}</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
         </IonGrid>
          </IonCard>
    )
    
  }

  export default ScheduleLog;