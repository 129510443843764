import { IonSlides } from '@ionic/react';
import React, { Component } from 'react';
import { GameContext } from '../../gamification/gamification';
import { Reward } from '../../gamification/GOALS';
import { PrizeParams } from "../../sampleObjects/samplePrizes";
import './RewardTrack.css';
import RewardTrackItem from './RewardTrackItem';



interface RewardTrackProps
{
    items: Reward[],
}


export default class RewardTrack extends Component<RewardTrackProps>
{
    static contextType = GameContext;

    renderItem(item: Reward, index: number)
    {
        return(
            <RewardTrackItem
                id={item.id}
                text={item.description}
                earned={item.status === 'claimed'}
            />
        );
    }

    render()
    {
        return (
            <div id="rewardTrack"
            >

            {
                this.props.items.map((item, index) => {
                    console.log(index)
                    return (
                        this.renderItem(item, index)
                    );
                })
            }

            </div>
        );
    }
}
