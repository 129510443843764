export interface Section {
    id: string,
    name?: string,
    type: 'info' | 'radio' | 'socialnetwork' | 'text' | 'time',
    prompt?: string,
    questions: string[],
    labels: string[],
    values?: any[],
    [ key: string ]: any
}

// References ids set in the RWAT below
const RWATPages: string[][] = [
    ['cogFlexInven'],
    ['empathy'],
    ['hope'],
    ['coping'],
    ['socialNetworkIntro', 'family', 'community', 'work', 'friends'],
    ['closestPerson_1', 'closestPerson_2'],
    ['mostTimePerson_0','mostTimePerson_1','mostTimePerson_2'],
    ['worth'],
    ['timeUsage'],
    ['comAndLeisure'],
    ['workTraj']
];

const RWAT2020: Section[] = [
    {
        id: 'cogFlexInven',
        name: 'Cognitive Flexibility Inventory',
        prompt: 'When answering these first set of questions, think about how you have been feeling over the past two weeks in your personal life.',
        type: 'radio',
        labels: ['Strongly\nDisagree', '', '', 'Neutral', '', '', 'Strongly\nAgree'],
        values: [1, 2, 3, 4, 5, 6, 7],
        questions: [
            `I have been good at “sizing up” situations.`,
            `I have had a hard time making decisions when faced with difficult situations.`,
            `I have been considering multiple options before making a decision.`,
            `When I have been encountering difficult situations, I felt like I was losing control.`,
            `I have been looking at difficult situations from many different angles.`,
            `I have been seeking additional information not immediately available before attributing causes to behavior.`,
            `When I have been encountering difficult situations, I become so stressed that I cannot think of a way to resolve the situation.`,
            `I have been trying to think about things from another person’s point of view.`,
            `I have been finding it troublesome that there are so many different ways to deal with difficult situations.`,
            `I have been good at putting myself in others’ shoes.`,
            `When I have been encountering difficult situations, I just do not know what to do.`,
            `I have been finding it important to look at difficult situations from many angles.`,
            `When I have been in difficult situations, I have considered multiple options before deciding how to behave.`,
            `I have been looking at difficult situations from different viewpoints.`,
            `I have been capable of overcoming the difficulties in life that I have faced.`,
            `I have been considering all the available facts and information when attributing causes to behavior.`,
            `I have been feeling I have no power to change things in difficult situations.`,
            `When I have been encountering difficult situations, I have stopped and tried to think of several ways to resolve it.`,
            `I have been able to think of more than one way to resolve a difficult situation I have been confronted with.`,
            `I have been considering multiple options before responding to difficult situations.`
        ]
    },
    {
        id: 'empathy',
        name: 'Empathy',
        prompt: 'Thinking about the past two weeks, how often have you felt the following ways...',
        type: 'radio',
        labels: ['Never', 'Rarely', 'Some-\ntimes', 'Often', 'Always'],
        values: [0, 1, 2, 3, 4],
        questions: [
            `When someone else is feeling excited, I tend to get excited too`,
            `Other people’s misfortunes disturb me a great deal`,
            `It upsets me to see someone being treated disrespectfully`,
            `It affects me when someone close to me is happy`,
            `I enjoy making other people feel better`,
            `I have tender, concerned feelings for people less fortunate than me`,
            `When a friend starts to talk about his/her problems, I try to steer the conversation towards something else`,
            `I can tell when others are sad even when they do not say anything`,
            `I find that I am “in tune” with other people’s moods`,
            `I feel sympathy for people who cause their own serious illnesses`,
            `I become irritated when someone cries`,
            `I am really interested in how other people feel`,
            `I get a strong urge to help when I see someone who is upset`,
            `When I see someone being treated unfairly, I feel much pity for them`,
            `I find it silly for people to cry out of happiness`,
            `When I see someone being taken advantage of, I feel kind of protective towards him/her`
        ]
    },
    {
        id: 'hope',
        name: 'Hope',
        prompt: 'Over the last two weeks, how much would you say you disagree or agree with the following statements...',
        type: 'radio',
        labels: ['Strongly\nDisagree', 'Disagree', 'Agree', 'Strongly\nAgree'],
        values: [1, 2, 3, 4],
        questions: [
            `I have a positive outlook toward life.`,
            `I have goals.`,
            `I feel all alone.`,
            `I can see possibilities in the midst of difficulties.`,
            `I have a faith that gives me comfort.`,
            `I feel scared about my future.`,
            `I can remember happy/joyful times.`,
            `I have deep inner strength.`,
            `I am able to give and receive caring/love.`,
            `I know where I am going in my life.`,
            `I believe that each day has potential.`,
            `I feel my life has value and worth.`
        ]
    },
    {
        id: 'coping',
        name: 'Effective Coping Strategies',
        prompt: 'When answering these next set of questions, think about a stressful situation that you have encountered in the past two weeks.',
        type: 'radio',
        labels: ['Never', 'Rarely', 'Often', 'Always'],
        values: [1, 2, 3, 4],
        questions: [
            `I turn to work or other activities to take my mind off things.`,
            `I concentrate my efforts on doing something to change the situation I am in.`,
            `I say to myself "this is not real".`,
            `I use alcohol or other drugs to make myself feel better.`,
            `I get emotional support from others.`,
            `I give up trying to deal with the situation I am in.`,
            `I take action to try to make my situation better.`,
            `I refuse to believe that I am in my current situation.`,
            `I vent to others about my situation.`,
            `I get help and advice from other people.`,
            `I use alcohol or other drugs to help me deal with the situation.`,
            `I try to see my situation in a positive light.`,
            `I criticize myself.`,
            `I try to come up with ideas about what to do.`,
            `I get comfort and understanding from someone.`,
            `I stop trying to cope.`,
            `I look for something good in what is happening.`,
            `I make jokes about stressful situations.`,
            `I do something to think about the stressful situation less, such as going to movies, watching TV, reading, daydreaming, sleeping, or shopping.`,
            `I accept the reality of the situation I am in.`,
            `I express my negative feelings.`,
            `I find comfort in my religion or spiritual beliefs`,
            `I get advice or help from other people about what to do.`,
            `I learn to live with my situation.`,
            `I think hard about what steps to take.`,
            `I blame myself for things that happened.`,
            `I pray or meditate.`,
            `I make fun of the situation.`
        ]
    },
    {
        id: 'socialNetworkIntro',
        name: 'Positive Relationships',
        prompt: 'Now you will be asked some questions about how your relationships with others have been going over the past two weeks.' +
                `First, let's get an idea of who has been in your social network in the past couple of weeks. In the form below, provide the initials of people who fall into each category. Put the people you feel closest to first, and other people last. You will also be asked if those people have been a positive, neutral, or negative influence on you in the past couple of weeks.`,
        type: 'radio',
        labels: [],
        values: [],
        questions: []
    },
    {
        id: 'family',
        name: 'Family',
        prompt: 'Add the initials of family members who have been in your social network in the past couple of weeks and order them with those you feel closest to first, and others last. Also mark whether those people have been positive, neutral, or negative influences.',
        type: 'socialnetwork',
        category: 'family',
        labels: [],
        values: [],
        questions: []
    },
    {
        id: 'community',
        name: 'Community',
        prompt: '(e.g. neighborhood, church support)',
        type: 'socialnetwork',
        category: 'community',
        labels: [],
        values: [],
        questions: []
    },
    {
        id: 'work',
        name: 'Work',
        prompt: '',
        type: 'socialnetwork',
        category: 'work',
        labels: [],
        values: [],
        questions: []
    },
    {
        id: 'friends',
        name: 'Friends/acquaintances',
        prompt: '',
        type: 'socialnetwork',
        category: 'friends',
        labels: [],
        values: [],
        questions: []
    },
    {
        id: 'closestPerson_1',
        name: 'Quality of Relationships',
        prompt: 'Now you will be asked about your relationships with others. Who do you feel like you have been closest to in the past couple of weeks?',
        type: 'text',
        labels: ['First Name', 'Relationship'],
        values: [],
        questions: [
            "What is that person's first name?",
            "What is your relationship to that person? (e.g. mom, partner, child)",
        ]
    },
    {
        id: 'closestPerson_2',
        type: 'radio',
        labels: ['Not at\nall', 'A little', 'Quite\na bit', 'Very\nMuch'],
        values: [1, 2, 3, 4],
        questions: [
            `How positive a role does this person play in your life?`,
            `How significant is this relationship in your life?`,
            `How close do you expect your relationship to be with this person in 10 years?`,
            `How much would you miss this person if the two of you could not see or talk with each other for a month?`,
            `How responsible do you feel for this person's well-being?`,
            `How much do you depend on this person?`
        ]
    },
    {
        id: 'mostTimePerson_0',
        prompt: 'Now you will be asked similar questions about who you have spent the most time with the past couple of weeks.',
        type: 'radio',
        labels: ['Same Person', 'Different Person'],
        values: [1, 0],
        questions: [
            'Is the person you have spent the most time with the same as the previous section? If so, you may skip this section.'
        ]
    },
    {
        id: 'mostTimePerson_1',
        type: 'text',
        labels: ['First Name', 'Relationship'],
        values: [],
        questions: [
            "What is that person's first name?",
            "What is your relationship to that person? (e.g. mom, partner, child)",
        ]
    },
    {
        id: 'mostTimePerson_2',
        type: 'radio',
        labels: ['Not at\nall', 'A little', 'Quite\na bit', 'Very\nMuch'],
        values: [1, 2, 3, 4],
        questions: [
            `How positive a role does this person play in your life?`,
            `How significant is this relationship in your life?`,
            `How close do you expect your relationship to be with this person in 10 years?`,
            `How much would you miss this person if the two of you could not see or talk with each other for a month?`,
            `How responsible do you feel for this person's well-being?`,
            `How much do you depend on this person? `
        ]
    },
    {
        id: 'worth',
        name: 'Perceptions of Worth',
        prompt: 'Over the past couple of weeks, to what extent do you agree with the following statements...',
        type: 'radio',
        labels: ['Strongly\nDisagree', 'Disagree', 'Agree', 'Strongly\nAgree'],
        values: [1, 2, 3, 4], 
        questions: [
            `Other people do not view me as capable.`,
            `I do not think other people respect my skills and abilities.`,
            `I have relationships where my competence and skills are recognized.`,
            `There are people who admire my talents and abilities.`
        ]
    },
    {
        id: 'timeUsage',
        name: 'Positive Social Engagement',
        prompt: 'Just a few more questions to go! Now you will be asked about how you have been spending your time in the past two weeks. Give your best guess at approximately how many hours you spend doing the following on a typical day...',
        type: 'time',
        labels: [
            "(time in bed)",
            "(time spent studying and in class)",
            "(time spent at work)",
            "(time spent cooking, cleaning, buying food, etc.)",
            "(time with groups, clubs, volunteering, etc.)",
            "(time with sports, TV, computer, music, hobbies, etc.)",
            "(time spent getting to and from work or school)",
            "(time with friends, partner, or family)",
            "(time spent exercising, eating, washing, etc.)",
            "(time spent in meditation or spiritual/religious duties)"
        ],
        questions: [
            "Sleeping",
            "School",
            "Paid work",
            "Household chores",
            "Community Activities",
            "Recreational activities",
            "Commuting",
            "Personal relationships",
            "Health and self-maintenance",
            "Spiritual or religious activities"
        ]
    },
    {
        id: 'comAndLeisure',
        name: 'Community and Leisure',
        prompt: 'Over the past two months, give me a sense of how many times you have been doing the following.',
        type: 'radio',
        labels: ['Never', 'Less\nthan\nMonthly', 'Every\nMonth', 'Every\nWeek', 'Every\nDay'],
        values: [0, 1, 2, 3, 4],
        pairQuestions: true,
        pairLabels: ['As part of\na program', 'On your\nown', 'With friends/\nloved ones'],
        pairValues: [1, 2, 3],
        questions: [
            `How often do you go out to eat?`,
            `Who do you typically do this with?`,
            `How often do you hang out with friends or loved ones at the house?`,
            `Who do you typically do this with?`,
            `How often do you go to night clubs, dance clubs, or bars?`,
            `Who do you typically do this with?`,
            `How often do you attend an event as a member of a club or association other than church? (e.g., fraternity, sorority, the Y, sports team affiliation, reading club)`,
            `Who do you typically do this with?`,
            `How often do you go to house parties?`,
            `Who do you typically do this with?`,
            `How often do you go to a work party or party of organization you volunteer with?`,
            `Who do you typically do this with?`,
            `How often do you go to fundraisers or charity events?`,
            `Who do you typically do this with?`,
            `How often do you go to church, synagogue, temple or other religious events?`,
            `Who do you typically do this with?`,
            `How often do you ride around in the car for fun?`,
            `Who do you typically do this with?`
        ]
    },
    {
        id: 'workTraj',
        name: 'Education and Employment',
        prompt: 'Ok – now we are on the last set of questions. I want to ask you some things about education and employment. Over the past two weeks, tell me how much you agree or disagree with the following statements.',
        type: 'radio',
        labels: ['Strongly\nDisagree', 'Disagree', 'Agree', 'Strongly\nAgree'],
        values: [1, 2, 3, 4], 
        questions: [
            `I am interested in attending more school.`,
            `I am willing to work hard to get more education.`,
            `One of my more important goals is to get more education.`,
            `I would put effort into a school or training program if it would lead to a good job.`,
            `I am happy with the amount of education I have completed.`,
            `I am satisfied with my educational accomplishments.`,
            `I am proud of my education.`,
            `I will work hard to improve my work situation.`,
            `I am willing to put in effort to have a job I enjoy.`,
            `Having a good job is important to my sense of wellbeing.`,
            `I have work which is satisfying to me.`,
            `I feel as though my work is a good fit for my skills and abilities.`,
            `I do work that is interesting to me.`
        ]
    },
];

export const getRWAT = () => RWAT2020;
export const getRWATSection = (id: string) => RWAT2020.find(s => s.id === id);

export const getPages = () => RWATPages;
export const getPagesObj = () => getPages().map(page => {
    return page.map(id => getRWATSection(id));
});