import {
    IonCol, IonGrid,
    IonItem, IonList, IonRadio,
    IonRadioGroup,
    IonRow
} from '@ionic/react';
import React from 'react';
import '../../rtif';
import './SectionRadio.css';
import './SurveySection';
import SurveySection, { SurveySectionProps } from './SurveySection';

interface SectionRadioProps extends SurveySectionProps {
    pairQuestions?: boolean,
    pairLabels?: string[],
    pairValues?: number[]
}

export default class SectionRadio extends SurveySection<SectionRadioProps> {
    numRendered = 0;

    constructor(props: SectionRadioProps)
    {
        super(props);
    }

    encodeAnswers()
    {
        return this.getQuestionAnswers().map((v,i,a) => {
            // Handle paired questions
            if(this.props.pairQuestions && (i % 2) === 1 && (a[i - (i % 2)] === 0 || v === undefined))
                return 0;
            return v;
        }).join('');
    }

    validate(): string
    {
        let undefs = this.getQuestionAnswers().map((v,i,a) => {
            if(v != undefined) return -1;

            if(this.props.pairQuestions && (i % 2) === 1 && a[i - (i % 2)] === 0) return -1;

            return i;
        }).filter(v => v !== -1);

        // console.log(undefs, undefs.length);

        if(!this.props.disabled && undefs.length > 0)
        {
            this.validated = false;
            return "All questions must be answered.";
        }
        
        this.validated = true;
        return '';
    }

    // componentDidUpdate()
    // {
    //     // @ts-ignore
    //     document?.getElementById("rwat")?.getSwiper()
    //       .then(v => { v.update(); console.log(v) });
    // }

    render()
    {
        let { values, labels, pairQuestions, pairLabels, pairValues } = this.props;
        let { answers } = this.state;
        let rendered = 0;

        if(values === undefined || values === null)
            values = Array.from(new Array(this.props.labels.length), (x, i) => i + 1);

        if(pairQuestions && (pairValues === undefined || pairValues === null) && pairLabels)
            pairValues = Array.from(new Array(pairLabels.length), (x, i) => i + 1);

        console.log(this.props.id, this.encodeAnswers())

        let r = (
            <div>
                {/* Header */}
                { this.getHeader() }

                {/* Questions */}
                <IonList>
                    {
                        this.props.questions.map((question, index) => {
                            let useLabels = labels, useValues = values;

                            if(pairQuestions && (index % 2) === 1)
                            {
                                if(!answers[index - (index % 2)])
                                    return null;

                                useLabels = pairLabels as string[];
                                useValues = pairValues;
                            }

                            rendered++;

                            return (
                                <IonItem key={`${this.props.id}_${index}`} class="radio" style={{"--ion-grid-columns": useLabels.length * 2}}>
                                    <IonRadioGroup value={answers[index]} onIonChange={v => {this.setQuestionAnswer(index, v.detail.value!)}} style={{width:"100%"}}>
                                        <IonGrid>
                                            <IonRow>
                                                {question}
                                            </IonRow>
                                            <IonRow class="answerLabels">
                                                {useLabels.map((l,i) => {
                                                    var style = {};
                                                    // silly #temporary workaround since we have a very small number of cases but 7 is too many columns
                                                    if(i >= 6) style = {textAlign:"right",direction:"rtl"};
                                                    return <IonCol size="2" style={style}>{l}</IonCol>;
                                                })}
                                            </IonRow>
                                            <IonRow class="ion-nowrap ion-align-items-end">
                                                {
                                                //@ts-ignore
                                                useValues.map((v, i) => (
                                                    <IonCol size="2">
                                                        <IonRadio disabled={this.props.disabled ||
                                                            ( pairQuestions && (index % 2) === 1 && answers[index - (index % 2)] === 0 )} value={v} />
                                                    </IonCol>
                                                ))
                                                }
                                            </IonRow>
                                        </IonGrid>
                                    </IonRadioGroup>
                                </IonItem>
                            )
                        })
                    }
                </IonList>
            </div>
        );

        // Trigger a special event when the number of elements rendered changes so the parent component can update
        if(this.props.onSpecialChange && rendered !== this.numRendered) this.props.onSpecialChange(answers);
        this.numRendered = rendered;

        return r;
    }
}