import { IonGrid, IonInput, IonItem, IonList, IonRow } from '@ionic/react';
import React from 'react';
import SurveySection from './SurveySection';

export default class SectionText extends SurveySection
{
    constructor(props)
    {
        super(props);
    }
    
    encodeAnswers()
    {
        return this.getQuestionAnswers().join('-');
    }

    render()
    {
        return (
            <div>
                {/* Header */}
                { this.getHeader() }

                {/* Questions */}
                <IonList>
                    {
                        this.props.questions.map((question, index) => {
                            return (
                                <IonItem key={index} class="text">
                                    <IonGrid>
                                        <IonRow>
                                            {question}
                                        </IonRow>
                                        <IonRow>
                                            <IonInput placeholder={this.props.labels[index]} value={this.state.answers[index]} onIonChange={e => {
                                                this.setQuestionAnswer(index, e.detail.value!);
                                            }} debounce={250} clearInput disabled={this.props.disabled} />
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            )
                        })
                    }
                </IonList>
            </div>
        );
    }
}