import React, { useContext, useMemo } from 'react';
import { IonCard, IonCardHeader, IonTitle, IonCardContent } from '@ionic/react';

import "./PointsOverview.css";
import { GameContext } from '../../gamification/gamification';
import { UserTask } from '../../gamification/GOALS';
import { filterCompleted24hrs } from './TaskOverview';
import { taskPoints, CompletedStates } from '../../gamification/tasks';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

function filterClaimed(task: UserTask) {
    return task.status === "REWARD_CLAIMED";
}

function sortCompletedTasks(a: UserTask, b: UserTask) {
    const ac = CompletedStates.includes(a.status);
    const bc = CompletedStates.includes(b.status);
    if (!ac && !bc)
        return 0;
    if (ac !== bc)
        return bc ? 1 : -1;
    return (b.completionTime ?? 0) - (a.completionTime ?? 0);
}

function pointsHistory(tasks: UserTask[], days: number) {
    const now = new Date();
    const pts: number[] = new Array(days).fill(0);
    tasks.forEach(task => {
        if (!task.completionTime || !CompletedStates.includes(task.status)) return;
        const diff = now.valueOf() - task.completionTime;
        const days = Math.floor(diff / 86400000);
        // console.log(days, taskPoints(task), task, new Date(task.completionTime));
        if (days >= pts.length) return;
        pts[days] += taskPoints(task);
    });
    return pts.reverse();
}

const PointsOverview: React.FC = () => {
    const { tasks, points } = useContext(GameContext);
    const pointsToday = useMemo(() => {
        // Get claimed tasks completed today
        const t = tasks.filter(filterCompleted24hrs).filter(filterClaimed);
        // Add point values for all of today's completed tasks
        return t.reduce((p, c) => p + taskPoints(c), 0);
    }, [tasks]);

    const change = useMemo(() => {
        // Calculate percent change in points
        let percentDiff = Number((pointsToday / (points - pointsToday)) * 100).toFixed(2);
        return `${pointsToday >= 0 ? '+' : '-'}${pointsToday} ${percentDiff !== 'Infinity' ? `(${percentDiff}%)` : ''}`;
    }, [points, pointsToday]);

    const history = useMemo(() => pointsHistory(tasks, 7), [tasks]);

    return (
        <IonCard style={{ width: '100%' }}>
            <IonCardHeader>
                <IonTitle>
                    Points Overview
                </IonTitle>
            </IonCardHeader>
            <IonCardContent>
                <div className="home-pt-top">
                    <div className="home-pt-total">
                        <h4>Points</h4>
                        <p>{points}</p>
                    </div>
                    <div className="home-pt-today">
                        <h4>Today</h4>
                        <p className={pointsToday > 0 ? 'pt-positive' : 'pt-neutral'}>{change}</p>
                    </div>
                </div>
                <div className="home-pt-line">
                    <Line
                        options={{
                            responsive: true
                        }}
                        data={{
                            labels: history.map((_, idx) => {
                                const d = new Date();
                                d.setDate(d.getDate() + idx - 6);
                                return d.toLocaleDateString(undefined, { day: 'numeric', month: 'numeric' });
                            }),
                            datasets: [
                                {
                                    label: 'Points',
                                    data: history,
                                    borderColor: 'limegreen',
                                    backgroundColor: 'limegreen'
                                }
                            ]
                        }}
                    />
                </div>
            </IonCardContent>
        </IonCard>
    );
};

export default PointsOverview;