import React, { useCallback, useReducer } from 'react';
import NIJAPI, { Task as OldTask, UserStatus } from '../NIJAPI';
import { sampleGOALSTasks } from '../sampleObjects/sampleTasksNew';
import { Task, UserTask } from './GOALS';
import { GameAction, GameFlag, handleGameFlag, CompletedStates, taskPoints } from './tasks';

const is_dev = process.env.REACT_APP_GOALS_DEV === '1';

export interface OldGamificationData
{
    points: number,             // Current number of points
    pointHistory: number[],     // Points gained in the past n days, where pointHistory[0] is the current day, pointHistory[1] is yesterday, etc.
    taskHistory?: OldTask[][],     // Tasks completed in the past n days, where taskHistory[0] is the list of tasks completed today, taskHistory[1] is yesterday, etc.
}

export interface GamificationData
{
    points: number,                     // Current number of points
    level: number,                      // Current level
    pointHistory: number[],            // Points gained in the past n days, where pointHistory[0] is the current day, pointHistory[1] is yesterday, etc.
    taskHistory: UserTask[][],       // Tasks completed in the past n days, where taskHistory[0] is the list of tasks completed today, taskHistory[1] is yesterday, etc.
    activeTask?: UserTask             // Current task being completed in the app
    userId?: number,                    // ID of logged in user
    username?: string,                  // Username of logged in user
    tasks: UserTask[],                // Current tasks for this user
    stateFlags: Record<string, any>     // Flags used for some arbitrary state info, e.g. debug flags
    userStatus: UserStatus              // Current user status
}

export interface GameContextValue extends GamificationData
{
    updateGameState: React.Dispatch<Partial<GamificationData>>,
    doGameEvent: <T extends GameFlag>(action: GameAction<T>) => void
}

export const GameContext = React.createContext<GameContextValue>({
    points: 0,
    level: 0,
    pointHistory: [],
    taskHistory: [],
    tasks: [],
    stateFlags: {},
    userStatus: {
        fivekeyPoint: 0,
        healthPoint: 0,
        isSafNeeded: true,
        maxFivekeyPoint: 0,
        maxHealthPoint: 0,
        maxMeetingPoint: 0,
        maxRestrictionPoint: 0,
        meetingPoint: 0,
        programEndTime: 0,
        programStartTime: 0,
        restrictionPoint: 0,
        userId: -1
    },
    updateGameState: v => { console.trace(`Got updateGameState using default state! This has no effect.`) },
    doGameEvent: v => { console.trace(`Got doGameEvent using default state! This has no effect.`) }
});

export type GameStateAction = Partial<GamificationData> & {
    preApply?: (GamificationData) => Partial<GamificationData> | null,      // Chain another reducer before this one
    postApply?: (GamificationData) => Partial<GamificationData> | null      // Chain another reducer after this one
};

function processTaskChange(tasks: UserTask[]): Partial<GamificationData>
{
    const points = tasks.reduce((pt,t) => {
        if(!CompletedStates.includes(t.status)) return pt;
        // Automatically claim rewards
        if(t.status === "COMPLETED" && new Date(t.completionTime).getDate() !== new Date().getDate())
            NIJAPI.instance.claimTask(t.id);

        return pt + taskPoints(t);
    }, 0);

    return {
        points: points
    };
}

// Apply new values from action to state
function gameReducer(state: GamificationData, action: GameStateAction): GamificationData
{
    console.log("old", state);
    // console.log("new", { ...state, ...action });
    let newState = {...state};
    if(action.preApply)
    {
        newState = {...newState, ...action.preApply(newState)};
        action.preApply = undefined;
    }

    newState = {...newState, ...action};

    if(action.postApply)
    {
        newState = {...newState, ...action.postApply(newState)};
        action.postApply = undefined;
    }

    // Process changes to tasks
    if(state.tasks !== newState.tasks)
    {
        const update = processTaskChange(newState.tasks);
        console.log("task update", update);
        newState = {...newState, ...update};
    }

    console.log("new", newState);
    return newState;
}

export const GameContextProvider: React.FC = (props) => {
    // Set up reducer for gamification data
    const [state, updateState] = useReducer(gameReducer, {
        pointHistory: [],
        taskHistory: [],
        stateFlags: {
            rwatQuickSubmit: is_dev,
            rwatDefaultResponse: is_dev
        },
        points: 0,
        level: 0,
        userStatus: {
            fivekeyPoint: 0,
            healthPoint: 0,
            isSafNeeded: true,
            maxFivekeyPoint: 0,
            maxHealthPoint: 0,
            maxMeetingPoint: 0,
            maxRestrictionPoint: 0,
            meetingPoint: 0,
            programEndTime: 0,
            programStartTime: 0,
            restrictionPoint: 0,
            userId: -1
        },
        tasks: []
    });

    // Set up game event handler
    const gameEvent = useCallback(<T extends GameFlag>(action: GameAction<T>) => {
        updateState({
            preApply: (state: GamificationData) => handleGameFlag(state, action)
        });
    }, [updateState]);

    // Set up context value
    const gameState: GameContextValue = {
        ...state,
        updateGameState: updateState,
        doGameEvent: gameEvent
    };

    React.useEffect(() => {
        // Fetch task list after authentication
        NIJAPI.instance.on('auth', () => {
            console.log("Fetching game info...");
            NIJAPI.instance.fetchTaskList().then(list => updateState({
                tasks: list
            }));
            NIJAPI.instance.fetchStatus().then(status => {
                updateState({ userStatus: status });
            });
            NIJAPI.instance.fetchUserLevel().then(response => {
                updateState({ level: response.userLevel})
            })
          });
    }, [])

    return (
        <GameContext.Provider value={gameState}>
            { props.children }
        </GameContext.Provider>
    );
}