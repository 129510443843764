import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonModal, IonRow } from '@ionic/react';
import { closeCircleOutline, heartOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import './JobListing.css';

interface JobObject {
    jobObj: object,
    callback?: (event: React.MouseEvent<HTMLIonCardElement, MouseEvent>) => void
}

// Takes in a job object as returned by the API (see sampleObjects/sampleJobListings.json)
// and constructs a card component which can be tapped to get more information.
// Optionally, a callback can be provided which will fire when the card is tapped.
const JobListing: React.FC<JobObject> = ({ jobObj, callback }) => {
    const [showModal, setModal] = useState(false);

    return (
            <div>
            <IonItem button onClick={(e) => { setModal(true); if (callback) callback(e); }}>
                <IonLabel>

                
                    <div className='leftCol'><h1 className="jobTitle">{jobObj['jobTitle']}</h1></div>
                    <IonButtons><IonButton className="heartButton" fill="clear" color='light'><IonIcon className="titleIcons" icon={heartOutline} /></IonButton><IonButton className="deleteButton" fill="clear" color='light'><IonIcon className="titleIcons" icon={closeCircleOutline} /></IonButton></IonButtons>
                

                
                    <p className="blackText">{jobObj['employer']}</p>
                    <p className="blackText">{jobObj['location']}</p>
                    
                </IonLabel>
            </IonItem>

            
            <IonModal isOpen={showModal} onDidDismiss={ () => { setModal(false); } } cssClass="jobModal">
                <IonContent>
                    <IonCard>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <h1 className="missionCategory">{jobObj['jobTitle']}</h1>
                                    <h5 className="missionCategory">{jobObj['employer']}</h5>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonItemDivider className="labelText">Description</IonItemDivider>
                                <IonCol size="12">
                                    <div className="centeredText">
                                        Wage: {jobObj['salary'] ? '$' + jobObj['salary'] + '/hr' : "Unknown"} <br />
                                        {jobObj['description']}
                                    </div>

                                </IonCol>
                            </IonRow>

                            <IonItemDivider className="labelText">Conditions</IonItemDivider>
                            <IonRow>
                                <IonCol size="12">
                                    <div className='centeredText'>
                                        {jobObj['workingConditions']}
                                    </div>

                                </IonCol>


                            </IonRow>
                            <IonRow>
                                <IonItemDivider className="labelText">Requirements</IonItemDivider>
                                <IonCol size="12">
                                    <div className="centeredText">
                                        {jobObj['jobRequirements']} <br />
                                        {jobObj['requiredExperience']} <br />
                                        {jobObj['physicalRequirements']} <br />
                                        {jobObj['mentalRequirements']} <br />
                                        {jobObj['requiredQualifications']} <br />
                                    </div>

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonItemDivider className="labelText">Preferred</IonItemDivider>
                                <IonCol size="12">
                                    <div className="centeredText">
                                        {jobObj['preferredQualifications']} <br />
                                        {jobObj['preferredExperience']}
                                    </div>

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonItemDivider className="labelText">Contact</IonItemDivider>
                                <IonCol size="12">
                                    <div className="centeredText">
                                        {jobObj['phoneNumber']} <br />
                                        <a href={jobObj['website']}>{jobObj['website']}</a>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow class="ion-justify-content-center">
                                <IonItemDivider></IonItemDivider>
                                <IonCol size="3">
                                    <IonButton color="success">Save</IonButton>
                                </IonCol>
                                <IonCol size="3">
                                    <IonButton onClick={() => { setModal(false); }} fill="clear">Close</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCard>
                </IonContent>
            </IonModal>
        </div>
    );
}; 

export default JobListing;