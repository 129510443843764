import { IonButton, IonContent, IonFab, IonFabButton, IonFooter, IonIcon, IonPage, useIonLoading } from '@ionic/react';
import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useHistory } from 'react-router';
import useAuth from '../../hooks/useAuth';
import useLibraryResource from '../../hooks/useLibraryResource';
import { add, remove } from 'ionicons/icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const CustomPDFViewer: React.FC = () => {
    const history = useHistory()
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [resources, isLoading, isError] = useLibraryResource(`${history.location.pathname.split("resource/")[1]}`)
    const [present, dismiss] = useIonLoading()
    const [scale, setScale] = useState<number>(0.6)


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (isLoading) {
            present({ message: "Loading pdf...", })
        }

        if (isError) {
            dismiss()
        }

        if (resources != null) {
            dismiss()
        }

    }, [resources, isLoading, isError, present, dismiss])

    return (
        <IonPage>
            <IonContent style={{ overflow: 'scroll' }}>
                <TransformWrapper>
                    <TransformComponent>
                        <Document file={resources} onLoadSuccess={onDocumentLoadSuccess} options={{ pdfBug: true }} renderMode="svg">
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                        <p>

                            {/* Page {pageNumber} of {numPages} */}
                        </p>
                    </TransformComponent>
                </TransformWrapper>
            </IonContent>
            {/* <IonFooter>
                <IonFab vertical="bottom" horizontal="end" >
                    <IonFabButton onClick={() => { setScale(scale + 0.1) }}>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
                <IonFab vertical="bottom" horizontal="start" >
                    <IonFabButton onClick={() => { setScale(scale - 0.1) }}>
                        <IonIcon icon={remove} />
                    </IonFabButton>
                </IonFab>
            </IonFooter> */}

        </IonPage>
    )
}

export default CustomPDFViewer;