import { IonCheckbox, IonItem, IonLabel, useIonViewWillEnter } from "@ionic/react"
import React, { useState } from "react"
import NIJAPI from "../../NIJAPI"
import Checklist from "./ChecklistInterface"

const ChecklistItem: React.FC<{data:Checklist}> = ({data}) => {

    const [completed,setCompleted] = useState<boolean>(data.completed)
  
    useIonViewWillEnter(()=> {
      setCompleted(data.completed)
    })
  
    async function updateChecklist(id:number,checked:boolean):Promise<any>{
      // @ts-ignore
      await NIJAPI.instance.putChecklist(id, { completed: checked }).then((response) => {
    //  await Axios.put(`http://192.168.0.168:1337/scheduling-checklists/${id}`,{completed:checked}).then((response) => {
        console.log(response.status);
        if (response.status === 200){
          setCompleted(checked)
        }
     }).catch((err)=> {
       console.error(err)
     }) 
    }
  
    if(completed){
      // setCompleted(true)
      return(
        <IonItem>
              <IonLabel>
                <del>
                  {data.name}
                  </del>
                </IonLabel>
              <IonCheckbox slot="start" checked={completed} onIonChange={(e) => 
                {
                  // setCompleted(false)
                  updateChecklist(data.id,false)
                }}></IonCheckbox>
            </IonItem>
      )
    }
  
    else{
      // setCompleted(false)
      return(
      <IonItem>
              <IonLabel>
                  {data.name}
                </IonLabel>
              <IonCheckbox slot="start" checked={completed} onIonChange={(e) => {
                updateChecklist(data.id,true)
                // setCompleted(true)
                }}></IonCheckbox>
            </IonItem>
      )  
  }
  
  
  }

  export default ChecklistItem