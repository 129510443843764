import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IonBadge, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonFooter, IonHeader, IonIcon, IonItem, IonList, IonPage, IonTextarea, IonTitle, IonToolbar, useIonModal, IonBackButton, useIonLoading, useIonAlert } from '@ionic/react';
import { Media, MediaObject } from '@awesome-cordova-plugins/media';
import "./TTSSession.css";
import { arrowBack, arrowForward, ear, information, keypadOutline, mic, micCircleOutline, person, personCircle, play, playSharp, send, sendSharp, stop, text } from 'ionicons/icons';
import { ChoicePagePart, InterventionInfo, PagePart, SessionInfo } from '../../components/fivekey/SessionInterfaces';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { HTTP } from '@awesome-cordova-plugins/http';
import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition';
import { TypeOfExpression } from 'typescript';
import NIJAPI, { SessionUpdateQuery } from '../../NIJAPI';
import { useCallback } from 'react';
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import ReactMarkdown from 'react-markdown';
import {remark} from 'remark';
import strip from 'strip-markdown';
import { EmptySession, ErrorSession } from '../../sampleObjects/emptySession';
import getSessionInfo from '../../sessions/sessionMap';
import { Storage } from '@capacitor/storage';
import Zoom from 'react-medium-image-zoom';

import 'react-medium-image-zoom/dist/styles.css'

enum QuestionStatus {
    Unfinished,
    Finished,
    Pending
}

interface TTSSessionMenuProps extends RouteComponentProps<{ sessionId: string }>
{
    // session: SessionInfo
}

const InfoModal: React.FC<{
    onDismiss: () => void
    info: string[]
}> = ({onDismiss, info}) => {
    return (
        <div className="session-info-modal">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Information
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onDismiss} fill="clear">
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <IonList>
                    {info.map((v, i) => {
                        let paragraphs = v.split('\n');
                        return paragraphs.map((pv, j) => (
                            <IonItem key={`${i}_${j}`} lines="none">
                                <p className="session-info-item ion-padding">
                                    {pv}
                                </p>
                            </IonItem>
                        ));
                    })}
                </IonList>
            </IonContent>
        </div>
    );
}

const Introduction: React.FC<{ introduction: string, speak: (str: string) => void }> = ({ introduction, speak }) => {
    return (
        <div className="ttsContent">
            <div className="ttsButton">
                <IonIcon icon={personCircle} style={{
                    width: '100%',
                    height: '100%'
                }} />
            </div>

            <div className="ttsContainer">
                <div className="responseForm">
                    <IonButton onClick={() => {
                        speak(introduction);
                    }}>
                        Listen
                        <IonIcon icon={playSharp} slot="end" />
                    </IonButton>
                </div>
            </div>
        </div>
    )
};

const TTSSessionMenu: React.FC<TTSSessionMenuProps> = ({ match }) => {
    // const { interventionList, match } = props;
    const [session, setSession] = useState<SessionInfo>(EmptySession);
    const [questionIndex, setQuestion] = useState(0);
    const [speaking, setSpeaking] = useState("");
    const [recording, setRecording] = useState(false);
    const [files, setFiles] = useState<MediaObject[]>([]);
    const [answers, setAnswers] = useState<string[]>([]);
    const [currFile, setCurrFile] = useState<MediaObject>();
    const [playback, setPlayback] = useState(false);
    const [mode, setMode] = useState(false);
    const [questionMode, setQuestionMode] = useState<boolean[]>([]);
    const [questionStatus, setQuestionStatus] = useState<QuestionStatus[]>([]);
    const [aiResponse, setAIResponse] = useState<string>("");
    const [progress, setProgress] = useState<number>(1);
    const [chosen, setChosen] = useState<boolean[]>(new Array(10).fill(false));
    const [responses, setResponses] = useState<{[question: string]: string}>({});
    const [startTime, setStartTime] = useState<number>(new Date().getTime());
    const [presentLoad, dismissLoad] = useIonLoading();
    const [presentSaveAlert] = useIonAlert();

    const { search } = useLocation();
    const [query, logId, status] = useMemo(() => {
        const q = new URLSearchParams(search);
        const id = q.get("logId");
        console.log(q, id);
        return [q, id, q.get("status")];
    }, [search]);

    // Get list of info based on which questions are finished
    const info: string[] = useMemo<string[]>(() => {
        const i: string[] = [];

        if(session.introduction.length > 0) i.push(session.introduction);

        session.info?.forEach((v, ind) => {
            if(ind <= questionIndex)
                i.push(v[1]);
        })

        return i;
    }, [session.introduction, session.info, questionIndex]);
    const handleDismiss = () => dismissModal();

    const [presentModal, dismissModal] = useIonModal(InfoModal, {
        info: info,
        onDismiss: handleDismiss
    });

    const questions = session.questions;

    const history = useHistory();

    const recordingRef = useRef<boolean>(false);
    recordingRef.current = recording;

    const [text, bubbles] = useMemo(() => {
        const t = aiResponse.length > 0 ? 
            aiResponse : (
                questionIndex < 0 ? session.introduction : (
                    questionIndex < questions.length ? questions[questionIndex] : "This concludes the session. Press \"SUBMIT\" below to complete the session!"
                )
            );
        return [t, t.split("\n")];
    }, [aiResponse, questionIndex, questions, session.introduction]);

    const currPart = session.pages[questionIndex]?.parts[progress - 1];
    const displayedParts: PagePart[] = questionIndex < session.pages.length ?
        session.pages[questionIndex].parts.reduce((prev, curr, i) => {
            // Skip parts we haven't reached yet
            if(progress <= i && questionStatus[questionIndex] !== QuestionStatus.Finished) return prev;

            // Add text part to read out question prompts
            if(curr.type === "choice" || curr.type === "response")
            {
                prev.push({
                    type: "text",
                    data: {
                        prompt: curr.data.prompt
                    }
                });

                if(responses[curr.data.prompt] && (progress > i + 1 || questionStatus[questionIndex] === QuestionStatus.Finished))
                    // @ts-ignore
                    prev.push({...curr, data: {...curr.data, prompt: responses[curr.data.prompt]}});
            }
            else
                // Push this part
                prev.push(curr);

            return prev;
        }, [] as PagePart[])
        :
        [{
            type: "text",
            data: {
                prompt: status !== "DONE"   ? "This concludes the session. Press \"SUBMIT\" below to complete the session!"
                                            : "This concludes the session. Press \"RETURN\" to return to the list of sessions."
            }
        }];

    console.log(progress, displayedParts);

    let displayBubbles: string[] = [];
    if(questionStatus[questionIndex] === QuestionStatus.Finished)
        displayBubbles = [...bubbles, answers[questionIndex]];
    else
        displayBubbles = bubbles.slice(0, progress);

    //@ts-ignore
    const SR: typeof window.SpeechRecognition = window.speechRecognition || window.webkitSpeechRecognition;

    const saveResponses = (resp) => {
        Storage.set({
            key: `session${match.params.sessionId}_${logId}`,
            value: JSON.stringify(resp)
        }).then(console.info, console.error);
    };


    useEffect(() => {
        SpeechRecognition.requestPermission()
            .then(
                () => console.log("Speech permission granted"),
                () => console.log("Speech permission denied")
            );
    }, [])

    // Load session from JSON based on provided ID
    useEffect(() => {
        let s: SessionInfo;

        // In case this fires multiple times, make sure there is only one loader
        dismissLoad();
        presentLoad({
            message: "Loading session...",
            duration: 3000
        });

        try
        {
            s = getSessionInfo(match.params.sessionId);
        }
        catch(e)
        {
            presentSaveAlert({
                header: 'Error',
                message: `Error when loading session! ${e}`,
                buttons: ['Close']
            });
            console.error('Session load error', e);
            s = ErrorSession;
        }
        setSession(s);

        setFiles(new Array<MediaObject>(s.pages.length));
        setAnswers(new Array<string>(s.pages.length));
        setQuestionMode(new Array<boolean>(s.pages.length));
        // console.log(status);
        const arr = new Array<QuestionStatus>(s.pages.length);
        arr.fill(status === "DONE" ? QuestionStatus.Finished : QuestionStatus.Unfinished);
        console.log(arr);
        setQuestionStatus(arr);

        if(status !== "DONE")
        {
            presentSaveAlert({
                header: 'Notice',
                message: 'Session responses will be automatically saved in case the session is interrupted',
                buttons: ['Ok']
            });            
        }


        // Try to restore saved responses
        Storage.get({key: `session${match.params.sessionId}_${logId}`})
            .then(val => {
                let resp = val.value;
                if(!resp) {
                    if(!logId) {
                        dismissLoad();
                        return;
                    }

                    // If no stored responses exist, try and fetch from the backend.
                    console.log("Session not found in storage, attempting fetch from backend...");

                    try
                    {
                        NIJAPI.instance.fetchSessionLog(parseInt(logId))
                            .then(log => {
                                console.log("Session responses fetched from backend!", log.response);
                                setResponses(log.response);
                                dismissLoad();
                            })
                            .catch(e => {
                                console.error("Fetching session log failed", e);
                                dismissLoad();
                            });                        
                    }
                    catch
                    {
                        dismissLoad();
                    }

                    return;
                }

                try
                {
                    let savedResponses = JSON.parse(resp);
                    console.log(savedResponses);
                    setResponses(savedResponses);
                }
                catch(e)
                {
                    console.error(e);
                }

                dismissLoad();
            })
            .catch(e => {
                console.error(e);
                dismissLoad();
            });

        // Give up loading responses after 5s if something weird happens
        // setTimeout(() => {console.error("Session loading skipped?"); dismissLoad();}, 3000);
    }, [dismissLoad, logId, match, presentLoad, presentSaveAlert, status]);

    function recordAudio()
    {
        if(recordingRef.current)
        {
            // currFile?.stopRecord();
            // setRecording(false);
            return;
        }

        // let f = files;

        // let curr = f[questionIndex] = Media.create(`cdvfile://localhost/files/i${match.params.interventionId}s${match.params.sessionId}_question${questionIndex}.aac`);
        // console.log(`cdvfile://localhost/files/i${match.params.interventionId}s${match.params.sessionId}_question${questionIndex}.aac`);
        // curr.onSuccess.subscribe(() => setPlayback(false));
        // setCurrFile(curr);
        setRecording(true);

        SpeechRecognition.startListening({
            showPopup: false
        })
            .subscribe(
                (values: string[]) => {
                    console.debug(values);
                    let a = answers[questionIndex] = [answers[questionIndex], values[0]].join(' ').trim();
                    setAnswers(answers);
            
                    let resp = {...responses};
                    resp[currPart.data.prompt] = [resp[currPart.data.prompt], values[0]].join(' ').trim();
                    setResponses(resp);
                    saveResponses(resp);

                    setRecording(false);
                },
                (error) => {
                    setRecording(false);
                    console.error(error);
                }
            );

        // curr.startRecord();
    }

    function playAudio()
    {
        if(!files[questionIndex] || recording) return;

        if(playback)
        {
            files[questionIndex].stop();
            setPlayback(false);
        }
        else
        {
            console.log(files[questionIndex]);
            files[questionIndex].play();
            setPlayback(true);
        }
    }

    const submit = useCallback((newStatus: "DONE" | "PENDING" = "DONE") =>
    {
        // let responses = {};

        // let i = 0;
        // for(const file of files)
        //     responses[i++] = 'https://nij-server/activity/audio/5/1.mp3';

        if(!logId)
        {
            console.error("Cannot submit session, no log ID provided!");
            return;
        }

        const update: SessionUpdateQuery = {
            endTime: new Date().getTime(),
            startTime: startTime,
            participantId: NIJAPI.instance.userId,
            response: responses,
            sessionLogId: logId,
            status: newStatus
        };

        console.info(update);

        NIJAPI.instance.updateSession(update)
            .then(r => {
                console.log(r);

                if(newStatus === "DONE")
                    history.goBack();
            })
            .catch(e => console.error(e));
    }, [history, logId, responses, startTime]);

    async function sentimentResponse()
    {
        if(!answers[questionIndex] || answers[questionIndex].length < 1)
        {
            questionStatus[questionIndex] = QuestionStatus.Unfinished;
            setQuestionStatus(questionStatus);
            return;
        }

        questionStatus[questionIndex] = QuestionStatus.Finished;
        setQuestionStatus(questionStatus);
        console.info("Sentiment analysis not available.");

        // NIJAPI.sentimentAnalysis(answers[questionIndex])
        //     .then(r => {
        //         console.log(r);
        //         questionStatus[questionIndex] = QuestionStatus.Finished;
        //         setQuestionStatus(questionStatus);
        //         setAIResponse(r.additional_line);
        //     })
        //     .catch(e => {
        //         console.error(e);
        //         questionStatus[questionIndex] = QuestionStatus.Finished;
        //         setQuestionStatus(questionStatus);
        //     });
    }

    const responseForm = () => {
        // Return empty form if this is an AI response, introduction, or finished question
        if(aiResponse.length > 0 ||
            questionStatus[questionIndex] === QuestionStatus.Finished ||
            questionIndex < 0 ||
            currPart?.type === "text")
            return null;

        if(questionIndex >= session.pages.length) // Show submit button if the activity is complete
        {
            return (
                <div className="ttsContainer">
                    <div className="responseForm">
                        <IonButton onClick={e => { if(status !== "DONE") submit("DONE"); else history.goBack(); }}>
                            {status !== "DONE" ? "Submit" : "Return"}
                        </IonButton>
                    </div>
                </div>
            )
        }

        if(currPart?.type === "choice")
        {
            let part = currPart as ChoicePagePart;
            let choices = part.data.choices;
            return (
                <div className="ttsContainer">
                    <div className="responseForm">
                        <div className="ttsChoiceForm">
                            {/* <h3>
                                {part.data.prompt}
                            </h3> */}
                            <div className="ttsChoices">
                                {
                                    choices.map((v,i) => {
                                        return (
                                            <IonButton
                                                fill={chosen[i] ? "solid" : "outline"}
                                                shape="round"
                                                className="ttsChoice"
                                                key={v.text}
                                                onClick={e => {
                                                    let totalChosen = chosen.reduce((p, c) => {
                                                        return p += c ? 1 : 0;
                                                    }, 0);
                                                    if(totalChosen >= part.data.maxChoices && !chosen[i])
                                                        return;
                                            
                                                    // Update chosen answers
                                                    let c = [...chosen];
                                                    c[i] = !c[i];
                                                    setChosen(c);
                                            
                                                    // Update button style
                                                    // let elem = e.target as HTMLElement;
                                                    // elem.classList.remove("button-outline", "button-solid");
                                                    // elem.classList.add(c[i] ? "button-solid" : "button-outline");
                                            
                                                    // Update response based on choices
                                                    let chosenAnswers = choices.filter((_, i) => c[i]).reduce((c,v)=>{
                                                        c.push(v.text);
                                                        return c;
                                                    }, [] as string[]);
                                                    console.log(chosenAnswers);
                                                    let a = [...answers];
                                                    a[questionIndex] = chosenAnswers.join("; ");
                                                    setAnswers(a);
                                                    let resp = {...responses};
                                                    resp[part.data.prompt] = a[questionIndex];
                                                    console.log(a[questionIndex], resp);
                                                    setResponses(resp);
                                                    saveResponses(resp);
                                                }}
                                            >
                                                {v.text}
                                            </IonButton>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="ttsContainer">
                <div className="responseForm">
                    <div className="ttsCombinedForm">
                        <div className="ttsInlineTextInput" onClick={(e) => {
                            // @ts-ignore
                            document.getElementById('ttsTextArea').setFocus();
                        }}>
                            <IonTextarea
                                autoGrow
                                className="ttsResponseBox"
                                id="ttsTextArea"
                                placeholder="Type a response here or use voice on right"
                                value={responses[currPart?.data.prompt]}
                                disabled={progress < bubbles.length}
                                onIonChange={e => {
                                    let a = answers;
                                    a[questionIndex] = e.detail.value!;
                                    setAnswers(a);
                                    let resp = {...responses};
                                    resp[currPart?.data.prompt] = e.detail.value!;
                                    setResponses(resp);
                                    saveResponses(resp);
                                    setMode(e.detail.value!.length > 0);
                                }}
                            />
                        </div>

                        <div className="ttsInlineButton">
                            <IonButton
                                fill={recording ? "solid" : "outline"}
                                className={recording ? "tts-recording" : ""}
                                onClick={event => recordAudio()}
                                disabled={progress < bubbles.length}
                            >
                                <IonIcon icon={mic} slot="icon-only" />
                            </IonButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const forwardButton = useMemo(() => {
        return (
            <IonFab vertical="center" horizontal="end">
                <IonFabButton size="small" onClick={(event) => {
                    console.log(aiResponse, questionStatus[questionIndex], questionIndex, session!.questions.length, questionStatus[questionIndex] === QuestionStatus.Pending);
                    if(aiResponse.length > 0)
                        setAIResponse("");
                    else if(questionStatus[questionIndex] === QuestionStatus.Unfinished || questionStatus[questionIndex] === undefined)
                    {
                        setProgress(progress + 1);
                        let a = [...answers];
                        a[questionIndex] = "";
                        setAnswers(a);
                        setChosen(new Array(10).fill(false));

                        if(questionIndex < 0 || (progress + 1) > session.pages[questionIndex].parts.length)
                        {
                            questionStatus[questionIndex] = QuestionStatus.Finished;
                            setQuestionStatus(questionStatus);

                            // Update responses every page
                            if(status !== "DONE")
                                submit("PENDING");
                        }
                        // sentimentResponse();
                        // return;
                    }

                    if(questionStatus[questionIndex] === QuestionStatus.Finished)
                    {
                        let ind = Math.min(questionIndex + 1, session!.pages.length);
                        setQuestion(ind);
                        let prog = 1;

                        // Set progress to last question answered on this page
                        if(ind < session!.pages.length)
                        {
                            let parts = session.pages[ind].parts;
                            console.log(responses);
                            for(let p = parts.length - 1; p >= 0; p--)
                            {
                                if(parts[p].type == "text") continue;
                                
                                if(responses[parts[p].data.prompt])
                                {
                                    prog = p + 1;
                                    break;
                                }
                            }
                        }
                        console.log(ind, prog);
                        setProgress(prog);
                        setChosen(new Array(10).fill(false));
                    }
                }} disabled={((questionIndex+1) > session!.pages.length)
                            || (questionStatus[questionIndex] === QuestionStatus.Pending)
                            || (questionIndex > -1 
                                && questionStatus[questionIndex] !== QuestionStatus.Finished
                                && (currPart?.type === "choice" || currPart?.type === "response")
                                && !responses[currPart.data.prompt])}>
                    <IonIcon icon={arrowForward} />
                </IonFabButton>
            </IonFab>
        );
    }, [questionIndex, session, questionStatus, currPart?.type, currPart?.data.prompt, responses, aiResponse, progress, answers, status, submit]);

    const speak = useCallback((str: string, element?: Element) => {
        // console.log(speaking, str);
        // Clear all tts-reading classes
        Array.from(document.getElementsByClassName("tts-reading")).forEach(e => {
            e.classList.remove("tts-reading");
        });
        if(speaking.length < 1 || (str.length > 0 && str !== speaking))
        {
            element?.classList.add("tts-reading");
            setSpeaking(str);
            TextToSpeech.speak({ text: str })
                .then(() => {
                    element?.classList.remove("tts-reading");
                    setSpeaking("");
                    // if(questionStatus[questionIndex] !== QuestionStatus.Finished && progress < session.pages[questionIndex].parts.length)
                    //     setProgress(progress + 1);
                });
        }
        else
        {
            setSpeaking("");
            TextToSpeech.speak({ text: "" })
                .then(() => {});
        }
    }, [speaking]);

    const backButton = useMemo(() => {
        return (
            <IonFab vertical="center" horizontal="start">
                <IonFabButton size="small" onClick={(event) => {
                    if(aiResponse.length > 0)
                    {
                        setAIResponse("");
                        return;
                    }

                    setQuestion(Math.max(questionIndex - 1, 0));
                    speak("");
                }} disabled={(questionIndex < 1 && aiResponse.length < 1) || questionStatus[questionIndex] === QuestionStatus.Pending}>
                    <IonIcon icon={arrowBack} />
                </IonFabButton>
            </IonFab>
        );
    }, [questionIndex, aiResponse.length, questionStatus, speak]);

    // const infoButton = useMemo(() => {
    //     return (
    //         <IonFab vertical="bottom" horizontal="start">
    //             <IonFabButton size="small" color="light" onClick={e => {
    //                 e.preventDefault();
    //                 presentModal()
    //             }}>
    //                 <IonIcon icon={information} />
    //             </IonFabButton>
    //             <IonBadge className="fab-badge" color="danger">{info.length}</IonBadge>
    //         </IonFab>
    //     );
    // }, [info]);

    const infoToolbarButton = useMemo(() => {
        return (
            <IonButton
                className="info-button"
                size="large"
                shape="round"
                onClick={e => {
                    e.preventDefault();
                    presentModal()
                }}
            >
                <IonIcon icon={information} />
                <IonBadge className="info-badge" color="danger">{info.length}</IonBadge>
            </IonButton>
        );
    }, [info.length, presentModal]);

    const PagePartBubble: React.FC<{part: PagePart, i: number}> = ({part, i}) => {
        let v = part.data.prompt;
        let isResponse = part.type === "choice" || part.type === "response";
        return (
            <>
                <div
                    id={"qbub" + i}
                    className={"questionBox" + (isResponse ? " response-bubble" : "")}
                    key={i}
                >
                    <div
                        className="questionText"
                        onClick={e => {
                            const dv = e.target! as HTMLElement;
                            if(dv.tagName !== "P") return;
                            remark()
                                .use(strip)
                                .process(v?.trim())
                                .then(f => {
                                    speak(String(f), document.getElementById("qbub" + i) as HTMLElement);
                                });
                        }}
                    >
                        <ReactMarkdown components={{
                            img: ({node, src, alt, ...props}) => {
                                // console.log("huh wuh");
                                return (
                                    <Zoom>
                                        <img src={src} alt={alt} {...props} onClick={e => e.preventDefault()} />
                                    </Zoom>
                                );
                            }
                        }}>
                            {
                                v?.trim()
                            }
                        </ReactMarkdown>
                    </div>
                </div>
            </>
        );
    }

    useEffect(() => {
        let bubblesDiv = document.getElementById("bubbles");
        bubblesDiv?.scrollTo(0, bubblesDiv.scrollHeight);
        // if(questionStatus[questionIndex] === QuestionStatus.Finished) return;
                                                
        // const dv = document.getElementById("bubbles")?.children.item(progress - 1);
        // // console.log(dv);
        // if(dv?.firstElementChild)
        //     speak(dv.firstElementChild.innerHTML, dv);
    }, [progress, questionStatus, questionIndex]);

    // console.log(speaking);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/fivekeys/case" />
                    </IonButtons>

                    <IonTitle>
                        Session
                    </IonTitle>

                    <IonButtons slot="end">
                        { infoToolbarButton }
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ttsSession">
                { backButton }
                { forwardButton }
                {
                    // questionIndex < 0 ?
                    // <Introduction introduction={session.introduction} speak={speak} />
                    // :
                    <div className="ttsContent">
                        <div className="ttsButton" id="bubbles">
                            {
                                displayedParts.map((v,i) => (
                                    <PagePartBubble part={v} i={i} key={i} />
                                ))
                            }
                            {/* <h3>
                                Question {questionIndex+1}
                            </h3> */}
                        </div>
                        { responseForm() }
                    </div>
                }
            </IonContent>
            {/* <IonFooter className="ttsFooter">
                <IonButton fill="clear" shape="round" onClick={e => setMode(!mode)} disabled={questionIndex >= questions.length || aiResponse.length > 0}>
                    { mode ? 
                        <>
                            <IonIcon icon={mic} size="large" slot="start" />
                            Use Voice
                        </>
                        :
                        <>
                            <IonIcon icon={text} size="large" slot="start" />
                            Use Text
                        </>
                    }
                </IonButton>
                { infoButton }
            </IonFooter> */}
        </IonPage>
    );
}

export default TTSSessionMenu;