import { IonCard, IonCardContent, IonCardTitle, IonCol, IonGrid, IonProgressBar, IonRow } from '@ionic/react';
import React, { Component, useState} from 'react';
import { Task, UserTask } from '../../gamification/GOALS';
import RewardIcon from "./RewardIcon";
import RewardIconProps from "./RewardIcon";
import './TaskItem.css';
import NIJAPI from './../../NIJAPI';


interface TaskItemProps {
    task: UserTask,
    onClick?: (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => void,
}   

// export default class TaskItem extends Component<TaskItemProps>
const TaskItem: React.FC<TaskItemProps> = (props) => 
{
    var [expanded, setExpanded] = useState(false); 

    function KeyToIcon(key : string ) {
        if(key === "Positive Relationships")
            return "boat";
        if(key === "Healthy Thinking Patterns")
            return "gear";
        if(key === "Positive Social Engagement")
            return "music";
        if (key === "Effective Coping Strategies")
            return "hammer";
        if (key === "Meaningful Work Trajectories")
            return "leaf";
        if (key === "Meaningful Work Trajectories")
            return "leaf";

        if (key === "health")
            return "heart";

        if (key === "restriction")
            return "stop";

        return "COIN";
    }
    
    function GetCompletedTag() : string {
        if(props.task.status === "ONGOING" || props.task.status === "PENDING")
            return "incomplete";
        else 
            return "complete"; 
    }

    function renderSmall() {
        let task = props.task;

        return(
            <IonCard className={"task-card compact " + GetCompletedTag()} id="task-item"  button onClick={() => setExpanded(!expanded)}>      
                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="10">
                                <IonCardTitle className="taskTitleCompact">
                                    {task.title}
                                </IonCardTitle>

                                <p className="dueText">
                                {new Date(task.limitedTo).toDateString()}
                                </p>

                                {/*{task.taskType}*/}
                            </IonCol>

                            <IonCol size="2" className="iconColumn">
                                <RewardIcon type={KeyToIcon(task.fivekeyCat)} amount={task.rewardAmt1} />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </IonCard>
        )
    }

    function renderBig() {
        let task = props.task;
        var formLink : string = "https://docs.google.com/forms/d/e/1FAIpQLSf46-IOridrNs9JEu1EdUUdZdQAncWj6OqUGlMCZgDLFbqrIQ/viewform?usp=pp_url" + 
        "&entry.1063371723=" + NIJAPI.instance.userId +
        "&entry.1563249320=" + props.task.title;

        return(
        <IonCard className={"task-card compact " + GetCompletedTag()} id="task-item" button onClick={() => setExpanded(!expanded)}>      
            <IonCardContent className="expanded">
                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <IonCardTitle className="taskTitleExpanded">
                                {task.title}
                            </IonCardTitle>

                            <p className="dueText">
                            Due {new Date(task.limitedTo).toDateString()}
                            </p>

                            <p className="taskDescription">
                                {task.description}
                            </p>

                            <p className="submitLink">
                                <a href={formLink} target="_blank" rel="noreferrer">
                                    Complete ↗
                                </a>
                            </p>

                            <p className="rewardText">
                                Reward: <RewardIcon type={KeyToIcon(task.fivekeyCat)} amount={task.rewardAmt1} />
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
        )
    }

    if(expanded === false)
        return renderSmall();
    else 
        return renderBig(); 
}

export default TaskItem;