
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { FirebaseConfig } from '../firebase_config';
// import useAuth from './useAuth';

const useLibrary = <T>() => {
    const [responses, setResponses] = useState<T[] | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [userId, setUseId] = useState<string | undefined>("")
    const [token,setToken] = useState<string>("")

    function refresh() {
        if (userId != null) {
            axios.get(`${process.env.REACT_APP_NIJAPI_BASEURL}/library`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then((res) => {
                setIsLoading(false)
                setIsError(false)
                setResponses(res.data)
            }).catch((err) => {
                setIsLoading(false)
                setResponses(null)
                setIsError(true)
                console.error(err)
            });
        }
    }


    useEffect(() => {
        initializeApp(FirebaseConfig)
        var auth = getAuth()
        // console.log(userId)

        auth.onAuthStateChanged((user) => {
            setUseId(user?.uid)

            user?.getIdToken().then((token) => {
                setToken(token)
            })
            
        })

        
        setIsLoading(true)
        console.log(token)
        if (userId != null) {
            axios.get(`${process.env.REACT_APP_NIJAPI_BASEURL}/library`, {
                headers: {
                "Authorization":`Bearer ${token}`
            }}).then((res) => {
            setIsLoading(false)
            setIsError(false)
            setResponses(res.data)
        }).catch((err) => {
            setIsLoading(false)
            setResponses(null)
            setIsError(true)
            console.error(err)
        });
        
        }

        
        return () => {};

    }, [userId,token]);

    return [responses, isLoading, isError,refresh] as const
};

export default useLibrary;