import { SessionInfo } from '../components/fivekey/SessionInterfaces';

export const EmptySession: SessionInfo = {
    id: -1,
    title: "n/a",
    description: "n/a",
    questions: [],
    introduction: "",
    pages: [
        {
            parts: [
                {
                    type: "text",
                    data: {
                        prompt: "Placeholder session"
                    }
                }
            ]
        }
    ]
};

export const ErrorSession: SessionInfo = {
    id: -2,
    title: "n/a",
    description: "n/a",
    questions: [],
    introduction: "",
    pages: [
        {
            parts: [
                {
                    type: "text",
                    data: {
                        prompt: "This session does not exist"
                    }
                }
            ]
        }
    ]
};