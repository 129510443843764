import { IonText } from '@ionic/react';
import React from 'react';
import { Rtif } from '../../rtif';
import RWAT from '../../RWAT';

export interface SurveySectionProps {
    id: string,
    name?: string,
    prompt?: string,
    questions: Array<string>,
    labels: Array<string>,
    values?: Array<any>,
    disabled?: boolean,
    options?: {[key: string]: any},
    onChange?: (answers: any[]) => void,
    onValid?: (answers: any[]) => void,
    onInvalid?: (answers: any[]) => void,
    onValidChange?: (answers: any[]) => void,
    onSpecialChange?: (answers: any[]) => void
}

export interface SurveySectionState {
    answers: any[];
}

abstract class SurveySection<P extends SurveySectionProps = SurveySectionProps, S extends SurveySectionState = SurveySectionState> extends React.Component<P, S>
{
    protected validated = false;

    get isValid() { return this.validated; }

    state: S

    constructor(props: P)
    {
        super(props);

        console.log(props.id);
        RWAT.getInstance().addSection(props.id, this);

        // @ts-ignore
        this.state = {
            answers: RWAT.getInstance().loadSectionAnswers(props.id) ?? new Array<any>(this.props.questions.length).fill(undefined)
        }

        console.log(this.state.answers);
        this.triggerEvents();
    }

    setQuestionAnswer(index: number, value: any)
    {
        let answers = this.state.answers;

        answers[index] = value;

        console.debug(answers);

        // this.triggerEvents();

        this.setState({ answers }, () => {
            this.triggerEvents();
        });
        RWAT.getInstance().saveSectionAnswers(this.props.id, answers);
    }

    getQuestionAnswers()
    {
        return this.state.answers;
    }

    validate(): string
    {
        if(!this.props.disabled && this.getQuestionAnswers().includes(undefined))
        {
            this.validated = false;
            return "All questions must be answered.";
        }
        
        this.validated = true;
        return '';
    }

    triggerEvents()
    {
        let {answers} = this.state;
        let initialValid = this.validated;

        this.validate();

        if(this.props.onChange) this.props.onChange(answers);
        if(this.props.onValid && this.validated) this.props.onValid(answers);
        if(this.props.onInvalid && !this.validated) this.props.onInvalid(answers);
        if(this.props.onValidChange && initialValid !== this.validated) this.props.onValidChange(answers);
    }

    getHeader()
    {
        return (
            <IonText color="dark">
                <Rtif boolean={this.props.name != null && this.props.name !== ""}>
                    <h1>{this.props.name}</h1>
                </Rtif>
                <Rtif boolean={this.props.prompt != null && this.props.prompt !== ""}>
                    <p>{this.props.prompt}</p>
                </Rtif>
            </IonText>
        );
    }

    abstract encodeAnswers(): string

    abstract render(): JSX.Element
}

export default SurveySection;