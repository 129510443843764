import { IonImg, IonSlide, IonCard, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState } from 'react';

import { IonIcon } from "@ionic/react";

import './RewardTrackItem.css';

const stampURI = "../assets/" + "ClaimedStamp.png";

export interface RewardTrackItemProps
{
    id: number,
    earned: boolean,
    text: any,
}

const RewardTrackItem: React.FC<RewardTrackItemProps> = (props: RewardTrackItemProps) => {

    function renderUnearned() {
        return (
                <IonCard className="unearnedRewardCard">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" className={"unearnedRewardCardColumn"}>
                                <h2>{props.text}</h2>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>
        );
    }

    function renderClaimed() {
        return (
            <IonCard className="claimedRewardCard">
                <IonGrid>
                    <IonRow>
                        <IonCol size="12" className={"unearnedRewardCardColumn"}>
                            <h2>{props.text}</h2>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
        );
    }


    let toReturn = renderUnearned();

    if (props.earned)
        toReturn = renderClaimed();

    return(toReturn)
}

export default RewardTrackItem;
