import { IonText } from "@ionic/react";
import React from "react";
import SchedulingLog from "./SchedulingLogInterface";
import WeeklyScheduleLog from "./WeeklyScheduleLog";

const WeeklyScheduleLogList: React.FC<{data:SchedulingLog[]}> = ({data,children}) => {
    // const items:ReactElement = 
    // )
  
    return(
      <div>
          <IonText class="ion-text-center">
              <h1>Week 1</h1>
          </IonText>
        {data.map((log,index) => 
          (<WeeklyScheduleLog key={index} data={log}></WeeklyScheduleLog>)
        )}
      </div>
    )
  }
  
  export default WeeklyScheduleLogList;