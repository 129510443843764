import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPage, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import { build, heart, people, personAdd, repeat, chevronForward, checkmark, chevronUp, chevronDown } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import "./CaseConcept.css";
import NIJAPI, { KeysSessions, SessionListResponse, FiveKeys, SessionLog } from '../../NIJAPI';
import { EmptySessionList } from '../../sampleObjects/sampleSessionList';

import idMap from '../../sessions/idMap.json';
import getSessionInfo from '../../sessions/sessionMap';

// Meta info about each key for this menu
const fiveKeyMeta: { [key in FiveKeys] } = {
    "Meaningful Work Trajectories": {icon: <img src={'../assets/icon/MeaningfulWorkTrajectoriesIcon.svg'} style={{ maxHeight: 50, maxWidth: 50, padding: 5}}  alt={'Meaningful Work Trajectory Icon'}/>, description: "Meaningful Work Trajectories consists of promoting compatibility between an individual’s goals and abilities and the demands of that individual’s occupation is sustainable. Compatibility is defined as a state in which two things are able to exist or occur together without problems or conflict. Occupation is defined as obligation(s) / job paid or unpaid. Sustainable is defined as able to be maintained or kept going, as an action or process."},
    "Effective Coping Strategies": {icon: <img src={'../assets/icon/EffectiveCopingStrategiesIcon.svg'} style={{ maxHeight: 50, maxWidth: 50, padding: 5}}  alt={'Effective Coping Strategies Icon'}/>, description: "Effective Coping Strategies are defined as adaptive behavioral and psychological efforts taken to manage and reduce internal/external stressors in ways that are not harmful in the short or long-term. "},
    "Positive Social Engagement": {icon: <img src={'../assets/icon/PositiveSocialEngagementIcon.svg'} style={{ maxHeight: 50, maxWidth: 50, padding: 5}}  alt={'Positive Social Engagement Icon'}/>, description: "Positive Social Engagement help to encourage an individual to engage in social experiences organized for beneficial social purposes that directly or indirectly involve others, engaged in during discretionary time, and experienced as enjoyable."},
    "Healthy Thinking Patterns": {icon: <img src={'../assets/icon/HealthyThinkingPatternsIcon.svg'} style={{ maxHeight: 50, maxWidth: 50, padding: 5}}  alt={'Healthy Thinking Patterns Icon'}/>, description: "Healthy Thinking Patterns are defined by adaptive mental actions or processes, the presence of empathy, and the acceptance or internalization of values and norms that promote pro-social behavior. Pro-social behavior is defined as actions that are intended to benefit another individual, groups of individuals, or society as a whole."},
    "Positive Relationships": {icon: <img src={'../assets/icon/PositiveRelationshipsIcon.svg'} style={{ maxHeight: 50, maxWidth: 50, padding: 5}}  alt={'Positive Relationships Icon'}/>, description: "Positive Interpersonal Relationships promote association between two people that occurs in person and can range in duration from brief to enduring within formal or informal social contexts. The relationship is reliable, mutually beneficial, and enhances psychological well-being."}
};

// Returns number of sessions in a session log with status PENDING
const sessionsToDo = (log: SessionLog) => {
    return log.sessionList.filter(v => v.status === "PENDING").length;
};

const CaseConcept: React.FC = () => {
    const [keys, setKeys] = useState<SessionListResponse>(EmptySessionList);
    const [expand, setExpand] = useState<{ [key in FiveKeys]: boolean }>({
        "Meaningful Work Trajectories":  true,
        "Effective Coping Strategies": true,
        "Positive Social Engagement": true,
        "Healthy Thinking Patterns": true,
        "Positive Relationships": true
    });
    const [presentLoad, dismissLoad] = useIonLoading();

    useEffect(() => {
        presentLoad({
            message: "Loading sessions...",
            duration: 3000
        });
        // Fetch session list from backend
        NIJAPI.instance.getSessionList()
            .then(v => {
                setKeys(v);
                dismissLoad();
            }, e => {
                console.error(e);
                dismissLoad();
            });
    }, [dismissLoad, presentLoad]);

    console.log(build)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/fivekeys" />
                    </IonButtons>

                    <IonTitle>
                        Case Conceptualization
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonList>
                    {
                        keys?.sessionLogList.map(v => {
                            const toDo = sessionsToDo(v);
                            return (
                                <>
                                    <IonItem key={v.key + 0} className="key-item" button lines="none"
                                        onClick={e => {
                                            e.preventDefault();

                                            let exp = { ...expand };
                                            exp[v.key] = !exp[v.key];
                                            setExpand(exp);
                                        }}>
                                        {/*<IonIcon icon={fiveKeyMeta[v.key].icon} slot="start" />*/}
                                        {fiveKeyMeta[v.key].icon}
                                        <IonLabel>
                                            {v.key}
                                        </IonLabel>
                                        {toDo > 0 &&
                                        <IonNote color="success">
                                            {sessionsToDo(v)} sessions to do
                                        </IonNote>}

                                        <IonIcon icon={expand[v.key] ? chevronUp : chevronDown} slot="end" />
                                    </IonItem>
                                    <IonItem key={v.key + 1} className="key-description">
                                        {fiveKeyMeta[v.key].description}
                                    </IonItem>
                                    {   expand[v.key] && v.sessionList.length > 0 &&
                                        <IonItem key={v.key + 2} className="key-sessions">
                                            <IonList>
                                                {v.sessionList.map(session => (
                                                    <IonItem key={`s${session.sessionId}-${session.sessionLogId}`} button routerLink={`/fivekeys/session/${session.sessionId}?logId=${session.sessionLogId}&status=${session.status}`}>
                                                        <IonLabel color={session.status !== "PENDING" ? "medium" : undefined}>
                                                            {session.sessionTitle}
                                                        </IonLabel>

                                                        {session.status !== "PENDING" &&
                                                        <IonIcon slot="end" color="success" icon={checkmark} />}
                                                        <IonIcon slot="end" icon={chevronForward} />
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                        </IonItem>
                                    }
                                </>
                            );
                        })
                    }

                    {
                        process.env.REACT_APP_GOALS_DEV === '1' &&
                        <>
                            <IonItem>
                                <IonLabel>[DEBUG] All Sessions [DEBUG]</IonLabel>
                                This should only appear in development builds! If you see this in production, please report it.
                                Sessions started from under this header will NOT submit!
                            </IonItem>
                            {
                                Object.keys(idMap).map(id =>
                                <IonItem key={"dbgSession" + id} button routerLink={`/fivekeys/session/${id}`}>
                                    <IonLabel>{getSessionInfo(id).title}</IonLabel>
                                </IonItem>)
                            }
                        </>
                    }
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default CaseConcept;
