import { IonBackButton, IonButton, IonButtons, IonCheckbox, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonNote, IonPage, IonTextarea, IonTitle, IonToolbar, useIonModal, useIonPicker, useIonToast } from '@ionic/react';
import { addYears, format, parse } from 'date-fns';
import { add, arrowBack, send } from 'ionicons/icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { BlankEvent, CalendarEvent, CalendarEventType, ChecklistItem, Meeting, MeetingType } from '../../components/calendar/CalendarTypes';
import NIJAPI, { NIJMeeting } from '../../NIJAPI';
import { BlankMeeting } from '../../sampleObjects/sampleMeetings';
import './EventScheduler.css';


const eventTypeDisplayNames = {
    zoom: 'Zoom',
    "in-person": "In-Person",
    phone: "Phone"
};

const meetingTypeDisplayNames: { [K in MeetingType]: string } = {
    meeting: 'In-person',
    call: 'Phone',
    zoom: 'Zoom',
    duo: 'Duo',
    skype: 'Skype',
    other: 'Other'
};

// Modal which allows the creation of checklists
const ChecklistModal: React.FC<{
    checklist: ChecklistItem[]
    onDismiss: () => void,
    updateEvent: <T extends keyof CalendarEvent>(field: T, newVal: CalendarEvent[T]) => void
}> = ({checklist, onDismiss, updateEvent}) => {
    // Quick update checklist using given updateEvent function
    const updateChecklist = <T extends keyof ChecklistItem>(index: number, field: T, newVal: ChecklistItem[T]) => {
        if(index >= checklist.length)
        {
            console.debug(`Skipping checklist update, index ${index} out of bounds (${checklist.length})`);
            return;
        }

        let newChecklist = [...checklist];
        newChecklist[index][field] = newVal;
        updateEvent('checklist', newChecklist);
    };

    const listRef = useRef<HTMLIonListElement>(null);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Checklist</IonTitle>

                    <IonButtons slot="start">
                        <IonButton onClick={onDismiss} shape="round">
                            <IonIcon icon={arrowBack} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList ref={listRef}>
                    {checklist.map((v,i) => {
                        return (
                            <IonItemSliding key={i}>
                                <IonItem>
                                    <IonInput placeholder="Task" value={v.name} onIonChange={e => updateChecklist(i, 'name', e.detail.value!)} />
                                    <IonCheckbox slot="end" checked={v.completed} onIonChange={e => updateChecklist(i, 'completed', e.detail.checked!)} />
                                </IonItem>

                                <IonItemOptions side="end" onIonSwipe={e => {
                                        listRef.current?.closeSlidingItems();
                                        let newChecklist = [...checklist];
                                        newChecklist.splice(i, 1);
                                        updateEvent('checklist', newChecklist);
                                }}>
                                    <IonItemOption expandable color="danger">
                                        Swipe to delete
                                    </IonItemOption>
                                </IonItemOptions>
                            </IonItemSliding>
                        );
                    })}
                    <IonItem lines="none" button onClick={e => {
                        let newChecklist = [...checklist];
                        newChecklist.push({
                            id: checklist.length.toString(),
                            completed: false,
                            name: ""
                        });
                        updateEvent('checklist', newChecklist);
                    }}>
                        <IonIcon slot="start" icon={add} />
                        <IonLabel>Add Item</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

// Modal which allows the creation of meetings
const MeetingsModal: React.FC<{
    meetings: Meeting[]
    onDismiss: () => void,
    updateEvent: <T extends keyof CalendarEvent>(field: T, newVal: CalendarEvent[T]) => void
}> = ({meetings, onDismiss, updateEvent}) => {
    // Quick update meeting using given updateEvent function
    const updateMeetings = <T extends keyof Meeting>(index: number, field: T, newVal: Meeting[T]) => {
        if(index >= meetings.length)
        {
            console.debug(`Skipping meetings update, index ${index} out of bounds (${meetings.length})`);
            return;
        }

        let newMeetings = [...meetings];
        newMeetings[index][field] = newVal;
        updateEvent('meetings', newMeetings);
    };

    const listRef = useRef<HTMLIonListElement>(null);

    // Picker definition for choosing meeting type
    const [present] = useIonPicker();
    const presentMeetingTypePicker = useCallback((index: number) => {
        present({
            columns: [
                {
                    name: 'meetingType',
                    // Automatically populate options with defined event display names
                    options: Object.keys(meetingTypeDisplayNames).map(v => {
                        return { text: meetingTypeDisplayNames[v], value: v }
                    })
                }
            ],
            buttons: [
                {
                    text: 'Confirm',
                    handler: selected => {
                        updateMeetings(index, 'type', selected.meetingType.value);
                    }
                }
            ]
        });
    }, [meetings]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Meeting</IonTitle>

                    <IonButtons slot="start">
                        <IonButton onClick={onDismiss} shape="round">
                            <IonIcon icon={arrowBack} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList ref={listRef}>
                    {meetings.map((v,i) => {
                        return (
                            <IonItemSliding key={i}>
                                <IonItem>
                                    <IonLabel position="stacked" />
                                    <IonItem lines="none" style={{ width: '100%' }}>
                                        <IonInput placeholder="Name" value={v.name} onIonChange={e => updateMeetings(i, 'name', e.detail.value!)} />
                                        <IonDatetime
                                            placeholder="Select Time"
                                            displayFormat="h:mma"
                                            value={v.time ? parse(v.time, 'HH:mm:ss', new Date()).toISOString() : undefined}
                                            onIonChange={e => {
                                                const date = new Date(e.detail.value!);
                                                updateMeetings(i, 'time', format(date, 'HH:mm:ss'));
                                            }}
                                        />
                                    </IonItem>
                                    <IonItem lines="none" style={{ width: '100%' }}>
                                        <IonInput readonly value={meetingTypeDisplayNames[v.type]} onClick={e => presentMeetingTypePicker(i)} />
                                        <IonInput
                                            className="ion-text-end"
                                            placeholder="Location/Contact Info"
                                            value={v.location}
                                            onIonChange={e => updateMeetings(i, 'location', e.detail.value!)}
                                        />
                                    </IonItem>
                                </IonItem>

                                <IonItemOptions side="end" onIonSwipe={e => {
                                        listRef.current?.closeSlidingItems();
                                        let newMeeting = [...meetings];
                                        newMeeting.splice(i, 1);
                                        updateEvent('meetings', newMeeting);
                                }}>
                                    <IonItemOption expandable color="danger">
                                        Swipe to delete
                                    </IonItemOption>
                                </IonItemOptions>
                            </IonItemSliding>
                        );
                    })}
                    <IonItem lines="none" button onClick={e => {
                        let newMeeting = [...meetings];
                        newMeeting.push({
                            id: meetings.length.toString(),
                            name: "",
                            type: 'meeting',
                            location: "",
                            time: ""
                        });
                        updateEvent('meetings', newMeeting);
                    }}>
                        <IonIcon slot="start" icon={add} />
                        <IonLabel>Add Item</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

const EventScheduler: React.FC<{ defaultEvent?: NIJMeeting }> = ({ defaultEvent }) => {
    const [ newEvent, setNewEvent ] = useState<NIJMeeting>(defaultEvent || BlankMeeting);
    const [ date, setDate ] = useState<string>();
    const [ time, setTime ] = useState<string>();
    const [ endDate, setEndDate ] = useState<string>();
    const [ endTime, setEndTime ] = useState<string>();

    const [present] = useIonPicker();

    const history = useHistory();

    const [toast, dismissToast] = useIonToast();

    // Quick update a parameter of our event
    const updateEvent = <T extends keyof NIJMeeting>(field: T, newVal: NIJMeeting[T]) => {
        setNewEvent(prev => {
            let tempEvent = { ... prev };
            tempEvent[field] = newVal;
            return tempEvent;
        });
    };

    // Set up checklist creation modal
    // const handleDismissChecklistModal = () => {
    //     dismissChecklistModal();
    // }
    // const [presentChecklistModal, dismissChecklistModal] = useIonModal(ChecklistModal, {
    //     checklist: newEvent.checklist,
    //     onDismiss: handleDismissChecklistModal,
    //     updateEvent: updateEvent
    // });

    // Set up meetings creation modal
    // const handleDismissMeetingsModal = () => {
    //     dismissMeetingsModal();
    // }
    // const [presentMeetingsModal, dismissMeetingsModal] = useIonModal(MeetingsModal, {
    //     meetings: newEvent.meetings,
    //     onDismiss: handleDismissMeetingsModal,
    //     updateEvent: updateEvent
    // });

    // Picker definition for choosing event type
    const presentEventTypePicker = useCallback(() => {
        present({
            columns: [
                {
                    name: 'eventType',
                    // Automatically populate options with defined event display names
                    options: Object.keys(eventTypeDisplayNames).map(v => {
                        return { text: eventTypeDisplayNames[v], value: v }
                    })
                }
            ],
            buttons: [
                {
                    text: 'Confirm',
                    handler: selected => {
                        updateEvent('type', selected.eventType.value);
                    }
                }
            ]
        });
    }, []);

    // Update timestamp when date or time is changed
    useEffect(() => {
        if(!date || !time) return;

        let combined = [date?.split("T")[0], time?.split("T")[1]].join("T");
        let d = new Date(combined);
        console.log(date, time, combined, d.toISOString());
        updateEvent('start_time', d.toISOString());
    }, [date, time]);
    
    useEffect(() => {
        if(!endDate || !endTime) return;

        let combined = [endDate?.split("T")[0], endTime?.split("T")[1]].join("T");
        let d = new Date(combined);
        console.log(endDate, endTime, combined, d.toISOString());
        updateEvent('end_time', d.toISOString());
    }, [endDate, endTime]);

    return (
        <IonPage id="event-scheduler">
            <IonHeader slot="fixed">
                <IonToolbar>
                    <IonTitle>
                        New Event
                    </IonTitle>

                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/calendar" />
                    </IonButtons>

                    <IonButtons slot="end">
                        <IonButton
                            shape="round"
                            fill="clear"
                            onClick={async e => {
                                newEvent.participantID = NIJAPI.instance.userId.toString();
                                newEvent.id = undefined;

                                console.info("Pushing new event");
                                console.log(newEvent);

                                NIJAPI.instance.createMeeting(newEvent)
                                    .then(v => {
                                        console.log(v);

                                        toast("Event created successfully", 3000);
                                        history.goBack();
                                    })
                                    .catch(e => {
                                        console.error(e);

                                        toast("Error creating event", 3000);
                                    });
                            }}
                        >
                            <IonIcon icon={send} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen slot="fixed">
                <IonItem lines="full">
                    <IonLabel slot="start">Title</IonLabel>
                    <IonInput
                        required
                        className="ion-text-end"
                        placeholder="Title"
                        value={newEvent.title}
                        onIonChange={e => {
                            updateEvent('title', e.detail.value!);
                        }}
                    />
                </IonItem>
                {/* <IonItem lines="full">
                    <IonLabel slot="start">Required</IonLabel>
                    <IonCheckbox
                        slot="end"
                        checked={newEvent.required}
                        onIonChange={e => {
                            updateEvent('required', e.detail.checked);
                        }}
                    />
                </IonItem> */}
                <IonItem lines="full">
                    <IonLabel slot="start">Start Date</IonLabel>
                    <IonDatetime
                        slot="end"
                        placeholder="Date"
                        displayFormat="MM/DD/YY"
                        min={format(new Date(), 'yyyy-MM-dd')}
                        max={addYears(new Date(), 1).getFullYear().toString()}
                        value={date}
                        onIonChange={e => {
                            console.log(e.detail.value);
                            setDate(e.detail.value!);
                        }}
                    />
                </IonItem>
                <IonItem lines="full">
                    <IonLabel slot="start">Start Time</IonLabel>
                    <IonDatetime
                        slot="end"
                        placeholder="Time"
                        displayFormat="h:mma"
                        value={time}
                        onIonChange={e => {
                            console.log(e.detail.value);
                            setTime(e.detail.value!);
                        }}
                    />
                </IonItem>
                <IonItem lines="full">
                    <IonLabel slot="start">End Date</IonLabel>
                    <IonDatetime
                        slot="end"
                        placeholder={"Date"}
                        displayFormat="MM/DD/YY"
                        min={format(new Date(), 'yyyy-MM-dd')}
                        max={addYears(new Date(), 1).getFullYear().toString()}
                        value={endDate}
                        onIonChange={e => {
                            console.log(e.detail.value);
                            setEndDate(e.detail.value!);
                        }}
                    />
                </IonItem>
                <IonItem lines="full">
                    <IonLabel slot="start">End Time</IonLabel>
                    <IonDatetime
                        slot="end"
                        placeholder={"Time"}
                        displayFormat="h:mma"
                        value={endTime}
                        onIonChange={e => {
                            console.log(e.detail.value);
                            setEndTime(e.detail.value!);
                        }}
                    />
                </IonItem>
                {/* <IonItem lines="full">
                    <IonLabel slot="start">Case Worker</IonLabel>
                    <IonInput
                        required
                        className="ion-text-end"
                        placeholder="Case Worker"
                        value={newEvent.caseWorker}
                        onIonChange={e => {
                            updateEvent('caseWorker', e.detail.value!);
                        }}
                    />
                </IonItem> */}
                <IonItem button lines="full" id="scheduler-type-input" onClick={presentEventTypePicker}>
                    <IonLabel slot="start">Event Type</IonLabel>
                    <IonInput className="ion-text-end" readonly slot="end" value={eventTypeDisplayNames[newEvent.type]} />
                </IonItem>
                {/* <IonItem lines="full">
                    <IonLabel slot="start">Location</IonLabel>
                    <IonInput
                        required
                        className="ion-text-end"
                        placeholder="Location"
                        value={newEvent.location}
                        onIonChange={e => {
                            updateEvent('location', e.detail.value!);
                        }}
                    />
                </IonItem> */}
                {/* <IonItem button detail lines="full" onClick={e => presentChecklistModal()}>
                    <IonLabel slot="start">Checklist</IonLabel>
                    <IonNote slot="end">{newEvent.checklist.length}</IonNote>
                </IonItem>
                <IonItem button detail lines="full" onClick={e => presentMeetingsModal()}>
                    <IonLabel slot="start">Meetings</IonLabel>
                    <IonNote slot="end">{newEvent.meetings.length}</IonNote>
                </IonItem> */}
                <IonItem lines="full">
                    <IonLabel position="stacked">Description</IonLabel>
                    <IonTextarea
                        placeholder="Description"
                        value={newEvent.purpose}
                        rows={6}
                        onIonChange={e => {
                            updateEvent('purpose', e.detail.value!);
                        }}
                    />
                </IonItem>
            </IonContent>
        </IonPage>
    );
}

export default EventScheduler;