import { IonIcon, IonItem, IonLabel } from "@ionic/react"
import { call } from "ionicons/icons"
import React from "react"
import { Call } from "./Call"

const CallItem:React.FC<{data:Call}> = ({data}) => {

  function statusColor(status:string):string {
    if (status === "missed"){
      return "danger"
    }

    else if(status === "answered"){
      return "success"
    }

    else{
      return "primary"
    }
  }

    if(data.callStatus === "missed"){
      return(
        <IonItem > 
        <IonLabel>
          Missed Call with {data.name}
          <p>Topic: {data.topic}</p>
          <p>To: {data.receiver} </p>
          <p>From: {data.sender} </p>
        </IonLabel>
        <IonIcon src={call} color={statusColor(data.callStatus)} slot="start" style={{color:"blue"}}></IonIcon>
      </IonItem>
    )
    }

    else{
      return(
        <IonItem > 
        <IonLabel>
          Call with {data.name}
          <p>Topic: {data.topic}</p>
          <p>To: {data.receiver} </p>
          <p>From: {data.sender} </p>
        </IonLabel>
        <IonIcon src={call} color={statusColor(data.callStatus)} slot="start" style={{color:"blue"}}></IonIcon>
      </IonItem>
    )
    }
    
}

export default CallItem;