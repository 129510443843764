import React from 'react';
import { bulb, cog, hammer, leaf, logoUsd, boat, musicalNote, water, heart, stopCircle } from 'ionicons/icons';
import { IonIcon, IonText } from '@ionic/react';
import './RewardIcon.css';

const styling = {
    "hammer": {
        icon: hammer
    },
    "gear": {
        icon: cog
    },
    "leaf": {
        icon: leaf
    },
    "lightbulb": {
        icon: bulb
    },
    "COIN": {
        icon: logoUsd
    },
    "boat" : {
        icon: boat
    },
    "music" : {
        icon: musicalNote
    },
    "water" : {
        icon: water
    },
    "heart": {
        icon: heart
    },
    "stop" : {
        icon: stopCircle
    }

}

interface RewardIconProps {
    type: string; //"hammer" | "gear" | "leaf" | "lightbulb" | "COIN" | "boat" | "music" | "water";
    amount?: number;
}

const RewardIcon: React.FC<RewardIconProps> = (props) => {

    return (
        <div className={`reward-icon ${props.type}`} >
            <IonIcon icon={styling[props.type].icon} size="large" />
            
            {props.amount &&  
                <IonText>
                    {props.amount}
                </IonText>  
            }
        </div>
    );
};

export default RewardIcon;