import { IonLabel, IonList, IonListHeader } from "@ionic/react"
import React from "react"
import Checklist from "./ChecklistInterface"
import ChecklistItem from "./ChecklistItem"

const ChecklistList: React.FC<{data:Checklist[]}> = ({data}) => {

    if (data.length != 0){
      return(
        <IonList inset>
            <IonListHeader>
              <IonLabel>
                <h1>Checklist</h1>
                </IonLabel>
            </IonListHeader>
          {data.map((checklist,index) => 
            (<ChecklistItem key={index} data={checklist}></ChecklistItem>)
          )}
        </IonList>
      )
    }

    else{
      return(
        <div></div>
      )
    }
    
      
  }

  export default ChecklistList