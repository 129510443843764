import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { hammerOutline, calendarOutline, homeOutline, bookOutline, keyOutline } from 'ionicons/icons';
import Tab3 from './pages/Tab3';
import Tab4 from './pages/Tab4';
import Tab6 from './pages/Tab6';
import Login from './pages/Login';
import Tab3health from './pages/Tab3health';
import Tab3search from './pages/Tab3search';
import SchedulerRouters from './pages/scheduler/SchedulerRouters';
import Home from './pages/rwat/Home';
import GOLHome from './pages/Home';
import Prizes from './pages/gamification/Prizes';
import Tasks from './pages/gamification/Tasks';

import NIJAPI from './NIJAPI';
import { OldGamificationData as GamificationData, GameContext, GameContextProvider } from './gamification/gamification';
import { Task, UserTask } from './gamification/GOALS';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';




/* Theme variables */
import './theme/variables.css';
import { LocalNotificationDescriptor, LocalNotifications } from '@capacitor/local-notifications';
import FiveKeysMenu from './pages/fivekey/FiveKeysMenu';
import PrimaryAssessments from './pages/fivekey/PrimaryAssessments';
import CaseConcept from './pages/fivekey/CaseConcept';
import TTSSessionMenu from './pages/fivekey/TTSSession';
import OverviewMenu from './components/overview/Menu';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from '@sentry/tracing';
import ResourceViewer from './pages/library/ResourceViewer';
import CustomPDFViewer from './components/library/CustomPDFViewer';
import { initializeApp } from 'firebase/app';
import { FirebaseConfig } from './firebase_config';
import { BackgroundGeolocationPlugin } from '@capacitor-community/background-geolocation';
import { registerPlugin } from '@capacitor/core';
import axios from 'axios';
import useUpdateChecker from './hooks/useUpdateChecker';
import { App } from '@capacitor/app';
import Native from './plugins/NativePlugin';
const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");

const app = initializeApp(FirebaseConfig);
let notifications: LocalNotificationDescriptor[] = [];

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://3f53c74346254c82a222bf4b02349d24@o1242712.ingest.sentry.io/6406939",

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: "nij-goals-app@" + process.env.REACT_APP_GOALS_VERSION,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV

  });
}


// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  Sentry.setExtra('error', error)
  Sentry.captureException(error);
  return Promise.reject(error);
});


const dummyData: GamificationData = {
  points: 310,
  pointHistory: [50, 160, 130, 145, 150, 100],
  taskHistory: [[{
    id: 7,
    userId: 1,
    task: {
      taskId: 4,
      taskName: "Visit a Job Site",
      taskDescription: "Visit your workplace",
      rewardType: "hammer",
      rewardAmount: 25
    },
    progress: 0,
    startTime: null
  },
  {
    id: 100,
    userId: 1,
    task: {
      taskId: 100,
      taskName: "Visited a Liquor Store",
      taskDescription: "Entered ABC Beverages at 8:32PM",
      rewardType: "lightbulb",
      rewardAmount: -10
    },
    progress: 0,
    startTime: null
  },
  {
    id: 101,
    userId: 1,
    task: {
      taskId: 101,
      taskName: "Complete RWAT",
      taskDescription: "Finished RWAT",
      rewardType: "COIN",
      rewardAmount: 35
    },
    progress: 0,
    startTime: null
  }]]
};

// Empty default data
const emptyData: GamificationData = {
  points: 0,
  pointHistory: [],
  taskHistory: []
}

function toObj(str) {
  return JSON.parse(JSON.stringify(str));
}


function authenticate() {
  return NIJAPI.instance.authenticate('ethan', '1234')
    .then((result) => {
      console.log("Login successful");
      return result;
    }, console.error);
}

function TestStatus() {
  return NIJAPI.instance.fetchStatus()
    .then((result) => console.log("Coin test: " + result['coinAmount']), console.error);
}

function TestTaskList() {
  return NIJAPI.instance.fetchTaskList()
    .then((result) => console.log("Task list fetch test: " + JSON.stringify(result)), console.error);
}

const MyApp: React.FC = () => {
  const [gameData, setGameData] = useState<GamificationData>(emptyData);
  const routerRef = useRef<IonReactRouter>(null);

  const { updateGameState, points } = useContext(GameContext);

  const his = useHistory();
  const [isError] = useUpdateChecker(process.env.REACT_APP_GOALS_VERSION!)

  // Run once
  useEffect(function () {

    LocalNotifications.addListener('localNotificationActionPerformed', ((action) => {
      console.log(action.actionId)
    }))

    App.addListener('appStateChange', ({ isActive }) => {

      if (!isActive) {
        console.log('app is now inactive')


        const url = window.location.href

        console.log(window.location.href)
        console.log(routerRef.current?.history)
        console.log(url)

        if (url!.includes("fivekeys/primary") || url!.includes("/fivekeys/case") || url!.includes("/fivekeys/session")) {
          Native.startSessionTimeout()
        }



      } else {
        console.log('app is now active')
      }
    })

    // if (isPlatform('capacitor')) {
    //   LocalNotifications.createChannel({
    //     id: 'goals-warning',
    //     name: 'GOALS Warnings',
    //     description: 'Warnings from GOALS app, e.g. nearing restriction zones',
    //     importance: 4,
    //     visibility: -1,
    //     sound: 'holdon.mp3',
    //     vibration: true
    //   });

    //   // Set up geolocation features

    //   BackgroundGeolocation.addWatcher({
    //     distanceFilter: 50,
    //     backgroundTitle: 'Checking your area',
    //     backgroundMessage: 'GOALS is informing you of nearby restrictions',
    //     requestPermissions: true
    //   }, function callback(location, error) {
    //     // Triggered when location change is detected
    //     console.info(`Location update`, location);

    //     if (error) {
    //       // Check if location permission must be granted
    //       if (error.code === "NOT_AUTHORIZED") {
    //         if (window.confirm("GOALS requires permission to warn you of nearby restriction zones.\n\nOpen settings now?"))
    //           BackgroundGeolocation.openSettings();
    //       }

    //       return console.error(error);
    //     }

    //     if (!location)
    //       return;

    //     // if(NIJAPI.instance.authenticated)
    //     NIJAPI.instance.pushLocationData(location.latitude, location.longitude, 100)
    //       .then(async (response) => {
    //         console.log(response);

    //         // Check if any violations have been detected
    //         if (response.gViolationCount > 0 || response.sViolationCount > 0) {
    //           // Create a notification warning of restriction zones
    //           notifications = (await LocalNotifications.schedule({
    //             notifications: [
    //               {
    //                 id: 1337,
    //                 channelId: 'goals-warning',
    //                 title: 'Near restricted area!',
    //                 body: 'Tap to see restriction zones'
    //               }
    //             ]
    //           })).notifications;
    //         }
    //         else {
    //           // Clear all location warnings if there are no current violations
    //           LocalNotifications.cancel({
    //             notifications: [{ id: 1337 }]
    //           });
    //         }
    //       });
    //   }).then(id => {
    //     // Remove watcher when it is no longer needed
    //     console.log(id);
    //     // BackgroundGeolocation.removeWatcher({id: id});
    //   });
    // }

    LocalNotifications.addListener('localNotificationActionPerformed', action => {
      if (action.actionId === "tap" && action.notification.id === 1337) // Handle tapping restriction zone warning
        routerRef.current?.history.push('/location');
      if (action.actionId === "tap" && action.notification.id === 5) // Handle tapping restriction zone warning
        routerRef.current?.history.push('/fivekeys');
    });

    // Sentry.captureMessage("My message")
  }, []);

  return (
    <IonApp>
      <IonReactRouter ref={routerRef}>

        <IonTabs>

          <IonRouterOutlet id="main">
            <GameContextProvider>
              <Route path="/home" exact={true}>
                <GOLHome />
              </Route>
              {/* <Route path="/calendar" component={Tab2} exact={true} /> */}
              <Route path="/taskswork" component={Tab3} />
              <Route path="/library" component={Tab4} />
              <Route path="/location" component={Tab6} />
              <Route path="/login" component={Login} />
              <Route path='/taskshealth' component={Tab3health} />
              {/*<Route path='/tasks' exact={true} >
                <Tasks />
              </Route>*/}
              <Route path='/jobs' component={Tab3search} />
              <Route path='/rwat' component={Home} />
              <Route path='/prizes' exact={true} >
                <Prizes />
              </Route>
              <Route path='/fivekeys' component={FiveKeysMenu} exact={true} />
              <Route path='/fivekeys/primary' component={PrimaryAssessments} exact={true} />
              <Route path='/fivekeys/case' component={CaseConcept} exact={true} />
              <Route path='/fivekeys/session/:sessionId' component={TTSSessionMenu} exact={true} />

              <SchedulerRouters />

              <Route path="/" render={() => <Redirect to={NIJAPI.instance.authenticated ? "/home" : "/login"} />} exact />

              <Route path='/library2' component={ResourceViewer} exact={true} />
              <Route exact path="/resource/:id">
                <CustomPDFViewer />
              </Route>
            </GameContextProvider>
          </IonRouterOutlet>

          <IonTabBar>
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={homeOutline} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tasks" href="/tasks">
              <IonIcon icon={hammerOutline} />
              <IonLabel>Tasks</IonLabel>
            </IonTabButton>
            <IonTabButton tab="calendar" href="/calendar">
              <IonIcon icon={calendarOutline} />
              <IonLabel>Schedule</IonLabel>
            </IonTabButton>
          </IonTabBar>


        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default MyApp;