// import { setUser } from "@sentry/react"
import axios from "axios"
import { getAuth } from "firebase/auth"
import { useEffect, useState } from "react"

const useAuth = () => {

    interface User {
        id: string,
        token: string
    }

    const [userId, setUserId] = useState<string | null>(null)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [token, setToken] = useState<string>("")
    const [userCredentials,setUserCredentials] = useState<User | null>(null)
    useEffect(() => {

        const auth = getAuth()

        auth.onAuthStateChanged(async (user) => {

            if (user != null) {
                console.log(user.getIdToken())
                axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
                let userInfo: User = { id:user.uid,token: await user.getIdToken()}
                setUserCredentials(userInfo)
                setUserId(user.uid)
            }

        })

        auth.onIdTokenChanged(async (user) => {
            if (user != null) {
                console.log(await user.getIdToken())
                axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
                let userInfo: User = { id:user.uid,token: await user.getIdToken()}
                setUserCredentials(userInfo)
                setUserId(user.uid)
            }

        })

        // do {
        //     setInterval(() => {console.log('verifying auth')},5000)
        // } while (userId === null);
        console.log(auth.currentUser)
        if (auth.currentUser != null) {
            setLoggedIn(true)
            setUserId(auth.currentUser.uid)
            // let userInfo: User = { id:auth.currentUser.uid,token: await auth.currentUser.getIdToken()}
            //     setUserCredentials(userInfo)
        }


    }, [userId, loggedIn, setUserId,token])

    return [userId, setUserId, loggedIn,userCredentials] as const;
}

export default useAuth;