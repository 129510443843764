import { IonLabel, IonList, IonListHeader } from "@ionic/react";
import React from "react";
import { Call } from "./Call";
import CallItem from "./CallItem";

const CallItemList:React.FC<{data:Call[]}> = ({data}) => {

    return(
        <IonList inset>
          <IonListHeader>
            <IonLabel>
              <h1>
                Calls
              </h1>
            </IonLabel>
            </IonListHeader>
            {data.map((call,index) => {
                return(
                    <CallItem key={index} data={call}></CallItem>
                )
            })}
            {/* <IonItem > 
              <IonLabel>
                Missed Call with Case Worker 
                <p>Topic: Check-In</p>
              </IonLabel>
              <IonIcon src={call} color="danger" slot="start" style={{color:"blue"}}></IonIcon>
            </IonItem>
            <IonItem > 
              <IonLabel>
                Call with Case Worker
                <p>Topic: Check-In</p> 
              </IonLabel>
              <IonIcon src={call} color="success" slot="start"></IonIcon>
            </IonItem>
            <IonItem > 
              <IonLabel>
                Call with AAA Sponsor
                <p>Topic: Check-In</p>
              </IonLabel>
              <IonIcon src={call} color="success" slot="start"></IonIcon>
            </IonItem>    */}
</IonList>
    )
}

export default CallItemList;

