import React from 'react';
import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { briefcaseOutline, calendarClearOutline, chatboxOutline, videocam, callOutline, flag } from 'ionicons/icons'
import { EventAvailableOutlined, EventBusyOutlined, HourglassEmptyOutlined } from '@material-ui/icons';
import SchedulingLog from '../scheduler/SchedulingLogInterface';
import Checklist from '../scheduler/ChecklistInterface';
import { Meeting } from '../scheduler/Meeting';
import { Call } from '../scheduler/Call';
import { CalendarEvent } from './CalendarTypes'
import { NIJMeeting } from '../../NIJAPI';
import './EventItem.css';

interface EventItemProps {
    event: NIJMeeting
}

export function getEventIcon(event: CalendarEvent | NIJMeeting) {
    switch (event.type) {
        case 'phone':
            return callOutline;
        case 'zoom':
            return videocam;
        case 'in-person':
        case 'meeting':
            return chatboxOutline;
        case 'job':
            return briefcaseOutline;
        default:
            return calendarClearOutline;
    }
}

export function getStatusIcon(event: CalendarEvent) {

    switch (event.approval) {
        case 'approved':
            return <EventAvailableOutlined className="calendarEventApprovalIcon" />;
        case 'pending':
            return <HourglassEmptyOutlined className="calendarEventApprovalIcon" />;
        case 'rejected':
            return <EventBusyOutlined className="calendarEventApprovalIcon" />;
    }
}

export function getNIJStatusIcon(event: NIJMeeting) {
    if (event.approved)
        return <EventAvailableOutlined className="calendarEventApprovalIcon" />;
    return <HourglassEmptyOutlined className="calendarEventApprovalIcon" />
}

const EventItem: React.FC<EventItemProps> = ({ event }) => {
    const date = new Date(event.start_time);
    return (
        <IonItem button lines="none" className={`calendarEvent ${event.approved ? "" : "eventunapproved"} ${event.required ? "eventrequired" : "eventoptional"} event${event.type}`} routerLink={`/calendar/event/${event.id}`}>
            <IonIcon className="calendarEventTypeIcon" icon={calendarClearOutline} slot="start" />

            <IonLabel>
                <IonText className="calendarEventName">
                    {event.title}
                </IonText>
                <br />
                <IonText className="calendarEventSub">
                    {/*at {date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}{event.type.length > 0 ? `, ${event.type}` : ''}  <br />*/}
                    Scheduled by {event.sender} <br />
                    {/*Ends at {new Date(event.end_time).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}*/}
                </IonText>
            </IonLabel>

            {/*{event.required &&
                <div slot="end">
                    <IonIcon icon={flag} className="eventRequiredFlag" />
                </div>
            }*/}

            {/* { !event.approved &&
            <div slot="end">
                <HourglassEmptyOutlined className="calendarEventApprovalIcon" />
            </div>
            } */}
        </IonItem>
    );
}

export default EventItem;