import { Task } from "../NIJAPI";

export const sampleTasks: Task[] = [
    {
        "id": 8,
        "userId": 1,
        "task": {
            "taskId": 7,
            "taskName": "Complete RWAT",
            "taskDescription": "Complete the Reentry Well-Being Assessment Tool",
            "rewardType": "COIN",
            "rewardAmount": 30
        },
        "progress": 0,
        "startTime": null
    },
    {
        "id": 1,
        "userId": 1,
        "task": {
            "taskId": 2,
            "taskName": "Self Reflection",
            "taskDescription": "Self reflection is an important step to build up self-awareness",
            "rewardType": "gear",
            "rewardAmount": 5
        },
        "progress": 40,
        "startTime": null
    },
    {
        "id": 2,
        "userId": 1,
        "task": {
            "taskId": 3,
            "taskName": "Check Job Posting",
            "taskDescription": "Let's start our journey by checking the very first job posting!",
            "rewardType": "hammer",
            "rewardAmount": 10
        },
        "progress": 0,
        "startTime": null
    },
    {
        "id": 6,
        "userId": 1,
        "task": {
            "taskId": 1,
            "taskName": "Fill Self Assessment Form",
            "taskDescription": "Fill the self assessment form so we can recommend jobs to you!",
            "rewardType": "hammer",
            "rewardAmount": 20
        },
        "progress": 0,
        "startTime": "2020-08-20T19:34:53.081624"
    },
    {
        "id": 7,
        "userId": 1,
        "task": {
            "taskId": 4,
            "taskName": "Visit a Job Site",
            "taskDescription": "Visit your workplace",
            "rewardType": "hammer",
            "rewardAmount": 25
        },
        "progress": 0,
        "startTime": "2020-08-20T19:34:53.081624"
    },
    {
        "id": 7,
        "userId": 1,
        "task": {
            "taskId": 5,
            "taskName": "Stay on Track!",
            "taskDescription": "You'll gain resources for every day you stay on track.",
            "rewardType": "lightbulb",
            "rewardAmount": 15
        },
        "progress": 25,
        "startTime": "2020-08-20T19:34:53.081624"
    },
    {
        "id": 8,
        "userId": 1,
        "task": {
            "taskId": 6,
            "taskName": "View Community Resources",
            "taskDescription": "Check out resources available in your community",
            "rewardType": "leaf",
            "rewardAmount": 10
        },
        "progress": 0,
        "startTime": "2020-08-20T19:34:53.081624"
    },
    {
        "id": 9,
        "userId": 1,
        "task": {
            "taskId": 7,
            "taskName": "Meditate",
            "taskDescription": "Meditate for 3 minutes",
            "rewardType": "gear",
            "rewardAmount": 25
        },
        "progress": 0,
        "startTime": null
    },
    {
        "id": 10,
        "userId": 1,
        "task": {
            "taskId": 8,
            "taskName": "Exercise",
            "taskDescription": "Exercise for 20 minutes",
            "rewardType": "gear",
            "rewardAmount": 35
        },
        "progress": 0,
        "startTime": null
    }
];