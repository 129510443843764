import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonNote, IonPage, IonTitle, IonToolbar, IonButton, IonIcon, IonSpinner, useIonToast } from '@ionic/react';
import React, { useState, useEffect } from 'react';

import "./PrimaryAssessments.css";
import NIJAPI, { PrimaryAssessment } from '../../NIJAPI';
import Native from '../../plugins/NativePlugin';
import { syncOutline } from 'ionicons/icons';

interface AssessmentStatus {
    status: "done" | "pending",
    time: Date
};

interface Assessment {
    name: string,
    description: string,
    routerLink?: string,
    status: AssessmentStatus
};

const StatusNote: React.FC<{status: PrimaryAssessment}> = ({status}) => {
    if(status.status !== "PENDING")
        return (
            <IonNote color="success" slot="end">
                Done
            </IonNote>
        );
    
    const date = new Date(status.meetingStartTime * 1000)

    return (
        <IonNote color="warning" slot="end">
            Pending <br />
            {status.meetingStartTime &&
            <>
                {date.toLocaleDateString()} <br />
                {date.toLocaleTimeString(undefined,{ hour: '2-digit', minute: '2-digit' })}
            </>
            }
        </IonNote>
    );
}

const PrimaryAssessments: React.FC = () => {
    const [items, setItems] = useState<PrimaryAssessment[]>([]); //([{
    //     "id": 47,
    //     "participantId": 5,
    //     "assessmentType": "RWAT",
    //     "status": "PENDING",
    //     "meetingId": 1111111,
    //     "meetingURL": "https://fsu.zoom.us/j/94855873091",
    //     "meetingStartTime": 1643635099000,
    //     "meetingDuration": "30 min",
    //     "actualEndTime": null
    // }, {
    //     "id": 48,
    //     "participantId": 5,
    //     "assessmentType": "Biopsychosocial Assessment",
    //     "status": "PENDING",
    //     "meetingId": 1111111,
    //     "meetingURL": "https://fsu.zoom.us/j/94855873091",
    //     "meetingStartTime": 1643641736000,
    //     "meetingDuration": "30 min",
    //     "actualEndTime": null
    // }]);
    const [noItems, setNoItems] = useState(false);
    const [toast] = useIonToast();

    const refresh = React.useCallback(() => {
        NIJAPI.instance.getPrimaryAssessmentList()
            .then(v => {
                setItems(v.primaryAssessmentLogList);
                setNoItems(v.primaryAssessmentLogList.length === 0);
            }, e => {
                console.error('Primary Assessment List Error', e);
                toast({
                    message: `Error fetching primary assessments: "${e}"`,
                    duration: 3000
                });
                setNoItems(true);
            });
    }, [toast]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    // Build page contents
    const assessments = React.useMemo(() => {
        if(items.length === 0 || noItems) {
            return (
                <div style={{
                    position: 'relative',
                    top: '50%',
                    width: '100%',
                    textAlign: 'center',
                    transform: 'translateY(-50%)',
                    color: 'darkgrey',
                    fontSize: '24px'
                }}>
                    { noItems ? "No Primary Assessments" : "Loading Primary Assessments..." }
                    <div style={{ fontSize: '64px', padding: '8px' }}>
                        {
                            noItems ?
                            <IonButton
                                fill="clear"
                                shape="round"
                                onClick={refresh}
                                size="large"
                                style={{
                                    color: 'darkgrey'
                                }}
                            >
                                <IonIcon icon={syncOutline} slot="icon-only" />
                            </IonButton> :
                            <IonSpinner color="medium" style={{ width: '64px', height: '64px' }} />
                        }
                    </div>
                    
                </div>
            );
        }

        return (
            <IonList>
                {
                    items.map((v, i) => 
                        <IonItem
                            key={v.assessmentType + v.id}
                            button
                            routerLink={v.assessmentType === "RWAT" ? '/rwat' : v.meetingId ? `/calendar/event/${v.meetingId}` : undefined}
                            className="assessment-item"
                            onClick={e => {
                                // Handle Zoom meetings
                                // if(v.meetingURL != null) {
                                //     e.preventDefault();
                                //     console.log(`Starting Zoom meeting for ${v.assessmentType}`)
                                //     Native.startZoom({ token: v.meetingURL });
                                // }
                            }}
                        >
                            <IonLabel>
                                <b className="assessment-title">{v.assessmentType}</b>
                            </IonLabel>

                            <StatusNote status={v} />
                        </IonItem>
                    )
                }
            </IonList>);
    }, [items, noItems, refresh]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/fivekeys" />
                    </IonButtons>

                    <IonTitle>
                        Primary Assessments
                    </IonTitle>

                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            shape="round"
                            onClick={refresh}
                        >
                            <IonIcon icon={syncOutline} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                { assessments }
            </IonContent>
        </IonPage>
    );
};

export default PrimaryAssessments;