import SurveySection from './components/rwat/SurveySection';
import { Storage } from '@capacitor/storage';

export type RWATAnswers = {
    participantId?: number,
    cogFlexInven: string,
    empathy: string,
    hope: string,
    coping: string,
    family: string,
    community: string,
    work: string,
    friends: string,
    cloestPerson: string,
    mostTimePerson: string,
    worth: string,
    timeUsage: string,
    comAndLeisure: string,
    workTraj: string
}

export const debugAnswers: RWATAnswers = {
    "cogFlexInven":"12354324123746273645",
    "empathy":"3410234013240123",
    "hope":"143212341231",
    "coping":"1423142342431423412432414231",
    "family":"people1(x,y)1-people2(x,y)2-people3(x,y)1",
    "community":"people1(x,y)1-people2(x,y)1",
    "work":"people1(x,y)2-people2(x,y)3-people3(x,y)0-people4(x,y)2",
    "friends":"people1(x,y)1",
    
    "cloestPerson":"relation-firstname-123341",
    "mostTimePerson":"relation-firstname-324231",
    
    "worth":"1422",
    "timeUsage":"sleeping:210:520-school:30:50-paid_work:120:360-household_chores:330:120-community_activities:250:110-recreational_activities:10:20-commuting:50:10-personal_relationships:25:50-health_and_self_maintenance:50:100-spiritual:20:40-other1:120:60-other2:20:10",
    
    "comAndLeisure":"134200421324121343",
    "workTraj":"2234123421234"
};

const simpleSections = ["cogFlexInven", "empathy", "hope", "coping", "family", "community", "work", "friends", "worth", "timeUsage", "comAndLeisure", "workTraj"];

export default class RWAT
{
    private static instance: RWAT;
    private sections: { [id: string]: SurveySection };
    private savedAnswers: { [id: string]: any };
    private encodedAnswers: { [id: string]: string };

    private constructor()
    {
        this.sections = {};
        this.savedAnswers = {};
        this.encodedAnswers = {};
    }

    addSection(id: string, section: SurveySection)
    {
        this.sections[id] = section;
    }

    getSection(id: string)
    {
        return this.sections[id];
    }

    getIDs()
    {
        return Object.keys(this.sections);
    }

    // Returns an empty string if all section IDs given resolve to a known & validated section
    // Otherwise, returns a string containing all errors reported.
    validatePage(ids: string[])
    {
        console.log(this.sections);
        let err = '';
        // console.debug(ids);
        for (const i of ids) {
            // console.debug(this.sections[i]);
            if(this.sections[i] == undefined) return 'An error occurred when loading the page.';
            let valid = this.sections[i].validate();
            // console.debug(valid);
            if(valid !== '' && !err.includes(valid)) err += valid + '\n';
        }

        return err;
    }

    tryGetAnswers(id: string): string
    {
        console.log("tryget", id);
        let sec = this.getSection(id);
        if(!sec) return "";

        return sec.encodeAnswers();
    }

    storeAnswers()
    {
        console.log(this.savedAnswers);
        return Storage.set({
            key: "rwatAnswers",
            value: JSON.stringify(this.savedAnswers)
        });
    }

    async loadAnswers()
    {
        try {
            const astr = (await Storage.get({ key: "rwatAnswers" })).value;
            if(!astr) return Promise.resolve("No saved responses");
            const aobj: { [id: string]: any } = JSON.parse(astr);
            if(!aobj) return Promise.reject("Could not parse saved response.");

            this.savedAnswers = aobj;
            console.log(this.savedAnswers);
            return Promise.resolve("Responses loaded!");    
        }
        catch(err) {
            return Promise.reject(err);
        }
    }

    saveSectionAnswers(id: string, answers: any)
    {
        console.log(id, answers);
        this.savedAnswers[id] = answers;
        this.storeAnswers();
    }

    loadSectionAnswers(id: string)
    {
        console.log(id, this.savedAnswers[id]);
        return this.savedAnswers[id];
    }

    encodeAnswers(): RWATAnswers
    {
        // return debugAnswers;
        console.log(this.sections);
        var answers = {};

        // Encode all sections that can be directly encoded
        for (const it of simpleSections)
            answers[it] = this.tryGetAnswers(it);

        // Some questions utilize multiple sections, and so need to be combined to the proper format
        answers["cloestPerson"] = `${this.tryGetAnswers("closestPerson_1")}-${this.tryGetAnswers("closestPerson_2")}`;
        if(this.tryGetAnswers("mostTimePerson_0") === "1")
            answers["mostTimePerson"] = "";
        else
            answers["mostTimePerson"] = `${this.tryGetAnswers("mostTimePerson_1")}-${this.tryGetAnswers("mostTimePerson_2")}`;

        return answers as RWATAnswers;
    }

    public static getInstance()
    {
        if(!RWAT.instance) RWAT.instance = new RWAT();
        return RWAT.instance;
    }
}