import { IonAlert, IonButton, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { helpOutline, newspaperOutline, videocamOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import './Tab4.css';



const slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoHeight: true,

};

const Tab4: React.FC = () => {

    const [searchText, setSearchText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [text, setText] = useState<string>();
    const [text2, setText2] = useState<string>();
    const [text3, setText3] = useState<string>();
    const [text4, setText4] = useState<string>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Library</IonTitle>
        </IonToolbar>
      </IonHeader>
          <IonContent className="backgroundColor">
       
              
              <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} color="light"></IonSearchbar>
              <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)} value="today">

                  <IonSegmentButton onClick={() => {
                      //@ts-ignore
                      document.getElementById("tab4slides").slideTo(0);
                  }} value="today">
                      <IonLabel>Recent</IonLabel>
                  </IonSegmentButton>

                  <IonSegmentButton onClick={() => {
                      //@ts-ignore
                      document.getElementById("tab4slides").slideTo(1);
                  }} value="all">
                      <IonLabel>Featured</IonLabel>
                  </IonSegmentButton>
              </IonSegment>

              <IonSlides id="tab4slides" options={slideOpts}>
                  <IonSlide>
                      <div className="slide1tab4">

                          <IonButton onClick={() => setShowModal(true)} expand='block' color='light' className="taskButtons">Interview Practice Tips <IonIcon className="iconPic" icon={videocamOutline} /></IonButton>
                          

                          
                          <IonButton onClick={() => setShowModal2(true)} expand='block' color='light' className="taskButtons">Dealing With Stress <IonIcon className="iconPic" icon={newspaperOutline} /></IonButton>
                         
                          <IonButton onClick={() => setShowModal3(true)} expand='block' color='light' className="taskButtons">Quiz: What is your learning style? <IonIcon className="iconPic" icon={helpOutline} /></IonButton>
                          
                         
                      </div>

                  </IonSlide>
                  <IonSlide>
                      <div className="slide1">

                          <IonButton onClick={() => setShowModal(true)} expand='block' color='light' className="taskButtons">Interview Practice Tips <IonIcon className="iconPic" icon={videocamOutline} /></IonButton>



                          <IonButton onClick={() => setShowModal2(true)} expand='block' color='light' className="taskButtons">Dealing With Stress <IonIcon className="iconPic" icon={newspaperOutline} /></IonButton>

                          <IonButton onClick={() => setShowModal3(true)} expand='block' color='light' className="taskButtons">Quiz: What is your learning style? <IonIcon className="iconPic" icon={helpOutline} /></IonButton>


                      </div>
                  </IonSlide>
              </IonSlides>

              <IonModal isOpen={showModal3} cssClass='my-custom-class'>
                  <h1 className="missionCategory">What is your learning style?</h1>
                  <IonList>

                      <IonItemDivider>What was your least favorite subject in school?</IonItemDivider>
                      <IonItem>
                          <IonInput value={text} placeholder="Enter Input" onIonChange={e => setText(e.detail.value!)} clearInput></IonInput>
                      </IonItem>
                      <IonItemDivider>Do you use subtitles when you watch television?</IonItemDivider>
                      <IonItem>
                          <IonInput value={text2} placeholder="Enter Input" onIonChange={e => setText2(e.detail.value!)} clearInput></IonInput>
                      </IonItem>
                      <IonItemDivider>Do you like audiobooks?</IonItemDivider>
                      <IonItem>
                          <IonInput value={text3} placeholder="Enter Input" onIonChange={e => setText3(e.detail.value!)} clearInput></IonInput>
                      </IonItem>
                      <IonItemDivider>Do you wear glasses?</IonItemDivider>
                      <IonItem>
                          <IonInput value={text4} placeholder="Enter Input" onIonChange={e => setText4(e.detail.value!)} clearInput></IonInput>
                      </IonItem>

                  </IonList>
                  <IonButton color="success" onClick={() => setShowAlert1(true)}>Submit</IonButton>
                  <IonAlert
                      isOpen={showAlert1}
                      onDidDismiss={() => {
                          setShowAlert1(false);
                          setShowModal3(false);
                      }}
                      cssClass='my-custom-class'
                      header={'Good Job!'}

                      message={'You are a visual learner!'}
                      buttons={['Confirm']}
                  />
                  <IonButton onClick={() => setShowModal3(false)} fill="clear">Close</IonButton>
              </IonModal>

              <IonModal isOpen={showModal2} cssClass='my-custom-class'>
                  <h1 className="missionCategory">Dealing With Stress</h1>
                  
                  <a className="missionCategory" href="https://www.skillsyouneed.com/ps/stress-tips.html">Click here</a>
                 
                  <IonButton onClick={() => setShowModal2(false)} fill="clear">Close</IonButton>
              </IonModal>

              <IonModal isOpen={showModal} cssClass='my-custom-class'>
                  <h1 className="missionCategory">Interview Practice Tips</h1>
                  <a className="missionCategory" href="https://www.youtube.com/watch?v=srw4r3htm4U">Click here</a>
                  <IonButton onClick={() => setShowModal(false)} fill="clear">Close</IonButton>
              </IonModal>
              <p><br/></p>
              <div className="categoryButtons">

                  {/* <IonButton size="large" expand='block' color='light' className="taskButtons">Parolee Law</IonButton> */}
                  <IonButton size="large" expand='block' color="light" className="taskButtons">The Five Keys</IonButton>
                  <IonButton size="large" expand='block' color='light' routerLink="/location" className="taskButtons">Location</IonButton>

               </div>
                
          </IonContent>
         
    </IonPage>
  );
};

export default Tab4;
