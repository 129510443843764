import { GamificationData } from "./gamification";
import { UserTask } from "./GOALS";

interface GameActionData
{
    finishTask: { id?: number },
    taskProgress: { id?: number, percentage: number },
    gpsViolation: { violationDate: Date }
}

export const CompletedStates = ["COMPLETED", "REWARD_CLAIMED"];

export type GameFlag = keyof GameActionData;

export interface GameAction<T extends GameFlag>
{
    type: T,
    data: GameActionData[T]
}

export function taskPoints(task: UserTask)
{
    return task.rewardAmt1 + task.rewardAmt2 + task.rewardAmt3 + task.rewardAmt4 + task.rewardAmt5;
}

// Handle game actions such as starting and finishing a task, then return any resulting state mutations
export function handleGameFlag<T extends GameFlag>(state: GamificationData, action: GameAction<T>): Partial<GamificationData> | null
{
    switch(action.type)
    {
        case "finishTask": {
            const data = action.data as GameActionData['finishTask'];
            // If no ID is provided, assume that we're finishing the active task.
            const id = data.id ?? state.activeTask?.id ?? null;
            if(!id)
            {
                console.trace('Got finishTask event with no ID and no task is active! Skipping...');
                return null;
            }

            // Check if any matching task exists
            const removedTaskInd = state.tasks.findIndex(v => v.id === id);
            if(removedTaskInd === -1)
            {
                console.info(`Got finishTask(${id}), but no task with id ${id} was found!`);
                return null;
            }
            const removedTask = state.tasks[removedTaskInd];

            const mutations: Partial<GamificationData> = {};

            // Remove any tasks matching ID
            mutations.tasks = state.tasks.filter(v => v.id !== id);

            // Award points
            // TODO: Remove this if/when the backend is handling points
            const addedPoints = removedTask.rewardAmt1;
            mutations.points = state.points + addedPoints;

            // Add levels
            mutations.level = state.level + addedPoints / 100;

            // Remove active task if its ID matches
            if(state.activeTask?.id === id)
                mutations.activeTask = undefined;

            // TODO: Add API calls to complete task

            // Add task info to history
            mutations.pointHistory = [ ... state.pointHistory ];
            if(mutations.pointHistory.length < 1) mutations.pointHistory.push(addedPoints);
            else mutations.pointHistory[0] += addedPoints;

            mutations.taskHistory = [ ... state.taskHistory ];
            if(mutations.taskHistory.length < 1) mutations.taskHistory.push([ removedTask ]);
            else mutations.taskHistory[0].push(removedTask);

            return mutations;
        }
        case "taskProgress": {
            const data = action.data as GameActionData['taskProgress'];
            // If no ID is provided, assume that we're finishing the active task.
            const id = data.id ?? state.activeTask?.id ?? null;
            if(!id)
            {
                console.trace('Got taskProgress event with no ID and no task is active! Skipping...');
                return null;
            }

            // Check if any matching task exists
            const taskInd = state.tasks.findIndex(v => v.id === id);
            if(taskInd === -1)
            {
                console.info(`Got taskProgress(${id}, ...), but no task with id ${id} was found!`);
                return null;
            }
            const task = state.tasks[taskInd];

            // Copy current task list to mutation
            const mutations: Partial<GamificationData> = {
                tasks: [...state.tasks]
            };

            // Update task percentage (clamped to be between 0 and 100)
            if(data.percentage < 0 || data.percentage > 100)
                console.warn(`Got taskProgress with percentage of ${data.percentage}, value will be clamped to [0,100]`);
            task.progress = Math.max(Math.min(data.percentage, 100), 0);

            // Update partial point value
            // if(task.point.maxvalue)
            //     task.point.partialValue = Math.floor(task.point.maxvalue * (task.completion.percentage / 100));

            // Update mutated task list with our updated task
            mutations.tasks![taskInd] = task;

            return mutations;
        }
        default:
            break;
    }

    return null;
}