import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonPage, IonRow, IonText, IonTextarea, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { calendarOutline, callOutline, cameraOutline, createOutline, sendOutline, trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import Checklist from '../../components/scheduler/ChecklistInterface';
import ChecklistList from '../../components/scheduler/ChecklistList';
import SchedulingLog from '../../components/scheduler/SchedulingLogInterface';
import { getWeeday, statusColor } from '../../utilities';
import './Tab1.css';

const Tab1: React.FC = () => {

  const [items,setItems] = useState<Checklist[]>([])
  const [log,setLog] = useState<SchedulingLog>({id:0,day:"NA",caseWorker:"NA",dueTime:"NA",date:"NA",description:"NA",status:"NA",month:"NA",year:0,checklistCount:0})



  useIonViewWillEnter(()=> {
    if (log.id == 0){
      let newLog:SchedulingLog = {id:0,day:"NA",caseWorker:"NA",dueTime:"NA",date:"NA",description:"NA",status:"NA",month:"NA",year:0,checklistCount:0}
    }
    
  })
  
  const [editMode,setEditMode] = useState<boolean>(false)
  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton/>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={createOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={sendOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={trashOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          
        </IonToolbar>
      </IonHeader>
      <IonContent >
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem lines="none">
                <IonText>{log.day} {log.month} {log.year} </IonText>
              </IonItem>
                
            </IonCol>

            <IonCol>
            <IonItem lines="none" color={statusColor(log.status)}>
              <IonText>{log.status}</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
            <IonItem lines="none">
                <IonText>{getWeeday(log.date)}</IonText>
              </IonItem>
            </IonCol>
            <IonCol>
            <IonItem lines="none">
                <IonText>2:55pm</IonText>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItemDivider class="ion-margin-bottom"></IonItemDivider>

        <IonItem lines="none">
        <IonTextarea class="ion-padding" placeholder="Start editing your log...">
        
        </IonTextarea>
        </IonItem>

        <IonList inset>
          <IonListHeader>
            <IonLabel>
              <h1>Checklist</h1>
              </IonLabel>
          </IonListHeader>
          <ChecklistList data={items}></ChecklistList>
        </IonList>

        <IonList inset>
          <IonListHeader>
            <IonLabel>
              <h1>
                Calls
              </h1>
            </IonLabel>
            </IonListHeader>
            <IonItem > 
              <IonLabel>
                Missed Call with Case Worker 
              </IonLabel>
              <IonIcon src={callOutline} slot="start"></IonIcon>
            </IonItem>
            <IonItem > 
              <IonLabel>
                Call with Case Worker 
              </IonLabel>
              <IonIcon src={callOutline} slot="start"></IonIcon>
            </IonItem>
            <IonItem > 
              <IonLabel>
                Call with AAA Sponsor 
              </IonLabel>
              <IonIcon src={callOutline} slot="start"></IonIcon>
            </IonItem>
            
            
          
        </IonList>

        <IonList inset>
          <IonListHeader>
            <IonLabel>
              <h1>
                Meetings
              </h1>
            </IonLabel>
            </IonListHeader>
            <IonItem > 
              <IonLabel>
                Job Interview 
              </IonLabel>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
            <IonItem > 
              <IonLabel>
                Call with Case Worker 
              </IonLabel>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
            <IonItem > 
              <IonLabel>
                Call with AAA Sponsor 
              </IonLabel>
              <IonIcon src={calendarOutline} slot="start"></IonIcon>
            </IonItem>
            
            
          
        </IonList>
        
      </IonContent>

      <IonFooter>
        <IonToolbar >

          <IonButtons slot="start">
            <IonButton >
              <IonIcon icon={cameraOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start">
            <IonButton >
              <IonIcon icon={sendOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={trashOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton >
              <IonIcon icon={callOutline}></IonIcon>
            </IonButton>
          </IonButtons>
          
        </IonToolbar>
        </IonFooter>
    </IonPage>
  );
};

export default Tab1;
