import { IonButton, IonCard, IonCardTitle, IonCol, IonContent, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonText, IonToast, useIonToast, IonFooter } from '@ionic/react';
import { callOutline, mailOutline, closeOutline, eyeOutline, eyeOffOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
//import { setIsLoggedIn, setUsername } from '../data/user/user.actions';
//import { connect } from '../data/connect';
import { RouteComponentProps, useHistory } from 'react-router';
import { GameContext } from '../gamification/gamification';
import NIJAPI from '../NIJAPI';
import { AppContext } from '../State';
import './Login.css';
import { getAuth } from 'firebase/auth/dist/auth';
import package_json from '../../package.json';

interface OwnProps extends RouteComponentProps { }

const devVer = process.env.REACT_APP_GOALS_DEV === '1'

const Login: React.FC = () => {

    const [username, setUsername] = useState(devVer ? 'nijuser4@gmail.com' : '');
    const [password, setPassword] = useState(devVer ? '45678910' : '');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [present, dismiss] = useIonToast();
    const [showPass, setShowPass] = useState(false);

    const { state, dispatch } = useContext(AppContext);
    const { updateGameState } = useContext(GameContext);

    const history = useHistory();

    const api = NIJAPI.instance;

    const login = async (e: React.FormEvent) => {
        e.preventDefault();

        setFormSubmitted(true);
        if (!username) {
            setUsernameError(true);
        }
        if (!password) {
            setPasswordError(true);
        }

        if (!username || !password) return;

        api.authenticate(username, password).then((r) => {
            present({
                message: 'Successfully logged in!',
                buttons: [{ text: 'Dismiss', handler: () => dismiss() }],
                duration: 3000
            });

            dispatch({
                type: 'setNameOfUser',
                nameOfUser: username
            });

            updateGameState({
                username: username,
                userId: api.userId
            });

            // Clear form
            setUsernameError(false);
            setPasswordError(false);

            setUsername('');
            setPassword('');
        }).catch((e: string) => {
            present({
                message: `Login failed: ${e}`,
                buttons: [{ text: 'Dismiss', handler: () => dismiss() }],
                duration: 3000
            });
        });
    };

    React.useEffect(() => {
        const id = NIJAPI.instance.on('auth', () => {
            history.push('/home');
        });

        return () => NIJAPI.instance.cancel(id);
    }, [history]);

    return (
        <IonPage id="login-page">
            <IonContent className="backgroundColor">
                <div className="loginContent">
                    {/* <IonImg className="appTitle" src="../assets/goals.png" />
                <p><br/></p> */}
                    <IonImg className="logo" src="../assets/logo/vector/cropped.svg" />
                    <IonCard>
                        <form noValidate onSubmit={login}>
                            <IonList>
                                <IonItem>
                                    <IonLabel position="stacked" color="primary">Username</IonLabel>
                                    <IonInput
                                        name="username"
                                        type="text"
                                        inputmode="email"
                                        value={username}
                                        spellCheck={false}
                                        autocapitalize="off"
                                        onIonChange={e => setUsername(e.detail.value!)}
                                        clearInput
                                        required>
                                    </IonInput>
                                </IonItem>

                                {formSubmitted && usernameError && <IonText color="danger">
                                    <p className="ion-padding-start">
                                        Username is required
                                    </p>
                                </IonText>}

                                <IonItem>
                                    <IonLabel position="stacked" color="primary">Password</IonLabel>
                                    <IonInput
                                        name="password"
                                        type={showPass ? "text" : "password"}
                                        value={password}
                                        onIonChange={e => setPassword(e.detail.value!)}
                                        clearInput
                                    >
                                    </IonInput>
                                    <IonButton
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            transform: 'translateY(-50%)'
                                        }}
                                        slot="end"
                                        shape="round"
                                        fill="clear"
                                        onClick={e => setShowPass(p => !p)}
                                    >
                                        <IonIcon slot="icon-only" icon={!showPass ? eyeOffOutline : eyeOutline} />
                                    </IonButton>
                                </IonItem>

                                {formSubmitted && passwordError && <IonText color="danger">
                                    <p className="ion-padding-start">
                                        Password is required
                                    </p>
                                </IonText>}
                            </IonList>

                            <IonRow>
                                <IonCol>
                                    <IonButton type="submit" expand="block">Login</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton onClick={() => setShowModal(true)} color="light" expand="block">Help</IonButton>
                                </IonCol>
                            </IonRow>
                        </form>
                    </IonCard>

                    <IonModal isOpen={showModal} cssClass='my-custom-class'>
                        <h1 className="missionCategory">Help</h1>
                        <IonImg className="houseImg" src="../assets/questionmark.png" />
                        <IonCard>
                            <IonCardTitle className="helpText">Tech Support</IonCardTitle>
                            <p className="helpText"><IonIcon icon={mailOutline} />spepper@fsu.edu</p>
                            <p className="helpText"><IonIcon icon={callOutline} />3172150825</p>
                        </IonCard>

                        <IonButton onClick={() => setShowModal(false)} fill="clear">Close</IonButton>
                    </IonModal>

                    {/* Temporary means of providing feedback for login success/failure */}
                    {/* <IonToast
                    isOpen={ toastText !== "" }
                    onDidDismiss={ () => setToast("")}
                    message={toastText}
                    duration={3000}
                    position="bottom" 
                /> */}
                </div>

            </IonContent>

            <IonFooter>
                <IonText style={{
                    display: 'block',
                    position: 'relative',
                    right: '16px',
                    textAlign: 'right'
                }}>
                    App version v{package_json.version} {process.env.REACT_APP_GOALS_DEV == '1' ? "dev" : ""}
                </IonText>
            </IonFooter>

        </IonPage>
    );
};

export default Login;