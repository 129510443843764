import React, { useEffect } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonGrid, IonRow, IonCol, IonItem, IonList, IonLabel, IonIcon } from '@ionic/react'
import NIJAPI from '../../NIJAPI';
import { GameContext } from '../../gamification/gamification';
import { UserTask } from '../../gamification/GOALS';

import './TaskOverview.css';
import { logoUsd } from 'ionicons/icons';

function sortTasks(a: UserTask, b: UserTask) {
	// Put mandatory and limited tasks in front of all others
	if (a.isMandatory !== b.isMandatory)
		return (a.isMandatory ? -1 : 1);
	if (a.isLimited !== b.isLimited)
		return (a.isLimited ? -1 : 1);

	// Sort limited tasks based on their end times
	if (a.isLimited && b.isLimited && b.limitedTo !== a.limitedTo)
		return b.limitedTo - a.limitedTo;

	// Sort tasks by current progress
	if (a.hasProgress && b.hasProgress && a.progress !== b.progress)
		return b.progress - a.progress;

	// Sort by create time otherwise
	return b.createTime - a.createTime;
}

function filterTasks(task: UserTask) {
	if (task.status in ["COMPLETED", "REWARD_CLAIMED", "DELETED", "FAILED"]) return false;
	if (task.isLimited && task.limitedTo < new Date().valueOf()) return false;
	return true;
}

export function filterCompleted24hrs(task: UserTask) {
	if (!task.completionTime) return false;
	// Returns true if the time since completion is <= 24hrs
	return (new Date().valueOf() - task.completionTime) <= 86400000;
}

function removeDuplicateTasks(arr: UserTask[], curr: UserTask): UserTask[] {
	if (!arr.find(v => v.title === curr.title)) arr.push(curr);
	return arr;
}

const TaskOverview: React.FC = () => {
	const { tasks, updateGameState } = React.useContext(GameContext);
	const sortedTasks = React.useMemo(() => [...tasks].reduce(removeDuplicateTasks, []).filter(filterTasks).sort(sortTasks), [tasks]);
	const completedTasks = React.useMemo(() => [...tasks].reduce(removeDuplicateTasks, []).filter(filterCompleted24hrs).sort(sortTasks), [tasks]);

	// Update task list on load
	React.useEffect(() => {
		NIJAPI.instance.fetchTaskList()
			.then(list => updateGameState({ tasks: list }))
			.catch(console.error);
	}, [updateGameState]);

	return (
		<IonCard>
			<IonCardHeader>
				<IonCardTitle>
					Tasks
				</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						<IonCol size="3" className="home-task-top-container">
							<div className="home-task-top">
								<h4>To Do</h4><p>{sortedTasks.length}</p>
							</div>
							<div className="home-task-top">
								<h4>Done Today</h4><p>{completedTasks.length}</p>
							</div>
						</IonCol>
						<IonCol size="1">
							<div className="vertical-divider" />
						</IonCol>
						<IonCol size="8">
							{sortedTasks.length > 0 ?
								<div className="home-task-list">
									{
										sortedTasks.slice(0, 3).map(task => {
											return (
												<div className="home-task-task" key={`ovtsk_${task.id}`}>
													<p>{task.title}</p>
													<div className="home-task-reward">
														{/* <IonIcon icon={logoUsd} /> */}
														{task.rewardAmt1}
													</div>
												</div>
											);
										})
									}
								</div>
								:
								<div className="home-task-notasks">
									No more tasks to do!
								</div>
							}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	)
};

export default TaskOverview;