import { SessionListResponse } from "../NIJAPI";

export const SampleSessionList: SessionListResponse = {
    "message": null,
    "sessionLogList": [
      {
        "key": "Meaningful Work Trajectories",
        "sessionList": [
          {
            "sessionId": "4",
            "sessionLogId": 1,
            "sessionTitle": "Communication Styles",
            "status": "PENDING"
          },
          {
            "sessionId": "5",
            "sessionLogId": 2,
            "sessionTitle": "Feelings, Thoughts, Mind Traps",
            "status": "PENDING"
          }
        ]
      },
      {
        "key": "Positive Relationships",
        "sessionList": [
          {
            "sessionId": "5",
            "sessionLogId": 3,
            "sessionTitle": "Feelings, Thoughts, Mind Traps",
            "status": "PENDING"
          },
          {
            "sessionId": "1",
            "sessionLogId": 4,
            "sessionTitle": "Communication Roadblocks",
            "status": "FINISHED"
          }
        ]
      },
      {
        "key": "Effective Coping Strategies",
        "sessionList": [
          {
            "sessionId": "3",
            "sessionLogId": 5,
            "sessionTitle": "The Aggression Cycle",
            "status": "PENDING"
          }
        ]
      },
      {
        "key": "Positive Social Engagement",
        "sessionList": []
      },
      {
        "key": "Healthy Thinking Patterns",
        "sessionList": []
      }
    ]
  };

  export const EmptySessionList: SessionListResponse = {
    "message": null,
    "sessionLogList": [
      {
        "key": "Meaningful Work Trajectories",
        "sessionList": []
      },
      {
        "key": "Positive Relationships",
        "sessionList": []
      },
      {
        "key": "Effective Coping Strategies",
        "sessionList": []
      },
      {
        "key": "Positive Social Engagement",
        "sessionList": []
      },
      {
        "key": "Healthy Thinking Patterns",
        "sessionList": []
      }
    ]
  };