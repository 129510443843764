import { IonAlert, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { addOutline, calendarOutline, todayOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import ScheduleLogList from '../../components/scheduler/ScheduleLogList';
import SchedulingLog from '../../components/scheduler/SchedulingLogInterface';
import WeeklyScheduleLogList from '../../components/scheduler/WeeklyScheduleLogList';
import NIJAPI from '../../NIJAPI';
import { getMonthName } from '../../utilities';
import './Tab2.css';

// TOOO:Switch out interface for class in SchedulingLog
//TODO:Fix brackets around data prop i ShceduleLog componet


const Tab2: React.FC = () => {

  // let logs: = []

  const [logs,setLogs] = useState<SchedulingLog[]>([])
  const [err,setErr] = useState<boolean>(false)
  const [viewType,setViewType] = useState<string>("daily")

  async function fetchChecklistCounts(updatedLogs:SchedulingLog[]) {

    function pad(number:number):string {
      if (number < 10) {
        return '0' + number.toFixed();
      }
      return number.toFixed();
    }

    // let updatedLogs:SchedulingLog[] = []
    let dateMap = new Map<string,number>();

    dateMap.set("2020-28-29",3)

    for (let index = 0; index < updatedLogs.length; index++) {
      const element = updatedLogs[index];
      let date:Date = new Date(element.date)
      let queryDate:string = `${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate()+1)}`
      // await Axios.get(`http://192.168.0.168:1337/scheduling-checklists/count?date=${queryDate}`).then((response) => {
      // await NIJAPI.instance.fetchChecklistCount(`?date=${queryDate}`).then((count) => {
      //   console.log(count);
      //   // let count:number = response.data;
      //   updatedLogs[index].checklistCount = count;
        
      //   }).catch((err) => {
      //     console.error(err);
      //   }).finally(() => {
          
      //   })
    }

    setLogs(updatedLogs)

    

    
  }

  function fetchScheduleLogs() {
    let emptyLogs:SchedulingLog[] = []
    // Axios.get('http://192.168.0.168:1337/scheduling-logs').then(async (response)=>{
    // NIJAPI.instance.fetchLogs().then(async (data) => {
    //   console.log(data);

    // // let data:[] = response.data;
    
    // for (let index = 0; index < data.length; index++) {
    //   const element:any = data[index];
    //   const date:Date = new Date(element.date)
    //   let schedulingLog:SchedulingLog = {
    //     id:element.id,
    //     dueTime:element.due_time,
    //     status:element.status,
    //     caseWorker:element.case_worker,
    //     description:element.description,
    //     month:getMonthName(element.date_logged),
    //     day:(new Date(element.date_logged).getDate() + 1).toFixed(),
    //     year:new Date(element.date_logged).getFullYear(),
    //     date:element.date_logged,
    //     checklistCount:0
    //   }

    //   emptyLogs.push(schedulingLog)
      
    // }

      
    //   fetchChecklistCounts(emptyLogs)
    // }).catch((err) => {
    //   console.error(err)
    //   setErr(true)
    // }).finally(()=>{
      
    // })

    
  }

  useIonViewWillEnter(() => {
    fetchScheduleLogs()
    
  });

  if (viewType == "daily"){
    return (
      
      <IonPage>
        <IonHeader>
          <IonToolbar>
          <IonTitle>My Logs</IonTitle>
          <IonButtons slot="end">
              <IonButton onClick={e => setViewType("daily")} >
                <IonIcon  icon={todayOutline}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={e => setViewType("weekly")}>
                <IonIcon icon={calendarOutline}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton >
                <IonIcon icon={calendarOutline}></IonIcon>
              </IonButton>
            </IonButtons>

            <IonButtons slot="end">
              <IonMenuButton autoHide={false}></IonMenuButton>
            </IonButtons>
            
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonAlert 
          isOpen={err}
          onDidDismiss={() => setErr(false)}
            cssClass='my-custom-class'
            header={'Alert'}
            subHeader={'Subtitle'}
            message={'This is an alert message.'}
            buttons={['OK']}
          ></IonAlert>
          {/* <ExploreContainer name="Tab 2 page" /> */}

          <ScheduleLogList data={logs}></ScheduleLogList>

            
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton routerLink="/tab3/0000-00-00">
              <IonIcon icon={addOutline}></IonIcon>
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
    );
  }

  else if(viewType == "weekly"){
    return (
      
      <IonPage>
        <IonHeader>
          <IonToolbar>
          <IonTitle>My Logs</IonTitle>
          <IonButtons slot="end">
              <IonButton onClick={e => setViewType("daily")} >
                <IonIcon  icon={todayOutline}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={e => setViewType("weekly")}>
                <IonIcon icon={calendarOutline}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton >
                <IonIcon icon={calendarOutline}></IonIcon>
              </IonButton>
            </IonButtons>

            <IonButtons slot="end">
              <IonMenuButton autoHide={false}></IonMenuButton>
            </IonButtons>
            
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonAlert 
          isOpen={err}
          onDidDismiss={() => setErr(false)}
            cssClass='my-custom-class'
            header={'Alert'}
            subHeader={'Subtitle'}
            message={'This is an alert message.'}
            buttons={['OK']}
          ></IonAlert>
          {/* <ExploreContainer name="Tab 2 page" /> */}

          <WeeklyScheduleLogList data={logs}></WeeklyScheduleLogList>

            
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton routerLink="/tab3/0000-00-00">
              <IonIcon icon={addOutline}></IonIcon>
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
    );
  }

  else{
    return (
      
      <IonPage>
        <IonHeader>
          <IonToolbar>
          <IonTitle>My Logs</IonTitle>
          <IonButtons slot="end">
              <IonButton onClick={e => setViewType("daily")} >
                <IonIcon  icon={todayOutline}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={e => setViewType("weekly")}>
                <IonIcon icon={calendarOutline}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton >
                <IonIcon icon={calendarOutline}></IonIcon>
              </IonButton>
            </IonButtons>

            <IonButtons slot="end">
              <IonMenuButton autoHide={false}></IonMenuButton>
            </IonButtons>
            
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonAlert 
          isOpen={err}
          onDidDismiss={() => setErr(false)}
            cssClass='my-custom-class'
            header={'Alert'}
            subHeader={'Subtitle'}
            message={'This is an alert message.'}
            buttons={['OK']}
          ></IonAlert>
          {/* <ExploreContainer name="Tab 2 page" /> */}

          <ScheduleLogList data={logs}></ScheduleLogList>

            
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton routerLink="/tab3/0000-00-00">
              <IonIcon icon={addOutline}></IonIcon>
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
    );
  }
};

export default Tab2;
