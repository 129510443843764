import { Point, Polygon } from "geojson";
import { GPSViolation } from "../NIJAPI";

const Restrictions = [
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8251592,40.5091508,-86.8252128,40.5090733,-86.8252236,40.5089836,-86.8251699,40.5088775,-86.8250787,40.5088204,-86.8249875,40.5088123,-86.8247944,40.5088204,-86.8247789,40.5088331,-86.8247247,40.5088775,-86.824671,40.5089713,-86.8246871,40.5090774,-86.8247676,40.5091793,-86.8249017,40.5092038,-86.8250197,40.5092079,-86.8251592,40.5091508",
      "@id": "way/246254868",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Prophetstown state park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8235445,40.5049701,-86.8235928,40.5048967,-86.8235713,40.5047988,-86.8234748,40.504758,-86.8233782,40.5047784,-86.8233031,40.5048478,-86.8233246,40.5049253,-86.8233471,40.5049413,-86.8234104,40.5049864,-86.8235445,40.5049701",
      "@id": "way/246254965",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Prophetstown state park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8173961,40.5077469,-86.817459,40.5076912,-86.8174934,40.5076225,-86.817495,40.507549,-86.8174638,40.5074794,-86.8174034,40.5074221,-86.8173209,40.5073837,-86.8172263,40.5073689,-86.8171306,40.5073793,-86.8170452,40.5074139,-86.8169804,40.5074684,-86.8169437,40.5075363,-86.8169395,40.5076098,-86.8169624,40.5076705,-86.8170076,40.5077235,-86.8170713,40.5077641,-86.8171478,40.5077888,-86.8172304,40.5077953,-86.8173119,40.5077831,-86.8173961,40.5077469",
      "@id": "way/246254970",
      "leisure": "playground",
      "foot": "yes",
      "highway": "path",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Prophetstown state park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8698948,40.434498,-86.8700355,40.4344261,-86.8700411,40.4343852,-86.8700207,40.4343485,-86.8699559,40.4343104,-86.8698985,40.4342879,-86.869791,40.4343203,-86.8697503,40.4343499,-86.8697429,40.4343922,-86.8697836,40.4344275,-86.8698392,40.4344853,-86.8698948,40.434498",
      "@id": "way/257177655",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Alrington Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8784404,40.3590307,-86.8784404,40.3589173,-86.8783864,40.3588973,-86.878328,40.3588962,-86.8782901,40.3589073,-86.8782872,40.3590296,-86.8784404,40.3590307",
      "@id": "way/264468167",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Homerton Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8784258,40.3605877,-86.8784608,40.3605543,-86.8784594,40.3604598,-86.8782974,40.3604586,-86.878293,40.360551,-86.8783324,40.3605877,-86.8784258,40.3605877",
      "@id": "way/264468169",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Homerton Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8055992,40.422483,-86.8055106,40.4224031,-86.8053847,40.4224901,-86.8053217,40.4224688,-86.8052285,40.4225292,-86.8053521,40.422641,-86.8055992,40.422483",
      "@id": "way/264517608",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Goldersgreen Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8836962,40.4177419,-86.8836991,40.4176285,-86.8836462,40.4176267,-86.8836488,40.4175221,-86.8835744,40.4175195,-86.8835742,40.417525,-86.8833451,40.4175169,-86.883345,40.4175103,-86.8832583,40.4175073,-86.8832604,40.4176307,-86.8832282,40.417631,-86.8832289,40.4176735,-86.8831099,40.4176747,-86.8831081,40.4175734,-86.8832303,40.4175722,-86.8832293,40.4175123,-86.8831892,40.4175127,-86.8831883,40.4174618,-86.8829723,40.4174639,-86.8829762,40.4176863,-86.882983,40.4176862,-86.8829845,40.4177779,-86.8832629,40.417775,-86.8832621,40.4177327,-86.8836962,40.4177419",
      "@id": "way/283530867",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "US",
      "addr:housenumber": 1200,
      "addr:postcode": 47901,
      "addr:state": "IN",
      "addr:street": "South Street",
      "amenity": "school",
      "building": "school",
      "ele": 187,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 442716,
      "gnis:state_id": 18,
      "name": "Saint Mary Cathedral School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8926474,40.4246059,-86.8928392,40.4246029,-86.8928689,40.4245668,-86.8928689,40.4245171,-86.8929381,40.4244931,-86.8929638,40.4244479,-86.8929677,40.4244013,-86.892683,40.4244028,-86.892681,40.4244765,-86.8926158,40.4244765,-86.8926158,40.4244615,-86.8925565,40.42446,-86.8925585,40.4245337,-86.8925861,40.4245367,-86.8925901,40.4245593,-86.8926455,40.4245608,-86.8926474,40.4246059",
      "@id": "way/283537177",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "bar",
      "building": "yes",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "The Pub",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.7921976,40.4103459,-86.7921843,40.4075955,-86.7919285,40.4075955,-86.7895668,40.4075961,-86.7895942,40.4103514,-86.7921976,40.4103459",
      "@id": "way/299788474",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 5865,
      "addr:postcode": 47905,
      "addr:state": "",
      "addr:street": "East 50 South",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wyandotte Elementary School",
      "School System_1": "Tippecanoe School Corporation",
      "building:levels": "K through 5",
      "phone": "1-765-772-7000",
      "website": "https://wye.tsc.k12.in.us/",
      "wheelchair": "yes",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9289332,40.4213573,-86.92891,40.4211776,-86.9285949,40.4212012,-86.9284836,40.4212958,-86.9287733,40.4214932,-86.9289332,40.4213573",
      "@id": "way/301833203",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Purdue Village Preschool",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9280699,40.421709,-86.9280637,40.4215455,-86.9277754,40.4215519,-86.9277816,40.4217154,-86.9280699,40.421709",
      "@id": "way/301833404",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Nimitz Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9274132,40.4185522,-86.9273513,40.4185521,-86.9273507,40.4187415,-86.9275223,40.4188028,-86.9277208,40.4187538,-86.9277664,40.4186619,-86.9276323,40.4185516,-86.9275378,40.4185518,-86.9274132,40.4185522",
      "@id": "way/301833526",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Arnold Drive Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9064329,40.4255862,-86.9064339,40.4254876,-86.9062681,40.4254819,-86.9062606,40.4256239,-86.906425,40.4256253,-86.9064329,40.4255862",
      "@id": "way/302408406",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8699056,40.4142923,-86.8699634,40.4142766,-86.8700152,40.4142333,-86.870004,40.414149,-86.8700104,40.4140866,-86.8699414,40.4140552,-86.8698972,40.4141117,-86.8698229,40.4141499,-86.8697657,40.4142206,-86.8698053,40.4142773,-86.8698584,40.4142932,-86.8699056,40.4142923",
      "@id": "way/302423537",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crescent Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8692163,40.4140755,-86.869235,40.4140073,-86.8691926,40.4139682,-86.869056,40.4139857,-86.8690797,40.414093,-86.8692163,40.4140755",
      "@id": "way/302423538",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crescent Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8695993,40.4144715,-86.8696633,40.4143898,-86.8695223,40.4143258,-86.8694583,40.4144074,-86.8695993,40.4144715",
      "@id": "way/302423539",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crescent Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.869355,40.4142611,-86.8692819,40.4141662,-86.8691673,40.4142174,-86.8692404,40.4143123,-86.869355,40.4142611",
      "@id": "way/302423540",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crescent Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8698073,40.4137496,-86.869869,40.4136659,-86.8696974,40.4135924,-86.8696356,40.4136761,-86.8698073,40.4137496",
      "@id": "way/302423541",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crescent Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8692469,40.4138992,-86.8692871,40.4138713,-86.8693101,40.4138044,-86.8692865,40.4137467,-86.8692695,40.413705,-86.8692302,40.4136751,-86.8691713,40.4137324,-86.8691551,40.4137784,-86.8691428,40.4138702,-86.8692469,40.4138992",
      "@id": "way/302423543",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crescent Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8696385,40.4141422,-86.8697337,40.4140049,-86.8696983,40.4139245,-86.8695688,40.4138992,-86.869491,40.4139074,-86.869432,40.4140095,-86.8694347,40.414083,-86.8695688,40.4141443,-86.8696385,40.4141422",
      "@id": "way/302423648",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crescent Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8681583,40.4205164,-86.8681863,40.4212124,-86.867654,40.4212116,-86.8677708,40.4228767,-86.8698303,40.4228444,-86.8698274,40.4224381,-86.8702928,40.4224229,-86.8702907,40.4223835,-86.8702785,40.4221526,-86.8708556,40.4216693,-86.8708374,40.4211334,-86.8709155,40.4208168,-86.8681583,40.4205164",
      "@id": "way/319560002",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 530,
      "addr:postcode": 47904,
      "addr:state": "",
      "addr:street": "North 26th Street",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": 444419,
      "gnis:state_id": "",
      "name": "Sunnyside Intermediate School",
      "School System_1": "",
      "building:levels": "",
      "phone": "+1 765 771 6100",
      "website": "https://sis.lsc.k12.in.us/",
      "wheelchair": "",
      "operator": "Lafayette School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8620773,40.436106,-86.8620805,40.4359302,-86.8619942,40.435833,-86.8619494,40.435744,-86.8621782,40.4351662,-86.8622726,40.435084,-86.8624597,40.4350295,-86.8624789,40.4349672,-86.8628078,40.4346826,-86.8634927,40.4342846,-86.8634993,40.4342638,-86.8631567,40.4339776,-86.8628375,40.4338672,-86.8625229,40.4337957,-86.8615067,40.4337828,-86.861497,40.4341292,-86.8608992,40.4341342,-86.8607416,40.4342293,-86.8608044,40.4353764,-86.8611652,40.4353872,-86.8611617,40.4361686,-86.8619911,40.4361589,-86.8620773,40.436106",
      "@id": "way/319560003",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 3101,
      "addr:postcode": 47904,
      "addr:state": "",
      "addr:street": "Elmwood Avenue",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": 445309,
      "gnis:state_id": "",
      "name": "Vinton Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "765-771-6140",
      "website": "http://lsc.vinton.schoolfusion.us/",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8928866,40.4135093,-86.8926177,40.4135127,-86.8926197,40.4136023,-86.8926218,40.413702,-86.8927207,40.4137007,-86.8927201,40.4136733,-86.8928901,40.4136711,-86.8928876,40.4135535,-86.8928866,40.4135093",
      "@id": "way/363665514",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "bar",
      "building": "yes",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Spot Tavern",
      "School System_1": "",
      "building:levels": "",
      "phone": "(765) 742-7989",
      "website": "http://www.spottavern.com/",
      "wheelchair": "",
      "operator": "",
      "smoking": "yes",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9141537,40.4457347,-86.9141147,40.4456485,-86.9139993,40.4453895,-86.91361,40.4445162,-86.9135388,40.444396,-86.9132127,40.4440437,-86.9131639,40.4439367,-86.9126531,40.4439822,-86.9120519,40.4440518,-86.9108953,40.4440894,-86.9110798,40.4457561,-86.9117034,40.4457522,-86.91223,40.4457482,-86.9126795,40.4457462,-86.9132792,40.4457455,-86.9135962,40.4457411,-86.9140365,40.4457349,-86.9141537,40.4457347",
      "@id": "way/369598644",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "West Lafayette Intermediate School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "https://wlis.wl.k12.in.us/",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9436649,40.3026775,-86.9436379,40.302589,-86.9436864,40.3015974,-86.9430856,40.3015729,-86.9431714,40.3026857,-86.9432581,40.3026843,-86.9436649,40.3026775",
      "@id": "way/443794710",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Mintonye Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9415835,40.3027839,-86.9415406,40.3021129,-86.9411115,40.3021538,-86.9409827,40.3023543,-86.9409827,40.3026039,-86.9411973,40.302792,-86.9415835,40.3027839",
      "@id": "way/443794711",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Mintonye Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9437293,40.3029802,-86.9437508,40.3013765,-86.94006,40.301317,-86.940103,40.3029884,-86.9437293,40.3029802",
      "@id": "way/443794712",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Mintonye Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Tippecanoe School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9470767,40.3037821,-86.9470123,40.3013711,-86.946497,40.3013777,-86.9438366,40.301412,-86.9437508,40.3037766,-86.9470767,40.3037821",
      "@id": "way/443794952",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Southwestern Middle School Grounds",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Tippecanoe School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9620819,40.4672992,-86.9620423,40.4660208,-86.9620448,40.4645962,-86.9620478,40.4644373,-86.9620333,40.4628564,-86.9617764,40.4628592,-86.9595719,40.462883,-86.9578817,40.4629012,-86.9579407,40.463893,-86.9582304,40.4639011,-86.9582787,40.4647255,-86.9595447,40.4647541,-86.959581,40.4660537,-86.9605318,40.4660438,-86.960563,40.4673188,-86.9606241,40.4673162,-86.9620819,40.4672992",
      "@id": "way/449016857",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Klondike Middle School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9188829,40.4508602,-86.918889,40.4507132,-86.9187792,40.450642,-86.9186166,40.4507689,-86.9186674,40.4508617,-86.9188829,40.4508602",
      "@id": "way/451616614",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Klondike Middle School Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8755008,40.4205011,-86.8726451,40.420996,-86.8725159,40.4209998,-86.8725805,40.4215935,-86.8727727,40.4215828,-86.8728714,40.4215773,-86.873738,40.4215292,-86.8738668,40.4215929,-86.8739289,40.4217637,-86.8735888,40.4225058,-86.8736061,40.4226692,-86.8738077,40.4227809,-86.8739913,40.4227659,-86.8741316,40.4227119,-86.8742972,40.422492,-86.8746902,40.4219339,-86.8750034,40.4217479,-86.8754302,40.421514,-86.8754699,40.4212721,-86.875548,40.4208443,-86.8755008,40.4205011",
      "@id": "way/473433900",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 2100,
      "addr:postcode": 47904,
      "addr:state": "IN",
      "addr:street": "Cason Street",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Murdock Elementary",
      "School System_1": "",
      "building:levels": "",
      "phone": "(765) 771-6120",
      "website": "https://mur.lsc.k12.in.us/",
      "wheelchair": "",
      "operator": "Lafayette School Corporation",
      "smoking": "",
      "fax": "(765) 771-6126",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8437263,40.4242965,-86.8437551,40.4241699,-86.8437007,40.4240921,-86.8436176,40.4240556,-86.843576,40.4241164,-86.8435537,40.4242624,-86.8435824,40.4242867,-86.8437263,40.4242965",
      "@id": "way/473439185",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Lawson Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.883314,40.4332011,-86.8833025,40.4321385,-86.8815535,40.4321589,-86.8815496,40.4331953,-86.8818181,40.4331953,-86.8819831,40.4331486,-86.8828269,40.4331486,-86.8830186,40.4331982,-86.883314,40.4332011",
      "@id": "way/474674840",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Linnwood School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8884177,40.3860069,-86.8883991,40.3859567,-86.8883593,40.3859143,-86.8883031,40.3858845,-86.888237,40.385871,-86.8881687,40.3858754,-86.8881104,40.3858949,-86.8880632,40.3859275,-86.8880318,40.3859698,-86.8880196,40.3860174,-86.8880276,40.3860655,-86.8880552,40.3861093,-86.8880995,40.3861442,-86.8881559,40.3861667,-86.8882188,40.3861746,-86.8882817,40.3861669,-86.8883383,40.3861445,-86.8883854,40.3861066,-86.8884129,40.3860589,-86.8884177,40.3860069",
      "@id": "way/475216718",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "South Beck Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8678154,40.3878152,-86.8678056,40.3871065,-86.8675816,40.3871015,-86.8675783,40.3874179,-86.8674328,40.3874166,-86.8674278,40.3875985,-86.8675701,40.3876035,-86.8675554,40.3878114,-86.8678154,40.3878152",
      "@id": "way/475897701",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Miami Elementary",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8975517,40.388684,-86.8975485,40.3885228,-86.8973384,40.3885252,-86.8973417,40.3886864,-86.8975517,40.388684",
      "@id": "way/476672754",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Primera Iglesia Bautista Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9220606,40.5076882,-86.9220636,40.5046722,-86.9220388,40.504347,-86.9219331,40.5041037,-86.9193707,40.5041103,-86.9193468,40.5041107,-86.9193025,40.5041115,-86.919291,40.5041117,-86.9161111,40.5041583,-86.9161489,40.5071471,-86.916159,40.5078728,-86.9173131,40.5078799,-86.9176081,40.5077738,-86.918445,40.5077127,-86.9220606,40.5076882",
      "@id": "way/487263334",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Battle Ground Middle School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9029656,40.4076447,-86.9029183,40.4075194,-86.9027942,40.40754,-86.9028078,40.4076774,-86.9029656,40.4076447",
      "@id": "way/547097679",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Shamrock Dog Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9022103,40.408358,-86.9022919,40.4081876,-86.9022255,40.4081544,-86.9021193,40.4082497,-86.9021117,40.4083479,-86.9022103,40.408358",
      "@id": "way/547097681",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Shamrock Dog Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8989018,40.4217541,-86.8988638,40.4215202,-86.8987775,40.4215016,-86.8987024,40.4215057,-86.8986702,40.4215425,-86.898697,40.4215996,-86.8987083,40.421669,-86.8988069,40.4217628,-86.8989018,40.4217541",
      "@id": "way/547104253",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wabash Heritage Trail",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8694701,40.4136215,-86.8694739,40.4135847,-86.8694303,40.4135903,-86.8693746,40.4135445,-86.869324,40.4135664,-86.8693259,40.4135932,-86.8692948,40.4135988,-86.8692974,40.4136186,-86.8693012,40.4136467,-86.8693583,40.4136341,-86.8694257,40.4136583,-86.8694701,40.4136215",
      "@id": "way/547112767",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Cherokee Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8699216,40.362927,-86.8699519,40.3629096,-86.8699595,40.3628677,-86.8699368,40.3628388,-86.8699064,40.3628244,-86.8697054,40.3628301,-86.8696636,40.3628475,-86.8696523,40.3628778,-86.8696674,40.362914,-86.8697016,40.3629284,-86.8699216,40.362927",
      "@id": "way/547475690",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Kingfisher Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.894043,40.4132889,-86.8939232,40.4132535,-86.8938584,40.4133804,-86.8939782,40.4134159,-86.894043,40.4132889",
      "@id": "way/548249235",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Bauer Community Center Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8622189,40.4343886,-86.8622475,40.4342145,-86.8620043,40.4341914,-86.8619757,40.4343655,-86.8622189,40.4343886",
      "@id": "way/550969125",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Vinton Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8614873,40.4346847,-86.8614572,40.4346786,-86.8613575,40.4346009,-86.8611347,40.4348009,-86.8613389,40.4348179,-86.8614873,40.4346847",
      "@id": "way/550969126",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Vinton Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9298634,40.4226386,-86.929869,40.4226025,-86.9297287,40.4225866,-86.9295921,40.4225361,-86.929644,40.4226315,-86.9296984,40.4226776,-86.9297818,40.4226733,-86.9298634,40.4226386",
      "@id": "way/570375797",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Nimitz Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9104937,40.4586908,-86.9107935,40.4584808,-86.9107951,40.4584274,-86.910658,40.4582891,-86.9103055,40.4586034,-86.9104283,40.4586968,-86.9104937,40.4586908",
      "@id": "way/572442187",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Barlow  Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9022828,40.4398255,-86.9023471,40.439705,-86.9020655,40.4396234,-86.9020199,40.4397377,-86.9022828,40.4398255",
      "@id": "way/673008966",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Happy Hollow Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.901513,40.4395968,-86.9015693,40.4394233,-86.9011777,40.4393253,-86.9011321,40.4394723,-86.901513,40.4395968",
      "@id": "way/673008967",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Happy Hollow Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8720426,40.4109959,-86.872037,40.4108703,-86.871789,40.4108879,-86.8717978,40.4109874,-86.8720426,40.4109959",
      "@id": "way/673211679",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Oakland Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.7905355,40.4084535,-86.7905332,40.4082081,-86.7902964,40.4082094,-86.7902987,40.4084547,-86.7905355,40.4084535",
      "@id": "way/673517428",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wyandotte Elementary Schoo",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.7908301,40.408165,-86.7908289,40.4080333,-86.7905703,40.4080339,-86.7905751,40.4081632,-86.7908301,40.408165",
      "@id": "way/673517431",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wyandotte Elementary Schoo",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.872813,40.4112942,-86.8727713,40.4101563,-86.8727506,40.4101567,-86.8708671,40.4101909,-86.8708595,40.4102949,-86.8719785,40.4112827,-86.872813,40.4112942",
      "@id": "way/673517449",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 611,
      "addr:postcode": 47905,
      "addr:state": "IN",
      "addr:street": "South Street",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Oakland Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "(765) 771-6130",
      "website": "https://oak.lsc.k12.in.us/",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.7985335,40.4198381,-86.7985362,40.4196298,-86.7984128,40.4195747,-86.7982787,40.4196114,-86.7982881,40.4196829,-86.7983538,40.4197237,-86.7983444,40.4197728,-86.7982867,40.4198146,-86.7983618,40.4198657,-86.7984906,40.4198657,-86.7985335,40.4198381",
      "@id": "way/674549360",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Faith Church Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8685201,40.3587338,-86.8685239,40.3586948,-86.8684557,40.358645,-86.8684329,40.3585734,-86.8683902,40.3585655,-86.8683457,40.3585749,-86.8683267,40.3586457,-86.8682622,40.3587006,-86.8682688,40.3587331,-86.8683058,40.3587541,-86.868486,40.3587526,-86.8685201,40.3587338",
      "@id": "way/674561809",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Landing Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8892183,40.3798817,-86.8892024,40.3781049,-86.8857078,40.3781231,-86.8857237,40.3798997,-86.8892183,40.3798817",
      "@id": "way/674837876",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 3280,
      "addr:postcode": 47909,
      "addr:state": "IN",
      "addr:street": "S 9th St",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Amelia Earhart Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "(765) 772-4740",
      "website": "https://ear.lsc.k12.in.us/",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8877873,40.3796382,-86.8877862,40.379498,-86.8874951,40.3794997,-86.8874957,40.3796395,-86.8877873,40.3796382",
      "@id": "way/674837881",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Amelia Earhart Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8906955,40.3952188,-86.8906081,40.3910851,-86.8905438,40.3880478,-86.8899618,40.3880476,-86.8885855,40.3880472,-86.8859582,40.3880463,-86.8859269,40.3884521,-86.8858784,40.3887525,-86.8861051,40.3952802,-86.8906955,40.3952188",
      "@id": "way/674849263",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Central Catholic Jr/Sr School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8849854,40.4246548,-86.8849693,40.4236729,-86.8832244,40.4236895,-86.8832405,40.4246714,-86.8849854,40.4246548",
      "@id": "way/675024269",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Lafayette Adult Learning",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9084619,40.4324715,-86.9084813,40.4324679,-86.908484,40.4324378,-86.9084512,40.4324271,-86.9084263,40.4324118,-86.9084223,40.4323618,-86.9084136,40.4323133,-86.9083546,40.4323041,-86.9083284,40.4323291,-86.9083392,40.4323985,-86.90837,40.4324144,-86.9083948,40.4324389,-86.9084304,40.43247,-86.9084619,40.4324715",
      "@id": "way/684692090",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Vine Street Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9159428,40.5040451,-86.9158876,40.4969751,-86.9143346,40.496978,-86.9140573,40.4969997,-86.9138576,40.4970662,-86.9136824,40.4971977,-86.9134974,40.4974527,-86.9134273,40.4974983,-86.9133406,40.4974927,-86.9132404,40.4975007,-86.9132045,40.497533,-86.9132621,40.4976519,-86.9132187,40.4977011,-86.913067,40.497719,-86.9128042,40.4978624,-86.9124262,40.4979613,-86.9122595,40.4980324,-86.9121863,40.4981653,-86.9122071,40.4988137,-86.9119911,40.4988207,-86.9119971,40.4992326,-86.9114192,40.499235,-86.9114799,40.5019804,-86.911526,40.5040655,-86.9159428,40.5040451",
      "@id": "way/710582358",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "William Henry Harrison High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.7629259,40.4610138,-86.762952,40.4587252,-86.7629748,40.4567312,-86.7590406,40.4567824,-86.7592221,40.4610852,-86.7629259,40.4610138",
      "@id": "way/710586459",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "East Tipp Middle School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8597086,40.4179469,-86.8597046,40.4177466,-86.8596601,40.4177472,-86.8596599,40.4177396,-86.8596325,40.4177399,-86.8596325,40.4177374,-86.8595832,40.417738,-86.8595832,40.4177405,-86.8595568,40.4177408,-86.8595578,40.4177916,-86.8595396,40.4177918,-86.8595405,40.4178359,-86.8595601,40.4178357,-86.8595618,40.4179207,-86.8595535,40.4179208,-86.8595537,40.4179317,-86.8595781,40.4179315,-86.8595785,40.4179484,-86.8597086,40.4179469",
      "@id": "way/719056427",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "US",
      "addr:housenumber": 3312,
      "addr:postcode": 47904,
      "addr:state": "IN",
      "addr:street": "South Street",
      "amenity": "bar",
      "building": "yes",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "The Cozy Tavern",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8295311,40.4197135,-86.8295298,40.4195404,-86.8293538,40.4195412,-86.8293537,40.4195236,-86.8292558,40.419524,-86.8292559,40.4195416,-86.8290822,40.4195424,-86.8290836,40.4197156,-86.8295311,40.4197135",
      "@id": "way/721725756",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "US",
      "addr:housenumber": 4050,
      "addr:postcode": 47905,
      "addr:state": "IN",
      "addr:street": "Britt Farm Drive",
      "amenity": "school",
      "building": "yes",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Sylvan Learning",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9151099,40.386974,-86.9150579,40.386819,-86.9148934,40.3868554,-86.9149459,40.3870043,-86.9151099,40.386974",
      "@id": "way/721774823",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Northchester Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8912971,40.4132116,-86.891296,40.4131688,-86.891232,40.4131698,-86.891231,40.4131334,-86.8911845,40.4131341,-86.8911867,40.4132133,-86.8912971,40.4132116",
      "@id": "way/733416860",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "South 5th Street Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9213823,40.4541044,-86.9213844,40.4539902,-86.9211347,40.4539875,-86.9211325,40.4541018,-86.9213823,40.4541044",
      "@id": "way/743152324",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Beau Jardin Laundry Facility",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Beau Jardin Apartments",
      "smoking": "",
      "fax": "",
      "access": "private",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8805921,40.431916,-86.8805876,40.4319347,-86.8805965,40.4319534,-86.8806323,40.4319821,-86.8806946,40.4319703,-86.8807504,40.4319466,-86.8807927,40.43195,-86.8808641,40.4319619,-86.8809666,40.4319653,-86.8810514,40.4319568,-86.8810558,40.4319415,-86.8810358,40.4319126,-86.8810112,40.4318838,-86.8809912,40.4318651,-86.8809756,40.4318465,-86.8809466,40.4318261,-86.8809109,40.4318108,-86.8808752,40.4318057,-86.8808195,40.4318261,-86.8807504,40.4318617,-86.8807058,40.4318753,-86.880688,40.4318668,-86.8806567,40.4318617,-86.8806166,40.4318702,-86.8805921,40.4318838,-86.8805921,40.431916",
      "@id": "way/784201340",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Greenbush Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8809265,40.4317446,-86.8809421,40.4317361,-86.8809958,40.4317139,-86.8809979,40.4316632,-86.8809488,40.4316241,-86.8808797,40.4316123,-86.8808039,40.4316157,-86.8807437,40.4316174,-86.8807102,40.4316377,-86.880708,40.4316666,-86.880708,40.4317073,-86.8807303,40.4317311,-86.8807749,40.4317243,-86.8808173,40.4317039,-86.8808507,40.4317073,-86.8808864,40.4317226,-86.8809265,40.4317446",
      "@id": "way/784201341",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Greenbush Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9702536,40.4804886,-86.9703094,40.4804062,-86.9701495,40.4803409,-86.9700938,40.4803377,-86.9700455,40.4803964,-86.9702536,40.4804886",
      "@id": "way/832058775",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Peterborough Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8905349,40.4222849,-86.8905295,40.4220779,-86.8903507,40.4220806,-86.8903561,40.4222876,-86.8905349,40.4222849",
      "@id": "way/835919802",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Centennial Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.906266,40.4656814,-86.9063075,40.4656552,-86.9063341,40.4656266,-86.9063435,40.4655808,-86.9063247,40.4655486,-86.906295,40.4655248,-86.9062512,40.4655141,-86.9058785,40.4655111,-86.9058488,40.4655254,-86.9058151,40.4655492,-86.905801,40.4655826,-86.9058018,40.4656165,-86.9058261,40.4656516,-86.905848,40.4656671,-86.9058879,40.465682,-86.9059349,40.4656826,-86.906028,40.4656076,-86.9060554,40.465607,-86.9061478,40.4656784,-86.9061987,40.4656856,-86.9062441,40.4656856,-86.906266,40.4656814",
      "@id": "way/858579315",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "University Farm Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "yes",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9061499,40.4612175,-86.9062538,40.4612182,-86.9063442,40.4611433,-86.9063451,40.460997,-86.9064896,40.4609147,-86.906158,40.4608857,-86.9061332,40.4611979,-86.9061499,40.4612175",
      "@id": "way/858629948",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "WLES Kindergarten Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "private",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9077978,40.4724282,-86.907815,40.4724115,-86.907815,40.4723333,-86.9077931,40.4723195,-86.9075588,40.4723202,-86.9075416,40.4723376,-86.9075416,40.4723883,-86.9075416,40.4724166,-86.9075502,40.472426,-86.9077978,40.4724282",
      "@id": "way/859066440",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Brixford Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9133458,40.4859223,-86.9133825,40.4858954,-86.9134081,40.4858675,-86.9134069,40.4858341,-86.913192,40.4856827,-86.9131504,40.4856855,-86.9130833,40.4857431,-86.9130955,40.4858016,-86.9131248,40.4858508,-86.9131761,40.4858935,-86.9133458,40.4859223",
      "@id": "way/859093514",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "West Lafayette Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.906344,40.4620861,-86.9067785,40.4620872,-86.908016,40.4620964,-86.9078975,40.4624301,-86.9086737,40.4624389,-86.908686,40.4606164,-86.9063207,40.4606633,-86.9061192,40.4606654,-86.9060708,40.4612447,-86.9063476,40.4612428,-86.906344,40.4620861",
      "@id": "way/859100176",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "West Lafayette",
      "addr:country": "",
      "addr:housenumber": 600,
      "addr:postcode": 47906,
      "addr:state": "IN",
      "addr:street": "Cumberland Avenue",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "West Lafayette Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "K-3",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9278789,40.4617921,-86.927881,40.4617176,-86.9277634,40.4617157,-86.9277613,40.4617902,-86.9278789,40.4617921",
      "@id": "way/859475898",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Courthouse Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9219977,40.4653098,-86.9219965,40.4651945,-86.9218591,40.4651945,-86.9218556,40.4652666,-86.921916,40.4653098,-86.9219977,40.4653098",
      "@id": "way/859692713",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Amberleigh Village Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9217845,40.465445,-86.9218319,40.4653891,-86.9217158,40.465327,-86.9217039,40.4653243,-86.921672,40.4653846,-86.9217845,40.465445",
      "@id": "way/859692714",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Amberleigh Village Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9217347,40.465463,-86.9216424,40.4654144,-86.9215997,40.465445,-86.9215689,40.4654639,-86.9215713,40.4654801,-86.9216684,40.4655288,-86.9217347,40.465463",
      "@id": "way/859692715",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Amberleigh Village Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9029985,40.453901,-86.9030008,40.453105,-86.901587,40.4531025,-86.9016054,40.453648,-86.9019214,40.453655,-86.9019786,40.4537703,-86.9022022,40.4539095,-86.9029985,40.453901",
      "@id": "way/859875209",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "West Lafayette",
      "addr:country": "",
      "addr:housenumber": 2552,
      "addr:postcode": 47906,
      "addr:state": "IN",
      "addr:street": "Soldiers Home Road",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Montessori School of Greater Lafayette",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "http://msgl.org/",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "0-3",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9118489,40.4454558,-86.9122536,40.4451439,-86.9121118,40.4450389,-86.9118206,40.4452662,-86.9116775,40.4451544,-86.9116537,40.4451774,-86.9116567,40.4451971,-86.9116431,40.4452078,-86.9116117,40.4452042,-86.911593,40.4452197,-86.9116748,40.4452983,-86.911693,40.4453213,-86.9117111,40.4453463,-86.9117293,40.4453648,-86.9118489,40.4454558",
      "@id": "way/859885724",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "West Lafayette Community School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9162202,40.5001413,-86.9195455,40.5000975,-86.9195391,40.4991055,-86.9205111,40.4991055,-86.920492,40.4971506,-86.9161115,40.4971895,-86.9162202,40.5001413",
      "@id": "way/859904776",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Burnett Creek Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9193262,40.4994419,-86.919334,40.4990563,-86.9191693,40.4990544,-86.9191578,40.4994397,-86.9193262,40.4994419",
      "@id": "way/859904782",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Burnett Creek Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9188944,40.4997768,-86.9189145,40.4997714,-86.9189246,40.4997538,-86.9189174,40.499455,-86.9189088,40.4994441,-86.9188886,40.4994375,-86.9187202,40.4994386,-86.9187043,40.4994441,-86.9186986,40.4994539,-86.9187058,40.4997637,-86.9187124,40.4997731,-86.9187274,40.499779,-86.9188944,40.4997768",
      "@id": "way/859904783",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Burnett Creek Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9200896,40.4469554,-86.919936,40.4469509,-86.9199232,40.4472053,-86.9200767,40.4472098,-86.9200896,40.4469554",
      "@id": "way/860270031",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Faith Christian Preschool West",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9701312,40.4488539,-86.97003,40.4487442,-86.9699648,40.4487364,-86.9697007,40.4488975,-86.9697693,40.448958,-86.9699347,40.4488737,-86.9699915,40.4489332,-86.9701312,40.4488539",
      "@id": "way/861213150",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Cousteau Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8085315,40.4094796,-86.8085666,40.4094485,-86.8085629,40.4092621,-86.8085323,40.4092382,-86.8084431,40.4092398,-86.8084168,40.4092626,-86.8084168,40.409286,-86.8083591,40.4092843,-86.8083569,40.4094485,-86.8084015,40.4094813,-86.8085315,40.4094796",
      "@id": "way/862359964",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Trotter Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.7965548,40.4115323,-86.7966342,40.4114341,-86.7964915,40.4113673,-86.7964459,40.4114238,-86.7964982,40.4114483,-86.7964645,40.41149,-86.7965548,40.4115323",
      "@id": "way/862361941",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Bluegrass Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9069822,40.3949175,-86.9069856,40.3948362,-86.9069538,40.3948258,-86.9069152,40.3948223,-86.9068982,40.3948007,-86.9068755,40.3947938,-86.9068573,40.3948068,-86.9068482,40.3948215,-86.9067199,40.3948258,-86.9067029,40.3948431,-86.9067063,40.3949097,-86.9067313,40.3949348,-86.9068494,40.3949304,-86.9068596,40.3949529,-86.9068834,40.3949624,-86.9069084,40.3949495,-86.9069141,40.3949313,-86.9069822,40.3949175",
      "@id": "way/862365391",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Crosser Sports Complex",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9107464,40.4367545,-86.9128097,40.4367961,-86.9128082,40.4351007,-86.9117498,40.4351027,-86.9106818,40.4351047,-86.9107464,40.4367545",
      "@id": "way/862470229",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "West Lafayette Junior/Senior High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9009241,40.3452146,-86.897852,40.3452002,-86.8978441,40.3459581,-86.8987995,40.3481508,-86.9009406,40.3481274,-86.9009241,40.3452146",
      "@id": "way/862484105",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Mayflower Mill Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8987995,40.3481508,-86.9003528,40.352566,-86.9023665,40.3524689,-86.9049254,40.3523657,-86.9040183,40.3496046,-86.903923,40.3488945,-86.9039039,40.3481099,-86.9012888,40.3481236,-86.9009406,40.3481274,-86.8987995,40.3481508",
      "@id": "way/862484106",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Mayflower Mill Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.88042,40.3470914,-86.8757905,40.3471033,-86.8759493,40.3539099,-86.87925,40.3538752,-86.8792545,40.3535833,-86.8789894,40.3535856,-86.8790024,40.3523889,-86.880481,40.3523809,-86.88042,40.3470914",
      "@id": "way/866043275",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wea Ridge Middle School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Tippecanoe County School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "school",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8805298,40.3559791,-86.880481,40.3523809,-86.8790024,40.3523889,-86.8789894,40.3535856,-86.8792545,40.3535833,-86.87925,40.3538752,-86.8759493,40.3539099,-86.875959,40.354822,-86.8760171,40.3551794,-86.8761817,40.3554563,-86.8764748,40.3556603,-86.8786011,40.3558532,-86.8805298,40.3559791",
      "@id": "way/866043276",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wea Ridge Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Tippecanoe County School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "school",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9072508,40.4631602,-86.9072332,40.4631486,-86.9072116,40.4631419,-86.9071884,40.463141,-86.907166,40.4631458,-86.907147,40.4631559,-86.9071333,40.4631701,-86.9071264,40.463187,-86.907127,40.4632046,-86.9071351,40.4632211,-86.9071498,40.4632348,-86.9071695,40.463244,-86.9071921,40.4632479,-86.9072152,40.4632461,-86.9072362,40.4632386,-86.9072529,40.4632263,-86.9072635,40.4632106,-86.9072668,40.463193,-86.9072624,40.4631756,-86.9072508,40.4631602",
      "@id": "way/866460163",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wabash Valley Education Center",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "yes",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9083477,40.4616381,-86.9081393,40.4616352,-86.9080827,40.461689,-86.9080891,40.4620061,-86.9083554,40.4620071,-86.9085111,40.4619445,-86.9084339,40.461688,-86.9083477,40.4616381",
      "@id": "way/866460175",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "WLES Grade 1-3 Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "private",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8436223,40.4290133,-86.8414695,40.4277905,-86.8409941,40.4277009,-86.8410003,40.4282665,-86.8409652,40.4284032,-86.8409425,40.4284597,-86.8409075,40.4285116,-86.8402346,40.4292217,-86.8401913,40.4292767,-86.8401644,40.4293207,-86.8401397,40.4293804,-86.8401252,40.4294495,-86.8401314,40.4297967,-86.8411882,40.4297779,-86.8414214,40.429792,-86.8416794,40.4298376,-86.841861,40.4299083,-86.8424389,40.4302414,-86.8427196,40.4299978,-86.8432191,40.4303215,-86.843442,40.4303325,-86.8434585,40.429272,-86.8436223,40.4290133",
      "@id": "way/867612669",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Glen Acres Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8473117,40.4336681,-86.8471567,40.4336064,-86.8471326,40.4336437,-86.8471834,40.4336661,-86.8471442,40.4337169,-86.8471772,40.4337298,-86.847154,40.4337956,-86.847211,40.4338126,-86.8473117,40.4336681",
      "@id": "way/867617701",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Chauncey Village Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8523289,40.4273538,-86.8523807,40.4273073,-86.8523523,40.427285,-86.8523681,40.4272736,-86.8523063,40.4272335,-86.8522327,40.427173,-86.8522361,40.4271266,-86.8522143,40.4270999,-86.8522319,40.4270865,-86.85217,40.4270248,-86.8520814,40.4270076,-86.851987,40.427021,-86.8519251,40.4270884,-86.8519502,40.4271609,-86.8520221,40.4271794,-86.8520664,40.4272551,-86.8521266,40.4272456,-86.8522837,40.4273436,-86.8522979,40.4273321,-86.8523289,40.4273538",
      "@id": "way/867655753",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Munger Park Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8447966,40.4231274,-86.844716,40.420685,-86.8425388,40.4207004,-86.8425459,40.4212974,-86.8425816,40.4225203,-86.8438244,40.422507,-86.8438391,40.4231375,-86.8447966,40.4231274",
      "@id": "way/868180422",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "McCaw Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8931313,40.4098506,-86.8942697,40.4098387,-86.8942279,40.4090001,-86.8942457,40.4088749,-86.8943863,40.40878,-86.8945823,40.4087287,-86.8947645,40.40859,-86.8946853,40.4083337,-86.8943289,40.4080639,-86.8941428,40.4079945,-86.8935547,40.4075483,-86.8934577,40.4074141,-86.8934261,40.4073447,-86.8935191,40.4069332,-86.8930895,40.4069347,-86.8931313,40.4098506",
      "@id": "way/868184580",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 700,
      "addr:postcode": 47905,
      "addr:state": "IN",
      "addr:street": "South 4th Street",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Miller Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Lafayette School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "0-4",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8702907,40.4223835,-86.8702785,40.4221526,-86.8702764,40.4221082,-86.8702549,40.4220854,-86.8702163,40.4220604,-86.8701077,40.4220386,-86.8700205,40.4220473,-86.8699605,40.4220636,-86.8699061,40.4220832,-86.8698318,40.4221246,-86.8698032,40.4221714,-86.8697675,40.422229,-86.8697675,40.4222834,-86.8697889,40.4223335,-86.8698247,40.4223618,-86.8698818,40.4223836,-86.8702907,40.4223835",
      "@id": "way/868193110",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Lafayette Sunnyside Intermediate School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.870253,40.4220684,-86.870293,40.4220641,-86.8703145,40.422038,-86.8703059,40.4218073,-86.8702001,40.4217746,-86.8700643,40.4220031,-86.8700657,40.4220293,-86.8701258,40.4220293,-86.8701887,40.4220423,-86.870253,40.4220684",
      "@id": "way/868193112",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Lafayette Sunnyside Intermediate School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8848803,40.4256308,-86.8849367,40.4255788,-86.8849335,40.4255353,-86.8847874,40.4255359,-86.8847628,40.425554,-86.8847731,40.4256308,-86.8848803,40.4256308",
      "@id": "way/868402208",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Salvation Army Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8876092,40.4238603,-86.8876116,40.4237219,-86.8876118,40.4237083,-86.887455,40.423707,-86.8874117,40.4237394,-86.887414,40.4238596,-86.8876092,40.4238603",
      "@id": "way/868413083",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "St. James Lutheran School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8874142,40.4237235,-86.8874175,40.4234808,-86.8871433,40.4234808,-86.8871406,40.4237796,-86.8873252,40.4237806,-86.8874142,40.4237235",
      "@id": "way/868413084",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "St. James Lutheran School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8882681,40.4237186,-86.8882681,40.4234694,-86.8882654,40.4231405,-86.8882606,40.4228252,-86.887925,40.4228282,-86.8869752,40.4228249,-86.8869989,40.4241627,-86.8882887,40.4241574,-86.8882681,40.4237186",
      "@id": "way/868413090",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "St. James Lutheran School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9258663,40.4618763,-86.9257688,40.4618771,-86.9257692,40.461906,-86.92577,40.4619626,-86.9257811,40.4619626,-86.9257889,40.4619702,-86.9258014,40.4619701,-86.9258124,40.4619633,-86.9258675,40.4619629,-86.9258663,40.4618763",
      "@id": "way/882835699",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "St. James Lutheran School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8675872,40.42298,-86.8675859,40.4228614,-86.8675694,40.4228639,-86.8675015,40.4227064,-86.8674849,40.4218959,-86.865557,40.4219186,-86.8655749,40.4227067,-86.866324,40.4227026,-86.8663248,40.4227603,-86.866328,40.4229878,-86.8670869,40.4229831,-86.8675872,40.42298",
      "@id": "way/885596375",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 525,
      "addr:postcode": 47904,
      "addr:state": "IN",
      "addr:street": "North 26th Street",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Lafayette Christian School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "christian",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8670869,40.4229831,-86.8670731,40.4228281,-86.8669423,40.4227754,-86.8668454,40.4227555,-86.8663248,40.4227603,-86.866328,40.4229878,-86.8670869,40.4229831",
      "@id": "way/885596376",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Lafayette Christian School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8717935,40.3955801,-86.8718511,40.3977953,-86.8718786,40.3988538,-86.8718873,40.3991896,-86.8718193,40.4013638,-86.8733935,40.4013491,-86.8738226,40.400724,-86.8738204,40.3999749,-86.8764483,40.3999095,-86.876368,40.3961368,-86.8763431,40.3960082,-86.8762933,40.3958584,-86.8762351,40.3957741,-86.8759693,40.3956075,-86.8756223,40.3955617,-86.8752742,40.3955442,-86.8717935,40.3955801",
      "@id": "way/885881214",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Jefferson High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8821124,40.4323746,-86.8821113,40.4321637,-86.8818408,40.4321645,-86.8818419,40.4323754,-86.8821124,40.4323746",
      "@id": "way/886256411",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Linnwood School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8734489,40.4405249,-86.8734501,40.4405051,-86.8735105,40.4404528,-86.8735697,40.4404492,-86.8735697,40.440388,-86.8736064,40.4403853,-86.8736052,40.440333,-86.873462,40.4403348,-86.8734537,40.4403682,-86.8732276,40.4403754,-86.8731897,40.4404655,-86.8732418,40.4404853,-86.8732382,40.4405258,-86.8734489,40.4405249",
      "@id": "way/886266198",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "McAllister Recreation Center",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9135,40.4670252,-86.9135258,40.4669922,-86.9135341,40.4669718,-86.9135114,40.4668588,-86.9135155,40.4667104,-86.9135444,40.4666334,-86.9135846,40.4665526,-86.9135774,40.4665172,-86.9135475,40.4664905,-86.9135093,40.4664772,-86.9134701,40.4664764,-86.9134237,40.4664945,-86.9133886,40.4665235,-86.9133597,40.4665651,-86.9133524,40.4666067,-86.9133545,40.4666374,-86.9133844,40.4666656,-86.9134071,40.466679,-86.9133752,40.466825,-86.9133359,40.4668384,-86.9133174,40.4668698,-86.9133112,40.4669004,-86.9133163,40.4669491,-86.9133442,40.4669985,-86.9133865,40.4670268,-86.9134247,40.4670417,-86.913468,40.4670362,-86.9135,40.4670252",
      "@id": "way/886686658",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Peck-Trachtman Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8385133,40.3858364,-86.8385156,40.3857425,-86.8385011,40.3857285,-86.8382482,40.3857256,-86.8382307,40.3857384,-86.8382269,40.3858318,-86.8382429,40.3858475,-86.838498,40.3858515,-86.8385133,40.3858364",
      "@id": "way/886774174",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "YMCA of Lafayette Indiana Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.8377747,40.3859367,-86.8374498,40.3859319,-86.8374477,40.3860293,-86.8377537,40.3860314,-86.8377736,40.3860189,-86.8377747,40.3859367",
      "@id": "way/886774176",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "YMCA of Lafayette Indiana Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9183683,40.4666928,-86.9184111,40.4666759,-86.9183937,40.466601,-86.9183286,40.466514,-86.918273,40.4664403,-86.918211,40.4663775,-86.9180871,40.4662989,-86.9180046,40.4662458,-86.9179506,40.4662554,-86.9179124,40.4662796,-86.9178918,40.4663279,-86.9179506,40.4664198,-86.9180046,40.4664935,-86.918103,40.4665781,-86.9181888,40.4666348,-86.9182634,40.4666771,-86.9183127,40.4666965,-86.9183683,40.4666928",
      "@id": "way/889919505",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Amberleigh Village Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": "",
      "longitude": "",
      "altitude": "",
      "geometry": "Polygon",
      "coordinates": "-86.9512816,40.3527385,-86.9508596,40.3527434,-86.9508414,40.3527486,-86.9508237,40.3527621,-86.9508265,40.3529265,-86.9508424,40.3529486,-86.9508519,40.352968,-86.9509377,40.3529739,-86.950965,40.3529704,-86.9509795,40.3529711,-86.9509886,40.3529846,-86.9509859,40.353003,-86.9509813,40.3530113,-86.9509891,40.3530168,-86.9511072,40.353022,-86.9511208,40.3530123,-86.9511231,40.352805,-86.9512334,40.352805,-86.9512725,40.352797,-86.9512847,40.3527794,-86.9512816,40.3527385",
      "@id": "way/897640774",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Shadeland Park",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Shadeland Parks and Recreation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4000356,
      "longitude": -86.8777867,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358650966",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 197,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 432204,
      "gnis:state_id": 18,
      "name": "Cary Home for Children",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4602167,
      "longitude": -86.7614475,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358653107",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 7501,
      "addr:postcode": 47905,
      "addr:state": "IN",
      "addr:street": "East 300 North",
      "amenity": "school",
      "building": "",
      "ele": 201,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 433937,
      "gnis:state_id": 18,
      "name": "East Tipp Middle School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "https://etm.tsc.k12.in.us/",
      "wheelchair": "",
      "operator": "Tippecanoe County School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "public",
      "religion": "",
      "wikidata": "Q5329545",
      "amenity_1": ""
    },
    {
      "latitude": 40.4050356,
      "longitude": -86.8911203,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358656257",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 201,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 436171,
      "gnis:state_id": 18,
      "name": "Highland School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4326583,
      "longitude": -86.882401,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358658635",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 174,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 437973,
      "gnis:state_id": 18,
      "name": "Linnwood Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4247581,
      "longitude": -86.7566736,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358660261",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 200,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 439327,
      "gnis:state_id": 18,
      "name": "Monitor School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4708676,
      "longitude": -86.9905682,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358664222",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 214,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 441655,
      "gnis:state_id": 18,
      "name": "Purdue University Agronomy Farm",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4419038,
      "longitude": -86.9300225,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358664225",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 214,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 441656,
      "gnis:state_id": 18,
      "name": "Purdue University Dairy Farm",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.2444811,
      "longitude": -86.826673,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358664227",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 234,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 441657,
      "gnis:state_id": 18,
      "name": "Purdue University Experimental Farm",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4708681,
      "longitude": -86.9208443,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358664231",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 211,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 441658,
      "gnis:state_id": 18,
      "name": "Purdue University Farm Center",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.428924,
      "longitude": -86.9530664,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358664240",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 204,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 441661,
      "gnis:state_id": 18,
      "name": "Purdue University Horticulture Farm",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4017022,
      "longitude": -86.9397322,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358664252",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 166,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 441665,
      "gnis:state_id": 18,
      "name": "Purdue University Veterinarian Farm",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4340142,
      "longitude": -86.8750715,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358665942",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 1902,
      "addr:postcode": 47904,
      "addr:state": "",
      "addr:street": "Meharry Street",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": 442658,
      "gnis:state_id": "",
      "name": "Saint Lawrence Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "765-742-4450",
      "website": "http://stlaw.lcss.org/",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.302259,
      "longitude": -86.9455637,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358667992",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 2100,
      "addr:postcode": 47909,
      "addr:state": "IN",
      "addr:street": "West 800 South",
      "amenity": "school",
      "building": "",
      "ele": 225,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 443891,
      "gnis:state_id": 18,
      "name": "Southwestern Junior High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.3964246,
      "longitude": -86.8738977,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358668879",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 199,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 444614,
      "gnis:state_id": 18,
      "name": "Tecumseh Junior High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.338925,
      "longitude": -86.8605634,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358670092",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 196,
      "gnis:county_id": 157,
      "gnis:created": "2/14/1979",
      "gnis:feature_id": 445587,
      "gnis:state_id": 18,
      "name": "Wea School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.5134935,
      "longitude": -86.8291445,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358673336",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 181,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 447589,
      "gnis:state_id": 18,
      "name": "Battle Ground Junior High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.5121767,
      "longitude": -86.833671,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358673338",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 180,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 447590,
      "gnis:state_id": 18,
      "name": "Battle Ground Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.3769803,
      "longitude": -86.7680624,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358673339",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 206,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 447591,
      "gnis:state_id": 18,
      "name": "Dayton Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.2489249,
      "longitude": -86.7222277,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358673341",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 250,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 447592,
      "gnis:state_id": 18,
      "name": "Clarks Hill Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.3769803,
      "longitude": -86.7677846,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/358680692",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": 206,
      "gnis:county_id": 157,
      "gnis:created": "10/1/1982",
      "gnis:feature_id": 1964929,
      "gnis:state_id": 18,
      "name": "Township School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.423791,
      "longitude": -86.9089845,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/1705344772",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Harry's Chocolate Shop",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4240455,
      "longitude": -86.908341,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/1705345050",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Brother's",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.3985889,
      "longitude": -86.8751701,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/2104723062",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Jefferson High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "Q6175493",
      "amenity_1": ""
    },
    {
      "latitude": 40.4139742,
      "longitude": -86.8695867,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/2104726334",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Columbian Park SIA Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.42291,
      "longitude": -86.9080183,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/2472169201",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Where Else",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.3540546,
      "longitude": -86.8789698,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/2701489709",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Wea Ridge Elementary School Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4187569,
      "longitude": -86.8916578,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/2873883442",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Knickerbocker Saloon",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4240729,
      "longitude": -86.9075626,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/3200412761",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "West Lafayette",
      "addr:country": "",
      "addr:housenumber": 208,
      "addr:postcode": 47906,
      "addr:state": "IN",
      "addr:street": "South Street",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Vienna",
      "School System_1": "",
      "building:levels": "",
      "phone": "+1 (765) 743-4446",
      "website": "https://www.yelp.com/biz/vienna-espresso-bar-and-bakery-west-lafayette",
      "wheelchair": "yes",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": "bakery"
    },
    {
      "latitude": 40.4219299,
      "longitude": -86.9046949,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/3465410707",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "West Lafayette",
      "addr:country": "",
      "addr:housenumber": 111,
      "addr:postcode": 47906,
      "addr:state": "IN",
      "addr:street": "South River Road",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "The Pint",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "http://www.thepintwl.com",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4189953,
      "longitude": -86.8946614,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/3523896323",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 223,
      "addr:postcode": 47906,
      "addr:state": "IN",
      "addr:street": "Main Street",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Black Sparrow",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "http://blacksparrowpub.com/",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.3511513,
      "longitude": -86.903208,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/4412559099",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 4951,
      "addr:postcode": 47909,
      "addr:state": "IN",
      "addr:street": "Old US Hwy 231",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "McCutcheon High School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "https://mhs.tsc.k12.in.us/",
      "wheelchair": "",
      "operator": "Tippecanoe School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "Q6800814",
      "amenity_1": ""
    },
    {
      "latitude": 40.4658855,
      "longitude": -86.9613229,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/4412576661",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "West Lafayette",
      "addr:country": "",
      "addr:housenumber": 3311,
      "addr:postcode": 47906,
      "addr:state": "IN",
      "addr:street": "Klondike Rd",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Klondike Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Tippecanoe School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.3024511,
      "longitude": -86.9423775,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/4413386714",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "Lafayette",
      "addr:country": "",
      "addr:housenumber": 2000,
      "addr:postcode": 47909,
      "addr:state": "IN",
      "addr:street": "West 800 South",
      "amenity": "school",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Mintonye Elementary School",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "Tippecanoe School Corporation",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.463677,
      "longitude": -86.9688418,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/5622789321",
      "leisure": "playground",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Maitland Playground",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    },
    {
      "latitude": 40.4579334,
      "longitude": -86.960915,
      "altitude": "",
      "geometry": "Point",
      "coordinates": "",
      "@id": "node/8122539847",
      "leisure": "",
      "foot": "",
      "highway": "",
      "addr:city": "",
      "addr:country": "",
      "addr:housenumber": "",
      "addr:postcode": "",
      "addr:state": "",
      "addr:street": "",
      "amenity": "bar",
      "building": "",
      "ele": "",
      "gnis:county_id": "",
      "gnis:created": "",
      "gnis:feature_id": "",
      "gnis:state_id": "",
      "name": "Paradise Pub",
      "School System_1": "",
      "building:levels": "",
      "phone": "",
      "website": "",
      "wheelchair": "",
      "operator": "",
      "smoking": "",
      "fax": "",
      "access": "",
      "grades": "",
      "operator:type": "",
      "religion": "",
      "wikidata": "",
      "amenity_1": ""
    }
  ];

export function toGeojson(loc: typeof Restrictions[number]): Point | Polygon
{
    if(loc.geometry === "Point") {
       return {
           type: 'Point',
           coordinates: [
               loc.latitude as number,
               loc.longitude as number
           ]
       };
    }

    const c = loc.coordinates.split(',').map(parseFloat);
    const pairs: [number,number][] = [];
    for(let i = 0; i < c.length; i += 2) {
        pairs.push([
            c[i],
            c[i+1]
        ]);
    }

    return {
        type: 'Polygon',
        coordinates: [pairs]
    }
}

export function toViolation(loc: typeof Restrictions[number]): GPSViolation
{
    return {
        id: parseInt(loc["@id"].split('/')[1]),
        locationName: loc.name,
        category: loc.leisure || loc.amenity,
        geom: toGeojson(loc)
    }
}

export const RestrictionViolations = Restrictions.map(toViolation);

export default Restrictions;