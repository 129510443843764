import {
  IonButton,
  IonCard,
  IonContent,
  IonHeader, IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
  IonSpinner,
  IonButtons,
  IonBackButton,
  useIonToast
} from '@ionic/react';
import React, { useContext, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import SectionRadio from '../../components/rwat/SectionRadio';
import SectionSocial from '../../components/rwat/SectionSocial';
import SectionText from '../../components/rwat/SectionText';
import SectionTime from '../../components/rwat/SectionTime';
import { getPages, getPagesObj, Section } from '../../data/questions';
import { GameContext } from '../../gamification/gamification';
import NIJAPI from '../../NIJAPI';
import { Rtif } from '../../rtif';
import RWAT, { debugAnswers } from '../../RWAT';
import './Home.css';


const Home: React.FC = () => {
  // When true, always allow user to go to next section, even if the current page is incomplete
  const validOverride = process.env.REACT_APP_GOALS_DEV === '1';

  const [dummy, setDummy] = useState<number>(0);
  // const [toastText, setToastText] = useState<string>("");
  const [answersLoaded, setAnswersLoaded] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const rwatObj = RWAT.getInstance();

  const [present, dismiss] = useIonToast();

  const history = useHistory();

  const gameState = useContext(GameContext);
  const { activeTask, doGameEvent, stateFlags } = gameState;

  const pagesObj = useMemo(() => getPagesObj(), []);

  console.log("rwat", gameState);

  // useEffect(() => {
  //   // @ts-ignore
  //   document.getElementById("rwat")?.swiper?.update();
  //     // .then(v => { v.update(); console.log(v) });

  //   console.log(document.getElementById("rwat"));
  // });

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  const getSection = React.useCallback((section: Section, index: any) => {
    let props = {
      onChange: undefined,
      // @ts-ignore
      onValidChange: () => { console.log("valid change"); setDummy(dummy => ++dummy); },
      onSpecialChange: undefined,
      id: section?.id,
      name: section?.name,
      prompt: section?.prompt,
      questions: section?.questions,
      labels: section?.labels,
      values: section?.values,
      category: section?.category,
      disabled: false,
      key: section?.id
    };

    Object.assign(props, section);

    if((props.id === 'mostTimePerson_1' || props.id === 'mostTimePerson_2') && rwatObj.getSection('mostTimePerson_0')?.getQuestionAnswers()[0] === 1)
      props.disabled = true;

    // if(props.id === 'mostTimePerson_0')
      // @ts-ignore
      props.onChange = () => { console.log("change"); setDummy(dummy => ++dummy); };

    if(props.id === 'comAndLeisure')
      // @ts-ignore
      props.onSpecialChange = () => {
        // @ts-ignore
        // setTimeout(() => document?.getElementById("rwat")?.updateAutoHeight(), 0);
      };

    if(section.type === 'radio')
      return <SectionRadio {...props} />
    if(section.type === 'time')
      return <SectionTime {...props} />
    if(section.type === 'text')
      return <SectionText {...props} />
    if(section.type === 'socialnetwork')
      return <SectionSocial {...props} />

    return null;
  }, [rwatObj]);

  // Load RWAT answers
  React.useEffect(() => {
    rwatObj.loadAnswers()
      .then((r) => console.log(r))
      .catch(err => console.error(err))
      .finally(() => setAnswersLoaded(true));
  }, [rwatObj]);

  // Validate answers
  const valid = useMemo(() => {
    console.log("Revalidating...",dummy);
    return rwatObj.validatePage(getPages()[page]);
  }, [rwatObj, page, dummy]);

  const createPage = React.useCallback((page: (Section | undefined)[], index) => {
    console.log("Rerendering...", dummy);
    const arr = pagesObj;
    
    const pages = page?.map((s, i) => {
      return getSection(s as Section, i);
    });

    console.log('Validation message', valid);

    return (
      <IonSlide key="index">
        <IonCard>
            { pages }
            {valid !== '' && <IonText color="warning" style={{ whiteSpace: 'pre-line' }}>{valid}</IonText>}
            { index !== 0 &&
              <IonButton onClick={() => {
                setPage(page => Math.max(page-1, 0));
              }} expand="block">Back</IonButton>
            }
            { (index !== arr.length-1) &&
              <IonButton disabled={!validOverride && valid !== ''}
              onClick={() => {
                setPage(page => Math.min(page+1, pagesObj.length-1));
              }} expand="block">Next</IonButton>
            }
            { (index == arr.length-1 || stateFlags["rwatQuickSubmit"]) &&
              <IonButton onClick={() => {
                console.log("submit");

                // Finish regardless of RWAT submission result
                if(stateFlags["rwatAlwaysFinish"])
                {
                  doGameEvent({
                    type: 'finishTask',
                    data: {}
                  });

                  history.goBack();
                }

                NIJAPI.instance.pushRwat((index !== arr.length-1 && stateFlags["rwatDefaultResponse"]) ? debugAnswers : undefined)
                  .then(v => {
                    console.log("rwat success");

                    // // Send signal that the active task is completed
                    // doGameEvent({
                    //   type: 'finishTask',
                    //   data: {}
                    // });

                    if(!stateFlags["rwatAlwaysFinish"])
                      history.goBack();
                  })
                  .catch(e => {
                    present({
                      message: `Error while submitting RWAT: "${e}"`,
                      duration: 3000,
                      position: 'bottom'
                    });
                    console.error('RWAT submission error', e);
                  });
              }} expand="block">Submit</IonButton>
            }
        </IonCard>
      </IonSlide>
    )}, [doGameEvent, getSection, history, pagesObj, present, stateFlags, valid, validOverride, dummy]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const pageNode = React.useMemo(() => createPage(pagesObj[page], page), [createPage, pagesObj, page]);

  if(!answersLoaded)
    return (
      <IonPage id="home-page">
        <IonHeader>
          <IonToolbar>
            <IonTitle>RWAT</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen class="ion-padding">
          <div className="rwat-loading">
            Loading RWAT responses...
            <div>
              <IonSpinner />
            </div>
          </div>
        </IonContent>
      </IonPage>
    );

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/fivekeys/primary" />
          </IonButtons>
          <IonTitle size="large">
            RWAT
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-padding">
        <div id="rwat">
          { pageNode }
          {/* { console.log(RWAT.getInstance().encodeAnswers()) } */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
