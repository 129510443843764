import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonProgressBar,
    IonSegment,
    IonSegmentButton,
    IonSlide,
    IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { timeOutline } from "ionicons/icons";
import React, { useContext, useRef, useState } from 'react';
import PrizePreview from '../../components/gamification/PrizePreview';
import RewardTrack from '../../components/gamification/RewardTrack';
import { GameContext } from '../../gamification/gamification';
import { samplePrizes } from '../../sampleObjects/samplePrizes';
import './Prizes.css';
import NIJAPI from '../../NIJAPI';
import { Reward } from '../../gamification/GOALS';



const stampURI = "../assets/" + "ClaimedStamp.png";

interface PrizesProps
{
}

const Prizes: React.FC<PrizesProps> = (props: PrizesProps) => {
    const { points, level } = useContext(GameContext);
    // Points
    //let level = Math.floor(points / 10) as number;
    let currentLevelProgress = level - Math.floor(level);
    let panelsPerPage = 2;

    let fillPercent = 0;
    fillPercent = currentLevelProgress / 2;
    if (Math.floor(level) % 2 == 1)
        fillPercent += 0.5;

    async function OnSlideChange(e: any) {
        let index = await e.target.getActiveIndex();
        let thisPageStartIdx = index * panelsPerPage;

        //@ts-ignore
        document.getElementById("progressBar").value = fillPercent;

    }

    // State
    const [incentiveShow, setIncentiveShow] = useState(false);
    const [errandShow, setErrandShow] = useState(false);
    const [pendingRewards, setPendingRewards] = useState<Reward[]>([])
    const [claimedRewards, setClaimedRewards] = useState<Reward[]>([])
    const slidesRef = useRef<HTMLIonSlidesElement>(null);




    React.useEffect(() => {
        NIJAPI.instance.fetchRewards()
            .then((rewards) => {
                let tempPendingRewards: Reward[] = []
                let tempClaimedRewards: Reward[] = []
                rewards.sort((a, b) => a.targetPoints < b.targetPoints ? -1 : a.targetPoints > b.targetPoints ? 1 : 0)
                rewards.map((reward) => {
                    if (reward.status === 'pending') {
                        tempPendingRewards.push(reward)
                    } else if (reward.status === 'claimed') {
                        tempClaimedRewards.push(reward)
                    }
                })


                setClaimedRewards(tempClaimedRewards)
                setPendingRewards(tempPendingRewards)
            })
    }, [])


    // @ts-ignore
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/tasks" />
                    </IonButtons>

                    <IonTitle>Rewards</IonTitle>

                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen>

                <IonSegment onIonChange={e => console.log('Segment selected', e.detail.value)} value="pending">
                    <div className="leftCol">
                        <IonSegmentButton onClick={() => {
                            slidesRef.current?.slideTo(0);
                        }} value="pending">
                            <IonLabel>Pending</IonLabel>
                        </IonSegmentButton>
                    </div>
                    <div className="rightCol">
                        <IonSegmentButton onClick={() => {
                            slidesRef.current?.slideTo(1);
                        }} value="claimed">
                            <IonLabel>Claimed</IonLabel>
                        </IonSegmentButton>
                    </div>
                </IonSegment>
                
                <IonSlides id="rewardsSlides" ref={slidesRef} options={{
                    initialSlide: 0,
                    speed: 400,
                    autoHeight: false,
                    height: '100%'
                }}>
                    <IonSlide className="pendingRewards">
                        <div className="pendingRewardsSlide">
                            <RewardTrack items={pendingRewards}/>
                        </div>
                    </IonSlide>
                    <IonSlide className="claimedRewards">
                        <div className="claimedRewardsSlide">
                            <RewardTrack items={claimedRewards}/>
                        </div>
                    </IonSlide>
                </IonSlides>
            </IonContent>
        </IonPage>
    );
};

export default Prizes;
