import { IonLabel, IonList, IonListHeader } from "@ionic/react"
import React from "react"
import { Meeting } from "./Meeting"
import MeetingItem from "./MeetingItem"

const MeetingItemList:React.FC<{data:Meeting[]}> = ({data}) => {

    return(
        <IonList inset>
          <IonListHeader>
            <IonLabel>
              <h1>
                Meetings
              </h1>
            </IonLabel>
            </IonListHeader>
            {data.map((meeting,index) => {
                return(
                    <MeetingItem data={meeting}></MeetingItem>
                )
            })}      
</IonList>
    )
}

export default MeetingItemList;
