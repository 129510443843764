import React from 'react';
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonIcon, IonFooter, IonText } from '@ionic/react';
import NIJAPI from '../../NIJAPI';
import { logInOutline, logOutOutline } from 'ionicons/icons';

const OverviewMenu: React.FC = () => {
    const auth = NIJAPI.instance.authenticated;
    return (
        <IonMenu side="start" menuId="home-sidebar" contentId="home-page" type="overlay">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>GOALS</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {/* <IonItem routerLink="/login" onClick={() => {
                        if(auth) NIJAPI.instance.firebaseSignOut();
                    }}>
                        <IonIcon icon={auth ? logOutOutline : logInOutline} slot="start" />
                        { auth ? "Log Out" : "Log In" }
                    </IonItem> */}
                </IonList>
            </IonContent>
            <IonFooter>
                <IonText style={{
                    display: 'block',
                    position: 'relative',
                    right: '16px',
                    textAlign: 'right'
                }}>
                    App version v{process.env.REACT_APP_GOALS_VERSION} {process.env.REACT_APP_GOALS_DEV == '1' ? "dev" : ""}
                </IonText>
            </IonFooter>
        </IonMenu>
    );
};

export default OverviewMenu;