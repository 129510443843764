import { ItemReorderEventDetail } from '@ionic/core';
import { IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRange, IonReorder, IonReorderGroup, IonRow } from '@ionic/react';
import { closeSharp, thumbsDownSharp, thumbsUpSharp } from 'ionicons/icons';
import React from 'react';
import { Rtif } from '../../rtif';
import './SectionSocial.css';
import SurveySection, { SurveySectionProps, SurveySectionState } from './SurveySection';

export enum Influence
{
    Unspecified = 0,
    Positive = 3,
    Negative = 1,
    Neutral = 2
}

const InfluenceMap: { [key in Influence]: number } = {
    [Influence.Unspecified]: 0,
    [Influence.Positive]: 1,
    [Influence.Negative]: 2,
    [Influence.Neutral]: 3
}

const LabelMap = [
    "Negative",
    "Neutral",
    "Positive"
];

const closenessGradient = [
    [255, 165, 184],
    [63, 94, 251]
];

type PersonCategory = 'family' | 'community' | 'work' | 'friends';

interface Person
{
    category: PersonCategory,
    initials: string,
    influence: Influence,
    position: [number, number]
}

interface SectionSocialProps extends SurveySectionProps {
    category: PersonCategory
}

interface SectionSocialState extends SurveySectionState {
    answers: Person[]
    newPerson: Person | null
}

function mix(colorA: number[], colorB: number[], v: number): number[]
{
    console.log(colorA, colorB, v);
    let v2 = 1 - v;

    let final = colorA.map((color, i) => {
        return color * v2 + colorB[i] * v;
    });

    console.log(final);

    return final;
}

function NaNto0(num: number): number
{
    return (isNaN(num) ? 0 : num);
}

function isPersonDefined(person: Person)
{
    // if(person.influence !== Influence.Unspecified) return true;
    if(person.initials !== undefined && person.initials !== '') return true;
    // if(person.position !== [0, 0]) return true;

    return false;
}

export default class SectionSocial extends SurveySection<SectionSocialProps, SectionSocialState>
{
    constructor(props)
    {
        super(props);

        if(this.state.answers.length < 1)
            this.addPerson();
    }
    // Handle touch/click events in chart
    // handleInteract(event: React.MouseEvent<HTMLDivElement, MouseEvent>)
    // {
    //     let rect = event.currentTarget.getBoundingClientRect();

    //     let x = Math.floor((event.nativeEvent.clientX - rect.left) - rect.width / 2);
    //     let y = Math.floor((event.nativeEvent.clientY - rect.top) - rect.height / -2);

    //     if(!this.state.newPerson)
    //     {
    //         var sx = Math.sign(x), sy = Math.sign(y);
    //         var person: Person = {
    //             category: 'family',
    //             initials: '',
    //             influence: Influence.Unspecified,
    //             position: [x, y]
    //         };

    //         if(sx === -1 && sy === 1) person.category = 'family';
    //         else if(sx === -1 && sy === -1) person.category = 'work';
    //         else if(sx === 1 && sy === 1) person.category = 'community';
    //         else if(sx === 1 && sy === -1) person.category = 'friends';
    //         else return;

    //         console.log(person);

    //         this.setState({ newPerson: person });
    //     }
    // }

    addPerson()
    {
        let { answers } = this.state;

        let newPerson: Person = {
            category: this.props.category,
            initials: '',
            influence: Influence.Neutral,
            position: [0, 0]
        };

        answers.push(newPerson);

        this.setState({ answers: answers });
        this.triggerEvents();
    }

    validate()
    {
        console.log(this.props.name, this.state.answers, this.state.answers.length, this.validated);
        if(this.state.answers.length < 2 || this.state.answers.find(v => v.initials.length > 0) === undefined)
        {
            this.validated = false;
            return `Please provide at least one person for ${this.props.name}.`;
        }

        this.validated = true;
        return '';
    }

    encodeAnswers()
    {
        return this.state.answers.map((v) => {
            if(!v.initials || v.initials === '') return undefined;

            return `${v.initials}(0,0)${InfluenceMap[v.influence]}`;
        }).filter(e => e !== undefined).join('-');
    }

    componentDidUpdate()
    {
        let { answers } = this.state;

        console.log(isPersonDefined(answers[answers.length - 1]))

        if(isPersonDefined(answers[answers.length - 1])) this.addPerson();

        // console.log(this.encodeAnswers())
    }

    handleReorder(event: CustomEvent<ItemReorderEventDetail>)
    {
        let { answers } = this.state;

        // console.log(answers, "what")
        answers = event.detail.complete(answers);
        // console.log(answers, "the...")

        this.setState({ answers: answers });
    }

    render()
    {
        let { answers } = this.state;

        return (
            <div>
                { this.getHeader() }

                {/* <div className="chartContainer">
                    <div className="chart" onClick={this.handleInteract.bind(this)}>
                        <div className="xaxis" />
                        <div className="yaxis" />
                    </div>
                </div> */}

                <IonReorderGroup disabled={false} onIonItemReorder={this.handleReorder.bind(this)}>
                    {
                        answers.map((v, i) => {
                            const isLast = i !== 0 && i === answers.length - 1;
                            return (
                                <IonItem key={`${this.props.name}_${i}`} className={isLast ? "rwat-last-person" : ""}>
                                    <IonGrid class="social">
                                        <IonRow>
                                            <IonCol size="12">
                                                <IonLabel position="stacked">Initials</IonLabel>
                                                <IonInput
                                                    value={v.initials}
                                                    placeholder="e.g. EAP, JT"
                                                    onIonChange={e => {
                                                        v.initials = e.detail.value!;
                                                        this.setQuestionAnswer(i, v)
                                                    }}
                                                />
                                            </IonCol>
                                        </IonRow>
                                        {!isLast &&
                                            <IonRow>
                                                <IonCol size="12">
                                                    <IonLabel position="stacked">Influence</IonLabel>
                                                    <IonRange
                                                        value={v.influence}
                                                        snaps={true}
                                                        step={1}
                                                        min={Influence.Negative}
                                                        max={Influence.Positive}
                                                        pin={true}
                                                        onIonFocus={e => {
                                                            // @ts-ignore
                                                            e.target.shadowRoot.querySelector(".range-pin").innerText = LabelMap[v.influence-1];
                                                        }}
                                                        onIonChange={e => {
                                                            v.influence = e.detail.value as number;
                                                            // @ts-ignore
                                                            e.target.shadowRoot.querySelector(".range-pin").innerText = LabelMap[v.influence-1];
                                                            this.setQuestionAnswer(i, v);
                                                        }}
                                                    >
                                                        <IonIcon color="red" icon={thumbsDownSharp} slot="start" />
                                                        <IonIcon color="green" icon={thumbsUpSharp} slot="end" />
                                                    </IonRange>
                                                </IonCol>
                                            </IonRow>
                                        }
                                    </IonGrid>
                                    <IonButton
                                        disabled={i === answers.length-1}
                                        shape="round"
                                        fill="clear"
                                        onClick={e => {answers.splice(i,1); this.setState({ answers: answers })}}
                                        class="topRight"
                                        >
                                        <IonIcon slot="icon-only" icon={closeSharp} />
                                    </IonButton>
                                    <Rtif boolean={i !== answers.length - 1}>
                                        <>
                                            <p
                                                className="topLeft"
                                                style={{
                                                    // color: `rgb(${mix(closenessGradient[0], closenessGradient[1], NaNto0(i / (answers.length - 2))).join(',')})`
                                                }}>
                                                {i + 1}
                                            </p>
                                            <IonReorder class="middleRight" />
                                        </>
                                    </Rtif>
                                </IonItem>
                            )
                        })
                    }
                </IonReorderGroup>
            </div>
        );
    }
}